import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button, Link, CircularProgress, IconButton, InputAdornment, FormControlLabel, Modal } from '@mui/material';
import style from './AuthComponent.module.css';
import { useDispatch } from "react-redux";
import { fetchUserPrivileges } from "../../service/reducers/PrivelegiesSlice";
import inputStyle from "../ImputCustom/CustomInpurField.module.css";
import { getDateForPickers } from "../../api/api";
import InputField from "../ImputCustom/CustomInpurField";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IOSSwitch } from "../CustomIosSwitch/IOSSwitch";
import {AUTH} from "../../api/config";

const LoginForm = ({ onLoginSuccess }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({ username: '', password: '' });
    const [errors, setErrors] = useState({ username: false, password: false });
    const [rememberMe, setRememberMe] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [resetError, setResetError] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    useEffect(() => {
        if (rememberMe) {
            const savedUsername = localStorage.getItem('savedUsername');
            const savedPassword = localStorage.getItem('savedPassword');
            if (savedUsername && savedPassword) {
                setFormValues({ username: savedUsername, password: savedPassword });
            }
        }
    }, [rememberMe]);

    useEffect(() => {
        if (formValues.username && formValues.password) {
            document.querySelector(`.${style.authButton}`).disabled = false;
        }
    }, [formValues]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: false });
    };

    const handleCheckboxChange = (e) => {
        setRememberMe(e.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${AUTH}/login`, formValues, {
                withCredentials: true
            });
            const tokenString = response.data.token;
            localStorage.setItem('token', tokenString);
            if (rememberMe) {
                localStorage.setItem('savedUsername', formValues.username);
                localStorage.setItem('savedPassword', formValues.password);
            } else {
                localStorage.removeItem('savedUsername');
                localStorage.removeItem('savedPassword');
            }
            await sendMeRequest(tokenString);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setErrors({ username: true, password: true });
            }
        }
    };

    const sendMeRequest = async (tokenString) => {
        try {
            const response = await dispatch(fetchUserPrivileges(tokenString));
            if (response.meta.requestStatus === 'fulfilled') {
                const dateForPickersResponse = await getDateForPickers();
                const sortedDateForPickersResponse = dateForPickersResponse.sort((a, b) => {
                    if (a.type === 'start_date' && b.type === 'end_date') {
                        return -1;
                    } else if (a.type === 'end_date' && b.type === 'start_date') {
                        return 1;
                    }
                    return 0;
                });
                localStorage.setItem('dateForPickers', JSON.stringify(sortedDateForPickersResponse));
                onLoginSuccess();
                navigate('/content/dashboard');
            } else {
                throw new Error('Неудачная авторизация');
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setResetEmail('');
        setResetError(false);
        setResetSuccess(false);
    };

    const handleResetEmailChange = (e) => {
        setResetEmail(e.target.value);
        setResetError(false);
    };

    const handleResetPassword = async () => {
        setResetLoading(true);
        try {
            const response = await axios.post(`${AUTH}/reset_password_request_email`, { email: resetEmail }, {
                headers: { 'Content-Type': 'application/json' }
            });
            setResetLoading(false);
            if (response.data.success) {
                setResetSuccess(true);
            } else {
                setResetError(true);
            }
        } catch (error) {
            setResetLoading(false);
            setResetError(true);
        }
    };

    return (
        <div>
            <div className={style.background} />
            <Container maxWidth="xs" sx={{
                zIndex: 9999,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        backgroundColor: 'white',
                        padding: '40px',
                        borderRadius: '16px',
                        boxShadow: 10,
                        width: '100%',
                        maxWidth: '400px'
                    }}
                >
                    <Typography variant="h5" sx={{ mb: 3 }}>Вход</Typography>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <InputField
                            type="text"
                            placeholder="Электронная почта"
                            name="username"
                            value={formValues.username}
                            onChange={handleInputChange}
                            className={inputStyle.inputField}
                            isError={errors.username}
                        />
                        <InputField
                            type={showPassword ? "text" : "password"}
                            placeholder="Пароль"
                            name="password"
                            value={formValues.password}
                            onChange={handleInputChange}
                            className={inputStyle.inputField}
                            isError={errors.password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                        style={{ backgroundColor: 'transparent', padding: 0 }}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {(errors.username || errors.password) && (
                            <Typography color="error" variant="body2">Неверные данные для входа</Typography>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                            <FormControlLabel
                                control={<IOSSwitch checked={rememberMe} onChange={handleCheckboxChange} />}
                                label="Запомнить меня"
                                labelPlacement="start"
                                sx={{ marginLeft: 0, gap: '10px' }}
                            />
                            <Link href="#" variant="body2" onClick={handleModalOpen} sx={{ fontSize: '14px', textDecoration: 'none', color: '#315EFB' }}>Забыли пароль?</Link>
                        </Box>
                        <Button type="submit" fullWidth variant="contained" className={style.authButton} sx={{ mt: 3, opacity: (!formValues.username || !formValues.password) ? 0.4 : 1 }} disabled={!formValues.username || !formValues.password}>
                            Войти
                        </Button>
                        {isLoading && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                                <CircularProgress size={24} />
                                <Box sx={{ mt: 2 }}>Загрузка...</Box>
                            </Box>
                        )}
                    </form>
                </Box>
            </Container>
            <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    maxWidth: 400,
                    bgcolor: 'background.paper',
                    borderRadius: '16px',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Восстановление пароля
                    </Typography>
                    {resetSuccess ? (
                        <>
                            <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
                                Инструкция по восстановлению пароля успешно отправлена на указанный email ({resetEmail})
                            </Typography>
                            <Button
                                onClick={handleModalClose}
                                fullWidth
                                variant="contained"
                                className={style.authButton}
                            >
                                Закрыть
                            </Button>
                        </>
                    ) : (
                        <>
                            <div className={style.inputContainer}>
                                <InputField
                                    type="email"
                                    placeholder="Электронная почта"
                                    name="resetEmail"
                                    value={resetEmail}
                                    onChange={handleResetEmailChange}
                                    className={`${inputStyle.inputField} ${resetError ? inputStyle.inputError : ''}`}
                                    isError={resetError}
                                />
                            </div>
                            {resetError && (
                                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                    Введите корректную почту
                                </Typography>
                            )}
                            <Button
                                onClick={handleResetPassword}
                                fullWidth
                                variant="contained"
                                className={style.authButton}
                                sx={{ mt: 3, opacity: !resetEmail ? 0.4 : 1 }}
                                disabled={!resetEmail}
                            >
                                {resetLoading ? <CircularProgress size={24} /> : "Восстановить"}
                            </Button>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default LoginForm;
