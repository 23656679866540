import React from 'react';
import style from './CustomInpurField.module.css';

const InputField = ({ type, placeholder, name, value, onChange, onBlur, className, isError, endAdornment }) => {
    const errorClass = isError ? style.inputError : '';
    const combinedClassName = `${className} ${errorClass}`;

    return (
        <div className={style.inputWrapper}>
            <input
                type={type}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                className={combinedClassName}
                onBlur={onBlur}
            />
            {endAdornment && (
                <div className={style.endAdornment}>
                    {endAdornment}
                </div>
            )}
        </div>
    );
};

export default InputField;
