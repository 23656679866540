import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import { useDispatch, useSelector } from "react-redux";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import { fetchSunBurstData } from "../../../service/reducers/SunBurstChartSlice";
import { activeColors } from "../../../utils/colors";
import Legend from "../../../components/DiagrammLegend/Legend";
import { formatCurrency } from "../../../utils/rubbleFunc";
import tooltipNames from "../../../utils/tooltipTitles.json";
import {
    addZoomableName,
    resetSegmentNameStore,
    setCurrentSegmentName
} from "../../../service/reducers/ZoomableChartSegmentSlice";
import useFetchData from "../../../hook/useFetchData";
import useTooltip from "../../../hook/useTooltip";
import Tooltip from "../../../components/Tooltip/Tooltip";

const SunBurstDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const { width, height } = useResizeObserver({ ref });
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const { SunBurstData, loading } = useSelector((state) => state.sunBurst);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const selectedZoomable = useSelector(state => state.segmentNameSlice.currentSegmentName);

    let headerTitle;
    switch (activeTab) {
        case 'Извещения':
            headerTitle = 'Структура требований к участникам';
            break;
        case 'Контракты':
            headerTitle = 'Структура по способам определения поставщиков';
            break;
        case 'Исполнение':
            headerTitle = 'Структура неустойки по контрактам';
            break;
        default:
            headerTitle = 'Неизвестная вкладка';
    }
    const headerWithTwoButtons = {
        title: headerTitle,
        icons: [
            {
                name: 'zoom',
                icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut,
                width: 20,
                height: 20,
                onClick: onZoomClick
            },
            {
                name: 'menu',
                icon: icons.menu,
                width: 20,
                height: 20
            }
        ]
    };

    useFetchData(fetchSunBurstData, [
        useSelector(state => state.stackedWithContentSegmentSlice.selectedMonth),
        useSelector(state => state.pieChartOtpSegment.selectedSlice),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.activitySlice),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position)
    ]);

    function transformToLegendData(data) {
        const legendData = data
            .filter(item => item.lvl === 1)
            .map(item => ({
                label: item.label,
                value: item.value
            }));
        return legendData;
    }

    useEffect(() => {
        if (loading === 'successful' && width && height && SunBurstData) {
            createSunBurstChart(SunBurstData);
        }
        if (SunBurstData) {
            const initialLegendData = transformToLegendData(SunBurstData);
            setLegendData(initialLegendData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, SunBurstData]);
    const [legendData, setLegendData] = useState([]);
    const [clickedColor, setClickedColor] = useState(null);

    const transformData = (data) => {
        const root = {
            name: "root",
            children: [],
        };

        if (!Array.isArray(data)) {
            console.error("Expected 'data' to be an array, received:", data);
            return root;
        }

        data.forEach((d) => {
            const childrenLvl2 = d.NextLvl ? d.NextLvl.map((lvl2) => {
                if (lvl2.NextLvl) {
                    return {
                        name: lvl2.tooltype || lvl2.label,
                        children: lvl2.NextLvl.map((lvl3) => ({
                            name: lvl3.tooltype || lvl3.label,
                            value: lvl3.value
                        }))
                    };
                } else {
                    return {
                        name: lvl2.tooltype || lvl2.label,
                        value: lvl2.value
                    };
                }
            }) : [{
                name: d.tooltype || d.label,
                value: d.value
            }];

            root.children.push({
                name: d.label,
                children: childrenLvl2
            });
        });
        return root;
    };

    const onMouseMove = (event, d) => {
        const tooltipConfig = tooltipNames.TOP.Tabs[activeTab];

        let tooltipData = [
            { label: tooltipConfig.name, value: d.data.name },
            { label: tooltipConfig.value, value: formatCurrency(d.value) }
        ];

        if (activeTab === 'Исполнение' && d.parent && d.parent.data.name !== "root" && d.parent.parent) {
            tooltipData = [
                { label: tooltipConfig.name, value: d.parent.data.name },
                { label: tooltipConfig.who, value: d.data.name },
                { label: tooltipConfig.value, value: formatCurrency(d.value) }
            ];
        }

        showTooltip(event, tooltipData);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const createSunBurstChart = (data) => {
        const margin = { top: 1, right: 1, bottom: 10, left: 1 };
        const effectiveWidth = width - margin.left - margin.right;
        const effectiveHeight = height - margin.top - margin.bottom;
        const radiusDivisors = {
            'Извещения': 7.3,
            'Исполнение': 9.5,
        };
        const currentDivisor = radiusDivisors[activeTab] || 7.5;
        const radius = Math.min(effectiveWidth, effectiveHeight) / currentDivisor;

        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3.select(ref.current)
            .append('svg')
            .attr('viewBox', `0 0 ${width} ${height}`)
            .attr('width', '100%')
            .attr('height', '100%')
            .append('g')

        const root = d3.hierarchy(transformData(data))
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value);

        root.each(d => d.current = d);

        d3.partition()
            .size([2 * Math.PI, root.height + 1])(root);

        const arc = d3.arc()
            .startAngle(d => d.x0)
            .endAngle(d => d.x1)
            .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius(d => d.y0 * radius)
            .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1));

        const color = d3.scaleOrdinal(activeColors);

        const g = svg.append("g")
            .attr("transform", `translate(${width / 2},${height / 2})`);

        const path = g.selectAll("path")
            .data(root.descendants().slice(1))
            .enter().append("path")
            .attr("fill", d => {
                while (d.depth > 1) d = d.parent;
                return color(d.data.name);
            })
            .attr("d", d => arc(d.current))
            .on("mousemove", (event, d) => onMouseMove(event, d))
            .on("mouseout", onMouseOut);

        path.filter(d => d.children)
            .style("cursor", "pointer")
            .on("click", (event, p) => clicked(event, p, color(p.data.name)));

        const parent = svg.append("circle")
            .datum(root)
            .attr("transform", `translate(${width / 2},${height / 2})`)
            .attr("r", radius)
            .attr("fill", "none")
            .attr("pointer-events", "all")
            .on("click", (event, p) => clicked(event, p, null));

        function clicked(event, p, color) {
            if (p.depth === 0) {
                dispatch(resetSegmentNameStore())
                const newLegendData = transformToLegendData(SunBurstData)
                setLegendData(newLegendData);
                setClickedColor(null);
            } else {
                dispatch(setCurrentSegmentName(p.data.name));
                dispatch(addZoomableName(headerTitle))
                setClickedColor(color);
            }
            parent.datum(p.parent || root);
            root.each(d => d.target = {
                x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                y0: Math.max(0, d.y0 - p.depth),
                y1: Math.max(0, d.y1 - p.depth)
            });

            const t = svg.transition().duration(750);
            path.transition(t)
                .tween("data", d => {
                    const i = d3.interpolate(d.current, d.target);
                    return t => d.current = i(t);
                })
                .attrTween("d", d => () => arc(d.current));

            if (p.children) {
                const newLegendData = p.children.map(d => ({
                    label: d.data.name,
                    value: d.value,
                }));
                setLegendData(newLegendData);
            }
        }
    };

    return (
        <div className={`${style.container} ${selectedZoomable.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "600px" } : {}}>
            {(loading === 'pending' || isLoadingMenu) ? (
                <Spinner />
            ) : (loading === 'failed' && activeTab === "Исполнение") ? (
                <div className={style.noData}>Данные о штрафах и пенни отсутствуют</div>
            ) : (
                <>
                    <div className={style.header}>
                        <HeaderDiagram
                            {...headerWithTwoButtons}
                            onZoomClick={onZoomClick}
                            diagramName={headerTitle}
                        />
                    </div>
                    <div className={style.header}>
                        <Legend
                            diagramId={headerTitle}
                            data={legendData}
                            dynamicRadius={zoomedDiagram ? 150 : 75}
                            activeColors={clickedColor ? [clickedColor] : activeColors}
                            onLegendItemClick={onMouseOut}
                            selectedSlice={[]}
                        />
                    </div>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`} />
                </>
            )}
        </div>
    );
};

export default SunBurstDiagramm;
