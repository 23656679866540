import React, {useEffect, useState} from 'react';
import xButtonIco from "../../../common/icons/xButtonBlack.svg";
import styles from '../../../components/InfoTable/InfoTable.module.css';
import style from './PurchaseInfoCard.module.css'
import {CustomLoader} from "../../TestPages/LoaderComponent/CustomLoader";
import StageProgressBar from "../ProgressBar/ProgressBar";
import {AccordionItem} from "../AccordeonList/AccordeonList";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
export const PurchaseAllInfo = ({onClose, data}) => {
    const [cardData, setCardData] = useState(null);
    const [visibleItemCount, setVisibleItemCount] = useState(5);
    useEffect(() => {
        if (data && typeof data === 'object') {
            setCardData(data);
        } else {
            console.error("Ожидался объект с данными, получен другой тип");
        }
    }, [data]);
    const handleLoadMore = () => {
        setVisibleItemCount(prevCount => prevCount + 5);
    };

    if (!cardData) {
        return <CustomLoader/>;
    }

    const zakupkiUrl = `https://zakupki.gov.ru/epz/order/notice/zk20/view/common-info.html?regNumber=${cardData.id}`;
    const customerUrl = `${window.location.origin}/content/dashboard?cust_inn=${cardData.inn}`;

    return (
        <div className={styles.mainContainer}>
            <div className={`${styles.sameJcHeader} ${styles.header}`}>
                <div className={style.headerText}>
                    {cardData?.title}
                </div>
                <div onClick={onClose}>
                    <img src={xButtonIco} alt="closeXIco" className={styles.closeIcon} />
                </div>
            </div>
            <div className={`${styles.dataContainer} ${styles.morePadding}`}>
                {cardData ? (
                    <>
                        <div className={style.gridContainer}>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Закупка</span>
                                <div className={style.test}>
                                    <span className={style.infoValue}>№ {cardData.id}</span>
                                    <a href={zakupkiUrl} target="_blank" rel="noopener noreferrer" className={style.infoLink}>
                                        <OpenInNewIcon className={style.linkRefer}/>
                                    </a>
                                </div>
                            </div>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Закон</span>
                                <span className={style.infoValue}>{cardData.gov}</span>
                            </div>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Способ определения</span>
                                <span className={style.infoValue}>{cardData.type}</span>
                            </div>
                        </div>
                        <div className={style.gridContainer}>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Заказчик</span>
                                <a href={customerUrl} className={style.infoValue}>{cardData.customer}</a>
                            </div>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Электронная площадка</span>
                                <a href={cardData.areaLink} className={style.infoLink}>
                                    {cardData.areaName}
                                </a>
                            </div>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Начальная цена контракта</span>
                                <span className={style.infoValue}>{new Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 2 }).format(cardData.price)} ₽</span>
                            </div>
                        </div>
                        <div className={`${style.headerText} ${style.noMrHeaderText}`}>
                            Подача заявок / Этапы
                        </div>
                        <StageProgressBar stages={cardData.stages}/>
                        <div className={`${style.headerText} ${style.noMrHeaderText}`}>
                            Финансовое обеспечение закупки
                        </div>
                        <div className={`${style.gridContainer} ${style.gridContainerSmG}`}>
                            {cardData?.financialSupport.map((support, index) => (
                                <div key={index} className={style.gridItem}>
                                    <span className={style.infoLabel}>
                                        {support.key === 9999 ? 'На последующие годы' : support.key === 0 ? 'Всего' : `На ${support.key} г.`}
                                    </span>
                                    <span className={style.infoValue}>
                                        {new Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 2 }).format(support.name)} ₽
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className={`${style.headerText} ${style.noMrHeaderText}`}>
                            Условия контракта
                        </div>
                        <div className={`${style.gridContainer} ${style.gridContainerSmG}`}>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Место поставки товара, выполнения работы или оказания услуги</span>
                                <span className={style.infoValue}>{cardData.conditionsPlace}</span>
                            </div>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Возможность одностороннего отказа от исполнения контракта в соответствии со ст. 95 Закона № 44-ФЗ</span>
                                <span className={style.infoValue}>{cardData.conditionsCancelation ? 'Да' : 'Нет'}</span>
                            </div>
                        </div>
                        <div className={`${style.headerText} ${style.noMrHeaderText}`}>
                            Обеспечение заявки
                        </div>
                        <div className={`${style.gridContainer} ${style.gridContainerSmG}`}>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Размер обеспечения</span>
                                <span className={style.infoValue}>{cardData.provisionPrice}</span>
                            </div>
                            <div className={style.gridItem}>
                                <span className={style.infoLabel}>Размер обеспечения исполнения контракта</span>
                                <span className={style.infoValue}>
                                    {cardData.provisionPriceValue}
                                    <span className={style.infoPercentage}>{`(${cardData.provisionPercentage} %)`}</span>
                                    </span>
                            </div>
                        </div>
                        <div className={`${style.headerText} ${style.noMrHeaderText} ${style.additionalBtMr}`}>
                            Объект закупки
                        </div>
                        <div>
                            {cardData?.nodes.slice(0, visibleItemCount).map((node, index) => (
                                <AccordionItem key={index} title={node.name} alwaysOpen={index === 0}>
                                    <div className={style.gridContainer}>
                                        <div className={style.gridItem}>
                                            <span className={style.infoLabel}>Код позиции</span>
                                            <span className={style.infoValue}>{node.code}</span>
                                        </div>
                                        <div className={style.gridItem}>
                                            <span className={style.infoLabel}>Ед. измерения</span>
                                            <span className={style.infoValue}>{node.unit}</span>
                                        </div>
                                        <div className={style.gridItem}>
                                            <span className={style.infoLabel}>Количество (объем работы, услуги)</span>
                                            <span className={style.infoValue}>{new Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 2 }).format(node.quantity)}</span>
                                        </div>
                                    </div>
                                    <div className={style.gridContainer}>
                                         <div className={style.gridItem}>
                                            <span className={style.infoLabel}>Цена за ед</span>
                                            <span className={style.infoValue}>{new Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 2 }).format(node.unitPrice)} ₽</span>
                                         </div>
                                        <div className={style.gridItem}>
                                            <span className={style.infoLabel}>Стоимость</span>
                                            <span className={style.infoValue}>{new Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 2 }).format(node.totalCost)} ₽</span>
                                        </div>
                                    </div>
                                </AccordionItem>
                            ))}
                            {cardData?.nodes.length > visibleItemCount && (
                                <div className={style.moreButtonContainer}>
                                    <div className={style.moreButton} onClick={handleLoadMore}>
                                        Загрузить еще
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`${style.headerText} ${style.noMrHeaderText}`}>
                            Преимущества, требования к участникам
                        </div>
                        <div className={style.topContainer}>
                            <div>
                                <span className={style.infoLabel}>Преимущества</span>
                                {cardData.advantages && cardData.advantages.map((advantage, index) => (
                                    <div key={index} className={style.gridItem}>
                                        <span className={style.infoValue}>{advantage}</span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <span className={style.infoLabel}>Требования к участникам</span>
                                {cardData.requirements && cardData.requirements.map((requirement, index) => (
                                    <div key={index} className={style.gridItem}>
                                        <span className={style.infoValue}>{requirement}</span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <span className={style.infoLabel}>Ограничения на запрет</span>
                                {cardData.prohibitions && cardData.prohibitions.map((prohibition, index) => (
                                    <div key={index} className={style.gridItem}>
                                        <span className={style.infoValue}>{prohibition}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/*<div className={`${style.headerText} ${style.noMrHeaderText}`}>
                            Возможные участники
                        </div>
                        <div className={style.topContainer}>
                            {cardData?.employers.map((employer, index) => (
                                <div key={index} className={style.gridItem}>
                                    <div className={style.empString}>
                                        <span className={style.infoValue}>{employer.name}</span>
                                        <span className={style.infoPercentage}>{employer.perc}%</span>
                                    </div>
                                    <span className={style.infoLabel}>ИНН {employer.inn}</span>
                                </div>
                            ))}
                        </div>*/}
                    </>
                ) : (
                   <CustomLoader/>
                )}
            </div>
        </div>
    );
}
