import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import { useDispatch, useSelector } from "react-redux";
import { activeColors } from "../../../utils/colors";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import Legend from "../../../components/DiagrammLegend/Legend";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import { formatCurrency } from "../../../utils/rubbleFunc";
import { useVirtualTooltipSize } from "../../../hook/useVirtualTooltipSize";
import styles from "../TestMapD/GeoChart.module.css";
import { setActiveLegendItem } from "../../../service/reducers/legendItemsClick";
import localStorageService from "../../../service/localStorage/localStorageService";
import { cancelAllPendingRequests } from "../../../api/api";
import tooltipNames from "../../../utils/tooltipTitles.json"
import useFetchData from "../../../hook/useFetchData";
import useTooltip from "../../../hook/useTooltip";
import Tooltip from "../../../components/Tooltip/Tooltip";
import {fetchPieOtpData, setShowCount} from "../../../service/reducers/PierChartOtp/PieChartOtpSlice";
import {
    addPieName,
    toggleSelectedSlice,
    updateSelectedSliceSize
} from "../../../service/reducers/PierChartOtp/PieChartOtpSegmentSlice";

const PieChartEtp = ({ onZoomClick, zoomedDiagram }) => {
    const selectedSlice = useSelector(state => state.pieChartOtpSegment.selectedSlice);
    const memoizedSelectedSlice = useMemo(() => selectedSlice || [], [selectedSlice]);
    const selectedSliceSize = useSelector(state => state.pieChartOtpSegment.selectedSliceSize);
    const dispatch = useDispatch();
    const ref = useRef();
    const arcGenerator = useRef();
    const dynamicRadius = useRef(0);
    const PIE_OFFSET = 10;
    const { width, height } = useResizeObserver({ ref });
    const { PieOtpData, showCount, loading } = useSelector((state) => state.pieChartOtp);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const headerTitle = `Закупки по площадкам ${showCount === 'count' ? "(от кол-ва)" : "(от суммы)"}`
    const headerWithThreeButtons = {
        title: headerTitle,
        icons: [
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'change', icon: icons.change, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };
    const colors = localStorageService.getItem('colors');

    useFetchData(fetchPieOtpData, [
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.stackedWithContentSegmentSlice.selectedMonth),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.activitySlice),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position)
    ]);

    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();

    useEffect(() => {
        if (loading === 'successful' && width && height && PieOtpData) {
            const newRadius = Math.min(width, height) * 0.35;
            memoizedSelectedSlice.forEach((label) => {
                const size = selectedSliceSize[label] ? newRadius + PIE_OFFSET : dynamicRadius.current;
                dispatch(updateSelectedSliceSize({ label, size }));
            });
            dynamicRadius.current = newRadius;
            if (showCount === 'count') {
                const newPieData = PieOtpData.map(item => {
                    return {
                        label: item.label,
                        value: item.extra[0].value
                    };
                });
                createPieChart(newPieData);
            } else {
                createPieChart(PieOtpData);
            }
        }
        // eslint-disable-next-line
    }, [width, height, PieOtpData, memoizedSelectedSlice, selectedSliceSize, showCount, loading]);

    useEffect(() => {
        if (!ref.current) return;
        d3.select(ref.current)
            .selectAll('path')
            .transition()
            .duration(800)
            .attrTween('d', function (d) {
                const i = d3.interpolate(d.startAngle, d.endAngle);
                return function (t) {
                    const interpolated = { ...d, endAngle: i(t) };
                    if (memoizedSelectedSlice.includes(d.data.label)) {
                        return d3.arc().innerRadius(0).outerRadius(dynamicRadius.current + PIE_OFFSET)(interpolated);
                    }
                    return arcGenerator.current(interpolated);
                };
            })
            .attr('opacity', (d) => memoizedSelectedSlice.length === 0 || memoizedSelectedSlice.includes(d.data.label) ? 1 : 0.5);
    }, [width, height, PieOtpData, memoizedSelectedSlice, selectedSliceSize, showCount]);

    useEffect(() => {
        if (!ref.current) return;
        const svg = d3.select(ref.current).select("svg");
        const pieChartGroup = svg.select('g');

        pieChartGroup
            .selectAll('text')
            .each(function (d) {
                updateText(d3.select(this), d);
            });
        // eslint-disable-next-line
    }, [memoizedSelectedSlice]);

    const getTextSize = (angle) => {
        const percentage = (angle / (2 * Math.PI)) * 100;
        const baseSize = dynamicRadius.current / 73;
        if (percentage < 1) return '0px';
        if (percentage < 5) return `${8 * baseSize}px`;
        if (percentage < 10) return `${8 * baseSize}px`;
        return `${10 * baseSize}px`;
    };

    const updateText = (textSelection, d) => {
        const percentage = (d.endAngle - d.startAngle) / (2 * Math.PI) * 100;
        const radiusThreshold = 63;
        const isSmallSegment = dynamicRadius.current * (d.endAngle - d.startAngle) <= radiusThreshold;

        if (isSmallSegment && !memoizedSelectedSlice.includes(d.data.label)) {
            textSelection.text('');
            return;
        }

        const color = isSmallSegment ? 'var(--text-color)' : 'white';
        const outerRadius = isSmallSegment ? dynamicRadius.current + PIE_OFFSET + (130 * dynamicRadius.current / 90) : dynamicRadius.current;
        const centroid = d3.arc().innerRadius(0).outerRadius(outerRadius).centroid(d);
        const angle = d.endAngle - d.startAngle;
        const fontSize = getTextSize(angle);

        textSelection
            .attr('x', centroid[0])
            .attr('y', centroid[1])
            .attr('dy', '0.33em')
            .text(`${percentage.toFixed(1)}%`)
            .attr('font-family', 'Golos regular')
            .attr('fill', color)
            .attr('font-size', fontSize)
            .attr('text-anchor', 'middle');
    };

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const tooltipConfig = tooltipNames.PieEptChart.Tabs[activeTab];
        const tooltipSize = calculateTooltipSize(d.data.extra);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }
        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        let tooltipText = [
            { label: tooltipConfig.label, value: d.data.label },
        ];

        if (showCount === 'sum') {
            tooltipText.push({ label: tooltipConfig.value, value: formatCurrency(d.data.value) });
        } else if (showCount === 'count') {
            tooltipText.push({ label: tooltipConfig.count, value: `${d.data.value} шт` });
        }

        showTooltip(event, tooltipText);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const handleLegendItemClick = (label) => {
        cancelAllPendingRequests();
        const size = dynamicRadius.current + PIE_OFFSET;
        const color = activeColors.find((_, index) => PieOtpData[index]?.label === label);
        dispatch(toggleSelectedSlice({ label, size, color }));
        dispatch(addPieName(headerTitle));
    };

    const sortedData = [...PieOtpData].sort((a, b) => b.value - a.value);

    const createLegendColors = () => {
            return activeColors.slice(0, PieOtpData.length);
    };

    const legendColors = createLegendColors();

    const findShortNameForLabel = (label) => {
        const colorEntry = colors.find(color => color.name === label);
        return colorEntry ? colorEntry.short_name : label;
    };

    const legendData = sortedData.map(item => ({
        label: findShortNameForLabel(item.label),
        fullLabel: item.label
    }));

    const createPieChart = (data) => {
        if (!Array.isArray(data) || data.length === 0) return;
        const sortedData = [...data].sort((a, b) => b.value - a.value);
        d3.select(ref.current).select("svg").remove();
        const colorScale = d3.scaleOrdinal()
            .domain(sortedData.map(d => d.label))
            .range(sortedData.map((d, index) => {
                const colorInfo = d.extra?.find(ex => ex.label === 'color');
                return colorInfo ? colorInfo.value : activeColors[index % activeColors.length];
            }));

        const pie = d3.pie().value((d) => d.value);
        const data_ready = pie(sortedData);

        arcGenerator.current = d3.arc().innerRadius(0).outerRadius(dynamicRadius.current);

        const svg = d3
            .select(ref.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height);

        const pieChartGroup = svg.append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const segmentGroup = pieChartGroup
            .selectAll('g.segment')
            .data(data_ready, (d) => d.data.label)
            .enter()
            .append('g')
            .attr('class', 'segment')
            .on('click', function (event, d) {
                cancelAllPendingRequests();
                if (data.length === 1) return;
                const size = dynamicRadius.current + PIE_OFFSET;
                const color = colorScale(d.data.label);
                dispatch(toggleSelectedSlice({ label: d.data.label, size, color }));
                dispatch(setActiveLegendItem({ diagramId: headerTitle, activeItem: d.data.label }));
                dispatch(addPieName(headerTitle));
            });

        segmentGroup.append('path')
            .attr('d', arcGenerator.current)
            .attr('fill', (d) => colorScale(d.data.label))
            .attr('stroke', 'var(--container-bg-color)')
            .attr('stroke-width', '0.5')
            .attr('opacity', (d) => memoizedSelectedSlice.length === 0 || memoizedSelectedSlice.includes(d.data.label) ? 1 : 0.5)
            .on('mousemove', (event, d) => onMouseMove(event, d))
            .on('mouseout', onMouseOut);

        segmentGroup.append('text')
            .each(function (d) {
                updateText(d3.select(this), d);
            })
            .style("user-select", "none")
            .style("-moz-user-select", "none")
            .style("-webkit-user-select", "none")
            .style("-ms-user-select", "none");
    };

    return (
        <div className={`${style.container} ${memoizedSelectedSlice.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram small-chart`} style={zoomedDiagram ? { height: "75vh" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    activeMode={showCount}
                    diagramName={headerTitle}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'sum') {
                            dispatch(setShowCount('sum'));
                        } else if (mode === 'count') {
                            dispatch(setShowCount('count'));
                        }
                    }}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={headerTitle} data={legendData} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={legendColors} onLegendItemClick={handleLegendItemClick} selectedSlice={memoizedSelectedSlice}/>
                    </div>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`} />
                </>
            )}
        </div>
    );
};

export default PieChartEtp;
