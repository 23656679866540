import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchGraphPlaneMonth} from "../../../api/api";

const initialState = {
    StackedWithContentData: [],
    loading: 'idle',
    error: null,
};

export const fetchStackedWithContentData = createAsyncThunk(
    'stackedWithContent/fetchStackedWithContentData',
    async (options) => {
        try {
            const { selectedMonthStacked, ...restOptions } = options;
            restOptions.newOKPDCode = restOptions.selectedProduct.map(item => item.productId);
            const response = await fetchGraphPlaneMonth(restOptions);
            if ((response.status === 204 && response.data === "") || response.data.result === null) {
                return [];
            }
            return response.data.result;
        } catch (error) {
            throw error;
        }
    }
);


const stackedWithContentSlice = createSlice({
    name: 'stackedWithContent',
    initialState,
    reducers: {
        resetStackedWithContentData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStackedWithContentData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchStackedWithContentData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.StackedWithContentData = action.payload;
                }
            })
            .addCase(fetchStackedWithContentData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { resetStackedWithContentData } = stackedWithContentSlice.actions;

export default stackedWithContentSlice.reducer;
