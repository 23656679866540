import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchTreeMap} from "../../../api/api";

const initialState = {
    TreeMapData: [],
    loading: 'idle',
    error: null,
    loadedItemsCount: 24,
    treeMapName: [],
};

export const fetchTreeMapData = createAsyncThunk(
    'treeMap/fetchTreeMapData',
    async (options) => {
        const newOKPDCode = options.selectedProduct.map(item => item.productId);
        const { selectedTreeMapLabels, ...modifiedOptions } = options;

        const mergedOptions = { ...modifiedOptions, newOKPDCode };
        const response = await fetchTreeMap(mergedOptions);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);

const treeMapChartSlice = createSlice({
    name: 'treeMapChart',
    initialState,
    reducers: {
        increaseLoadedItemsCount: (state, action) => {
            state.loadedItemsCount += action.payload || 24;
        },
        resetTreeMapData: (state) => {
            return initialState;
        },
        addTreeMapName: (state, action) => {
            state.treeMapName = [action.payload];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTreeMapData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchTreeMapData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.TreeMapData = action.payload;
                }
            })
            .addCase(fetchTreeMapData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const {
    addTreeMapName,
    resetTreeMapData,
    increaseLoadedItemsCount
} = treeMapChartSlice.actions;
export default treeMapChartSlice.reducer;
