import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import style from '../AddGroup/GourpWizzard.module.css';
import styles from '../../EditFormGlobal/EditForm.module.css';
import btnStyle from '../../CustomModalChanger/CustomModal.module.css';
import {
    addCompanyNotInGroup,
    cancelAllPendingRequests,
    deleteSoloCompany,
    searchCompanies,
} from "../../../api/api";
import trash from "../../../common/icons/trashIcon.svg";
import SearchAutocomplete from "../../SearchAutoComplete/SearchAutoComplete";
import { useDispatch } from "react-redux";
import { removeParam, setParam } from "../../../service/reducers/FilterSearchSlice";

const InnWizard = ({ defaultCompanyType }) => {
    const [selectedType, setSelectedType] = useState('company');
    const [selectedCompanyType, setSelectedCompanyType] = useState(defaultCompanyType || 'contractor');
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [confirmModal, setConfirmModal] = useState({ open: false, data: null, type: null });
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            setSelectedType('company');
            setSelectedCompanyType('contractor');
            setSelectedCompanies([]);
            setSearchResults([]);
            setInputValue('');
            setConfirmModal({ open: false, data: null, type: null });
            setError("");
        };
    }, []);

    const handleSearchChange = async (event, newInputValue) => {
        cancelAllPendingRequests();
        if (newInputValue.length > 0) {
            try {
                const results = await searchCompanies(newInputValue);
                let filteredResults = [];
                if (results.length > 0) {
                    if (selectedCompanyType === 'customer') {
                        filteredResults = results.filter(company => company.customer);
                    } else if (selectedCompanyType === 'contractor') {
                        filteredResults = results.filter(company => company.suppliers);
                    } else {
                        filteredResults = results;
                    }
                    setSearchResults(filteredResults.slice(0, 30));
                } else {
                    setSearchResults([{ short_name: 'Ничего не найдено', id: 'not_found' }]);
                }
            } catch (error) {
                console.error('Ошибка при выполнении поиска:', error);
                setSearchResults([]);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleDeletInn = async (company) => {
        const inn = extractInn(company);
        setSelectedCompanies(currentCompanies => currentCompanies.filter(c => !c.includes(`(${inn})`)));
        if (defaultCompanyType) {
            dispatch(removeParam({ key: 'customerInn', value: inn }));
            setInputValue("");
        } else {
            try {
                await deleteSoloCompany(inn);
            } catch (error) {
                console.error('Ошибка при удалении компании:', error);
            }
        }
    };

    const extractInn = (newValue) => {
        const match = newValue.match(/\((\d+)\)/);
        return match ? match[1] : null;
    };

    const handleSelectCompany = async (event, newValue, reason) => {
        if (reason === 'selectOption') {
            if (!selectedCompanies.includes(newValue)) {
                const inn = extractInn(newValue);
                if (defaultCompanyType) {
                    dispatch(setParam({ key: 'customerInn', value: inn }));
                    setSelectedCompanies([...selectedCompanies, newValue]);
                } else {
                    if (inn) {
                        try {
                            await addCompanyNotInGroup(inn);
                            setSelectedCompanies([...selectedCompanies, newValue]);
                        } catch (error) {
                            console.error('Ошибка при добавлении компании:', error);
                            if (error.response && error.response.status === 400 && error.response.data.message === "limit exceeded") {
                                setError("Превышен лимит на добавление компаний.");
                            }
                        }
                    }
                }
            }
            setInputValue("");
        }
    };

    const showConfirmModal = (data, type) => {
        setConfirmModal({ open: true, data, type });
    };

    const hideConfirmModal = () => {
        setConfirmModal({ open: false, data: null });
    };

    const confirmDelete = async () => {
        switch (confirmModal.type) {
            case 'company':
                await handleDeletInn(confirmModal.data);
                break;
            default:
                console.error('Неизвестный тип для удаления');
        }
        hideConfirmModal();
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        handleSearchChange(event, newInputValue);
    };

    return (
        <div>
            <div>
                <div className={styles.formControl}>
                    {!defaultCompanyType && selectedType === 'company' && (
                        <div>
                            <div>Выберите тип группы</div>
                            <ToggleButtonGroup
                                value={selectedCompanyType}
                                exclusive
                                onChange={(e, newValue) => setSelectedCompanyType(newValue)}
                                style={{ fontFamily: 'Golos Regular', fontSize: '14px', marginTop: '5px', height: '24px' }}
                            >
                                <ToggleButton
                                    value="contractor"
                                    className={`${style.toggleButton} ${selectedCompanyType === 'contractor' ? style.active : ''}`}
                                >
                                    Подрядчик
                                </ToggleButton>
                                <ToggleButton
                                    value="customer"
                                    className={`${style.toggleButton} ${selectedCompanyType === 'customer' ? style.active : ''}`}
                                >
                                    Заказчик
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    )}
                </div>
            </div>
            <div>
                {selectedType === 'company' && selectedCompanyType === 'contractor' && (
                    <>
                        <div className={styles.formControl}>
                            Найдите и выберите Компанию Подрядчика
                        </div>
                        <SearchAutocomplete
                            options={searchResults.map((option) => `${option.short_name} (${option.inn})`)}
                            onChange={handleSelectCompany}
                            inputValue={inputValue}
                            onInputChange={handleInputChange}
                            placeholder="Введите название компании"
                            noOptionsText="Ничего не найдено"
                            value={inputValue}
                        />
                    </>
                )}
                {selectedType === 'company' && selectedCompanyType === 'customer' && (
                    <>
                        <div className={styles.formControl}>
                            Найдите и выберите Компанию Заказчика
                        </div>
                        <SearchAutocomplete
                            options={searchResults.map((option) => `${option.short_name} (${option.inn})`)}
                            onChange={handleSelectCompany}
                            onInputChange={handleSearchChange}
                            placeholder="Введите название компании"
                            noOptionsText="Ничего не найдено"
                        />
                    </>
                )}
                {error && (
                    <div className={style.errorConatiner}>
                        {error}
                    </div>
                )}
                {selectedCompanies.length > 0 && (
                    <div className={style.companiesContainer}>
                        {selectedCompanies.map((company, index) => (
                            <div className={style.companyMapContainer} key={index}>
                                <div>{company}</div>
                                <img
                                    className={style.trashIco}
                                    src={trash}
                                    alt="trash"
                                    onClick={() => showConfirmModal(company, 'company')}
                                />
                            </div>
                        ))}
                    </div>
                )}
                {confirmModal.open && (
                    <div className={`${style.confirmModal} ${confirmModal.open ? style.confirmModalActive : ''}`}>
                        {confirmModal.type === 'company' && (
                            <p>Подтвердите удаление компании {confirmModal.data}</p>
                        )}
                        <div className={style.confirmModalBtnGroup}>
                            <button className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton}`} onClick={confirmDelete}>Да</button>
                            <button className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton} ${btnStyle.redButton}`} onClick={hideConfirmModal}>Отмена</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InnWizard;
