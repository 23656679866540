import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {PURCHASE} from "../../api/config";

export const fetchLikeResults = createAsyncThunk(
    'likes/fetchLikeResults',
    async () => {
        const response = await axios.get(`${PURCHASE}/subscribe`, {
            withCredentials: true
        });
        return response.data.result;
    }
);

const initialState = {
    likeResults: [],
    status: 'idle',
    error: null
};

const likesSlice = createSlice({
    name: 'likes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLikeResults.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLikeResults.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.likeResults = action.payload;
            })
            .addCase(fetchLikeResults.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default likesSlice.reducer;
