import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import style from "./TreeMapDiagramm.module.css";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import { treeMapColors } from "../../../utils/colors"
import Spinner from "../../TestPages/Spinner";
import { useDispatch, useSelector } from "react-redux";
import useResizeObserver from "use-resize-observer";
import {
    addTreeMapName,
    fetchTreeMapData,
    increaseLoadedItemsCount
} from "../../../service/reducers/TreeMapChart/TreeMapChartSlice";
import styles from "../TestMapD/GeoChart.module.css"
import {
    clearSelectedSegments,
    setTreeMapMode,
    toggleSelectedSegment
} from "../../../service/reducers/TreeMapChart/TreeMapChartSegmentSlice";
import { useVirtualTooltipSize } from "../../../hook/useVirtualTooltipSize";
import { cancelAllPendingRequests } from "../../../api/api";
import tooltipNames from "../../../utils/tooltipTitles.json"
import { formatCurrency } from "../../../utils/rubbleFunc";
import useFetchData from "../../../hook/useFetchData";
import useTooltip from '../../../hook/useTooltip';
import Tooltip from '../../../components/Tooltip/Tooltip';

const TreeMapDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const ref = useRef();
    const { width, height } = useResizeObserver({ ref });
    const { TreeMapData, loading, loadedItemsCount } = useSelector((state) => state.treemap);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const selectedTreeMapLabels = selectedSegments.map(segment => segment.label);
    const { treeMapMode } = useSelector((state) => state.treeMapSlice);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const regionComboSelect = useSelector((state) => state.regionComboSelect.regionComboData);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const shouldShowChangeButton = (selectedOrganization.type === 'okpd' || selectedOrganization.type === 'region' || filterOkpd.length > 0 || regionComboSelect.length > 0) && activeTab !== 'Извещения';
    let headerTitle;

    if (activeTab === 'Извещения') {
        headerTitle = 'Сравнение объемов заказчиков';
    }
    else if ((selectedOrganization.type === 'company_customer' && activeTab === 'Контракты') || slidePoz === 'supplier') {
        headerTitle = 'Структура контрактов по исполнителям';
    }
    else if ((selectedOrganization.type === 'company_suppliers' && activeTab === 'Контракты') || slidePoz === 'customer') {
        headerTitle = 'Структура контрактов по заказчикам';
    }
    else if (shouldShowChangeButton && treeMapMode === '') {
        headerTitle = "Структура контрактов по исполнителям";
    }
    else {
        switch (treeMapMode) {
            case 'cust':
                headerTitle = "Структура контрактов по исполнителям";
                break;
            case 'org':
                headerTitle = "Структура контрактов по заказчикам";
                break;
            default:
                headerTitle = activeTab === 'Извещения'
                    ? "Сравнение объемов заказчиков"
                    : "Структура объемов поставщиков";
        }
    }
    const headerWithThreeButtons = {
        title: headerTitle,
        icons: [
            ...shouldShowChangeButton ? [{ name: 'change', icon: icons.change, width: 20, height: 20 }] : [],
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };

    useFetchData(fetchTreeMapData, [
        useSelector(state => state.stackedWithContentSegmentSlice.selectedMonth),
        useSelector(state => state.pieChartOtpSegment.selectedSlice),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.treeMapSlice.treeMapMode),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.activitySlice),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position)
    ], 'treeMapDiagramm');

    useEffect(() => {
        if (loading === 'successful' && width && height && TreeMapData && TreeMapData.nodes) {
            const sortedNodes = [...TreeMapData.nodes].sort((a, b) => b.value - a.value);
            const chunkedData = {
                ...TreeMapData,
                nodes: sortedNodes.slice(0, loadedItemsCount)
            };
            drawChart(chunkedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, TreeMapData, selectedSegments, loadedItemsCount]);

    const hasMoreData = loadedItemsCount < (TreeMapData?.nodes?.length || 0);
    const totalItemsCount = TreeMapData?.nodes?.length || 0;
    const legendTotal = `(${loadedItemsCount}/${totalItemsCount})`
    const remainingItemsCount = totalItemsCount - loadedItemsCount;
    const dynamicValue = Math.min(remainingItemsCount, 24);

    const loadMoreItems = () => {
        dispatch(increaseLoadedItemsCount(dynamicValue));
    };

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const fieldForName = activeTab === 'Извещения' ? 'CustFullName' : 'tooltype';
        const tooltipConfig = tooltipNames.TreeMapNotification.Tabs[activeTab];
        let tooltipText = [
            { label: tooltipConfig.label, value: d.data.extra.find(x => x.label === fieldForName)?.value || 'Н/Д' },
            { label: tooltipConfig.inn, value: d.data.label },
            { label: tooltipConfig.summary, value: formatCurrency(d.data.value) },
            { label: tooltipConfig.count, value: `${d.data.extra.find(x => x.label === 'count')?.value || 0} шт` }
        ];

        let tooltipSize = calculateTooltipSize(tooltipText.map(item => `<div><strong>${item.label}</strong>: ${item.value}</div>`));
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        showTooltip(event, tooltipText);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const handleSegmentClick = (d) => {
        cancelAllPendingRequests();
        onMouseOut();
        const tooltype = d.data.extra?.find(x => x.label === 'tooltype' || x.label === 'CustFullName')?.value;

        const segmentData = {
            label: d.data.label,
            tooltype: tooltype || 'Default Tooltype',
        };

        dispatch(addTreeMapName(headerTitle));
        dispatch(toggleSelectedSegment(segmentData));
    };

    const drawChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height);

        const root = d3.hierarchy({ children: data.nodes })
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value);

        d3.treemap()
            .tile(d3.treemapResquarify)
            .size([width, height])
            .padding(1)
            .round(true)
            (root);

        const color = d3.scaleOrdinal(treeMapColors);
        const leaf = svg.selectAll("g")
            .data(root.leaves())
            .enter().append("g")
            .attr("transform", d => `translate(${d.x0},${d.y0})`);

        leaf.append("rect")
            .attr("id", d => d.data.label)
            .attr("width", d => d.x1 - d.x0)
            .attr("height", 0)
            .attr("y", d => d.y1 - d.y0)
            .attr("fill", d => color(d.data.label))
            .attr("stroke", d => selectedTreeMapLabels.includes(d.data.label) ? "var(--tabText-color)" : "none")
            .attr("opacity", d => selectedTreeMapLabels.length === 0 || selectedTreeMapLabels.includes(d.data.label) ? 1 : 0.3)
            .on('mousemove', (event, d) => onMouseMove(event, d))
            .on('mouseout', onMouseOut)
            .on('click', (event, d) => handleSegmentClick(d))
            .transition()
            .duration(1000)
            .attr("height", d => d.y1 - d.y0)
            .attr("y", d => 0);
        leaf.append("text")
            .attr("x", 4)
            .attr("y", 13)
            .style("fill", "#ffffff")
            .style("font-size", "14px")
            .attr("font-family", "Golos Regular")
            .text(d => {
                const extra = d.data.extra || [];
                const custFullName = extra.find(e => e.label === 'CustFullName');
                const supFullName = extra.find(e => e.label === 'tooltype');
                const label = custFullName ? custFullName.value : (supFullName ? supFullName.value : d.data.label);
                return label;
            })
            .each(function (d) {
                const self = d3.select(this);
                let textLength = self.node().getComputedTextLength();
                let text = self.text();
                while (textLength > (d.x1 - d.x0 - 8) && text.length > 0) {
                    text = text.slice(0, -1);
                    self.text(`${text}…`);
                    textLength = self.node().getComputedTextLength();
                }
            });
    };

    return (
        <div className={`${style.container} ${selectedTreeMapLabels.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`}
             style={zoomedDiagram ? { height: "600px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    legendTotal={legendTotal}
                    diagramName={headerTitle}
                    activeMode={treeMapMode || 'cust'}
                    onZoomClick={onZoomClick}
                    dynamicValue={dynamicValue}
                    hasMoreData={hasMoreData}
                    loadMoreItems={loadMoreItems}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'customer?') {
                            dispatch(setTreeMapMode('cust'));
                            if (selectedSegments.length > 0) {
                                dispatch(clearSelectedSegments());
                            }
                        } else if (mode === 'supplier?') {
                            dispatch(setTreeMapMode('org'));
                            if (selectedSegments.length > 0) {
                                dispatch(clearSelectedSegments());
                            }
                        }
                        else {
                            dispatch(setTreeMapMode("cust"))
                        }
                    }}
                    diagramType="treeMap"
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`} />
                </>
            )}
        </div>
    );
};

export default TreeMapDiagramm;
