import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchPieOtp} from '../../../api/api';

const initialState = {
    PieOtpData: [],
    showCount: 'sum',
    loading: 'idle',
    error: null,
};

export const fetchPieOtpData = createAsyncThunk(
    'pieOtpChart/fetchPieOtpData',
    async (options, { rejectWithValue }) => {
        try {
            const { pieEtp, ...modifiedOptions } = options;
            const response = await fetchPieOtp(modifiedOptions);
            if ((response.status === 204 && response.data === "") || response.data.result === null) {
                return [];
            }
            return response.data.result.nodes;
        } catch (error) {
            if (error.message === 'unauthorized') {
                return rejectWithValue({ message: 'unauthorized' });
            }
            return rejectWithValue(error.response ? error.response.data : { message: 'Unknown error' });
        }
    }
);

const pieChartOtpSlice = createSlice({
    name: 'pieOtpChart',
    initialState,
    reducers: {
        setShowCount: (state, action) => {
            state.showCount = action.payload;
        },
        resetPieEtpData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPieOtpData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchPieOtpData.fulfilled, (state, action) => {
                state.loading = 'successful';
                state.PieOtpData = action.payload;
            })
            .addCase(fetchPieOtpData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { setShowCount,resetPieEtpData } = pieChartOtpSlice.actions;
export default pieChartOtpSlice.reducer;
