import React, { useState, useEffect } from 'react';
import style from '../OkpdTree/OkpdTree.module.css';
import locationsData from '../../utils/geoNamesMap.json';
import LocationItem from './LocationItem';
import { IconButton, TextField, CircularProgress } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import btnStyle from "../CustomModalChanger/CustomModal.module.css";
import { addRegion } from '../../api/api';

const LocationsList = ({ hideLimit = false, currentCount = 0, limit = null, onSelectChange, onAddRegionsComplete }) => {
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState(locationsData);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentSelectedCount, setCurrentSelectedCount] = useState(currentCount);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const data = filter
            ? locationsData.filter(item => item.region.toLowerCase().includes(filter.toLowerCase()))
            : locationsData;
        setFilteredData(data);
    }, [filter]);

    const handleClear = () => {
        setFilter('');
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleSelectChange = (region, isSelected) => {
        if (onSelectChange) {
            onSelectChange(region, isSelected);
        }
        setSelectedRegions(prevSelected =>
            isSelected ? [...prevSelected, region] : prevSelected.filter(r => r.ISO !== region.ISO)
        );
        setCurrentSelectedCount(prevCount => isSelected ? prevCount + 1 : prevCount - 1);
    };

    const handleAddRegions = async () => {
        setIsLoading(true);
        setErrorMessage(''); // Clear previous error message
        const requests = selectedRegions.map(region => addRegion(region));

        try {
            await Promise.all(requests);
            setSelectedRegions([]);
            if (onAddRegionsComplete) {
                onAddRegionsComplete();
            }
        } catch (error) {
            if (error.response && error.response.status === 500 && error.response.data.message.includes('duplicate key value violates unique constraint')) {
                setErrorMessage('Данный регион уже был ранее добавлен!');
            } else {
                console.error('Error adding regions:', error);
                setErrorMessage('Произошла ошибка при добавлении региона.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const isLimitReached = limit !== null && currentSelectedCount >= limit;

    return (
        <div className={`${style.areaContainer} ${style.okpdContainer}`}>
            {!hideLimit && limit !== null && (
                <div className={style.limitContainer}>Вы выбрали {currentSelectedCount}/{limit} регионов</div>
            )}
            <div>
                <TextField
                    InputProps={{
                        endAdornment: (
                            filter && (
                                <IconButton
                                    onClick={handleClear}
                                    style={{ padding: '5px' }}
                                >
                                    <ClearIcon style={{ fontSize: '20px', color: '#8D96B2' }} />
                                </IconButton>
                            )
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '6px',
                            backgroundColor: '#F7FAFC',
                            '& fieldset': {
                                borderColor: '#8D96B2',
                            },
                            '&:hover fieldset': {
                                borderColor: '#C8A1FF',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#4A3DFD',
                            },
                            '& .MuiInputBase-input': {
                                padding: '8px',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                        },
                        '& .MuiInputBase-input': {
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                        },
                        '& .MuiFormHelperText-root': {
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                        },
                    }}
                    className={style.formControl}
                    placeholder="Введите название города/области/региона"
                    value={filter}
                    onChange={handleFilterChange}
                />
            </div>
            <div className={style.scrollableList}>
                <ul>
                    {filteredData && filteredData.length > 0 ? (
                        filteredData.map(location => (
                            <LocationItem
                                key={location.region}
                                data={location}
                                onSelectChange={handleSelectChange}
                                isLimitReached={isLimitReached}
                                style={isLimitReached ? { color: '#8D96B2' } : {}}
                            />
                        ))
                    ) : (
                        <li>Нет данных для отображения</li>
                    )}
                </ul>
            </div>
            {limit !== null && (
                <>
                    <button
                        onClick={handleAddRegions}
                        className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton}`}
                        style={isLoading ? { opacity: 0.5 } : {}}
                        disabled={isLoading}
                    >
                        Добавить выбранные регионы
                    </button>
                    {isLoading && (
                        <div className={btnStyle.sendingContainer}>
                            <CircularProgress size={20} />
                            <span>Данные отправляются...</span>
                        </div>
                    )}
                    {errorMessage && (
                        <div className={btnStyle.errorMessage}>
                            {errorMessage}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default LocationsList;
