import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const SearchAutocomplete = ({ options, onChange, onInputChange, placeholder, noOptionsText, value, inputValue }) => {
    const getOptionLabel = (option) => {
        if (typeof option === 'string') {
            return option;
        }
        if (typeof option === 'object' && option.okpd_name && option.okpd_code) {
            return `${option.okpd_name} (${option.okpd_code})`;
        }
        return '';
    };

    return (
        <Autocomplete
            className="autocomplete-custom"
            onChange={onChange}
            onInputChange={onInputChange}
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder={placeholder} fullWidth />
            )}
            noOptionsText={noOptionsText}
            value={value}
            inputValue={inputValue}
            clearOnEscape
        />
    );
};

export default SearchAutocomplete;
