import React, { useEffect, useState } from 'react';
import styles from './CustomToast.module.css';

const CustomToast = ({ message, type, duration = 2000, onClose }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const showTimer = setTimeout(() => {
            setVisible(false);
        }, duration - 500);

        const closeTimer = setTimeout(() => {
            onClose();
        }, duration);

        return () => {
            clearTimeout(showTimer);
            clearTimeout(closeTimer);
        };
    }, [duration, onClose]);

    return (
        <div className={`${styles.toast} ${visible ? styles.show : styles.hide} ${type === 'add' ? styles.add : styles.remove}`}>
            {message}
        </div>
    );
};

export default CustomToast;
