export const updateLocalStorageDates = (newStartDate, newEndDate) => {
    const convertDateToLocalISO = (date, isEndDate = false) => {
        const tzOffset = date.getTimezoneOffset() * 60000;
        let localISODate;
        if (isEndDate) {
            localISODate = (new Date(date - tzOffset)).toISOString().slice(0, 11) + "12:00:00+03:00";
        } else {
            localISODate = (new Date(date - tzOffset)).toISOString().slice(0, -1) + "+03:00";
        }
        return localISODate.replace('T', ' ').replace(/\.\d+$/, '').replace(' ', 'T');
    };

    const storedDates = localStorage.getItem('dateForPickers');
    let datesArray = storedDates ? JSON.parse(storedDates) : [];

    const startDateIndex = datesArray.findIndex(date => date.type === 'start_date');
    if (startDateIndex > -1) {
        datesArray[startDateIndex].body = convertDateToLocalISO(newStartDate);
    } else {
        datesArray.push({ type: 'start_date', body: convertDateToLocalISO(newStartDate) });
    }

    const endDateIndex = datesArray.findIndex(date => date.type === 'end_date');
    if (endDateIndex > -1) {
        datesArray[endDateIndex].body = convertDateToLocalISO(newEndDate, true);
    } else {
        datesArray.push({ type: 'end_date', body: convertDateToLocalISO(newEndDate, true) });
    }

    localStorage.setItem('dateForPickers', JSON.stringify(datesArray));
};
