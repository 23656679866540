import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import { useDispatch, useSelector } from "react-redux";
import { activeColors } from "../../../utils/colors";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {
    fetchSunData,
    increaseLimit,
    selectCurrentLimit,
    selectLimPerPage, setSunKeyMode
} from "../../../service/reducers/SunKeyChartSlice";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";
import { formatCurrency } from "../../../utils/rubbleFunc";
import { findOkpdNameByCode } from "../../../utils/findOKPDNameGlobal";
import tooltipNames from "../../../utils/tooltipTitles.json";
import localStorageService from "../../../service/localStorage/localStorageService";
import useFetchData from "../../../hook/useFetchData";
import useTooltip from "../../../hook/useTooltip";
import Tooltip from "../../../components/Tooltip/Tooltip";

const SunDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const { width, height } = useResizeObserver({ ref });
    const { SunKeyData, loading} = useSelector((state) => state.sunKey);
    const { sunKeyMode } = useSelector((state) => state.sunKey);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const regionComboSelect = useSelector(state => state.regionComboSelect.regionComboData);
    const shouldShowChangeButton = (selectedOrganization.type === 'okpd' || selectedOrganization.type === 'region' || filterOkpd.length > 0 || regionComboSelect.length > 0) && activeTab !== 'Извещения';
    const headerWithThreeButtons = {
        title: 'Структура потоков средств через ОКПД2/НДС',
        icons: [
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            ...shouldShowChangeButton ? [{ name: 'change', icon: icons.change, width: 20, height: 20 }] : [],
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };
    const currentLimit = useSelector(selectCurrentLimit);
    const limPerPage = useSelector(selectLimPerPage);
    const shouldDisplayButton = currentLimit < limPerPage;
    const okpdData = localStorageService.getItem('okpdData') || [];

    const handleLoadMore = () => {
        dispatch(increaseLimit());
    };

    useFetchData(fetchSunData, [
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.sunKey.sunKeyMode),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.sunKey.currentLimit),
        useSelector(state => state.searchSwitcher.position),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.activitySlice),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.organization.selectedOrganization),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
    ], "sunKeyChart");

    useEffect(() => {
        if ( loading === 'successful' && width && height && SunKeyData) {
            createSunChart(SunKeyData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, SunKeyData]);

    const tooltipConfig = tooltipNames.Sunkey.Tabs[activeTab];

    const onMouseMove = (event, d) => {
        let tooltipData;
        const codeRegex = /^(\d+\.)+\d+$/;
        const innRegex = /^\d{10}(\d{2})?$/;
        if (codeRegex.test(d.name) || (d.name.length <= 2 && !innRegex.test(d.name))) {
            tooltipData = [
                { label: tooltipConfig.okpdCode, value: d.name },
                { label: tooltipConfig.okpdName, value: findOkpdNameByCode(d.name, okpdData) },
                { label: tooltipConfig.summary, value: formatCurrency(d.value) },
            ];
        } else {
            const targetInfo = d.targetLinks && d.targetLinks.length > 0 ? d.targetLinks[0].supFullName : '';
            const sourceInfo = d.sourceLinks && d.sourceLinks.length > 0 ? d.sourceLinks[0].supFullName : '';
            const companyName = targetInfo || sourceInfo;
            tooltipData = [
                { label: tooltipConfig.inn, value: d.name },
                { label: tooltipConfig.innName, value: companyName },
                { label: tooltipConfig.summary, value: formatCurrency(d.value) }
            ];
        }
        showTooltip(event, tooltipData);
    };

    const onMouseMoveLink = (event, d) => {
        const isINN = (code) => /^\d{10,12}$/.test(code);
        let sourceLabel, targetLabel;
        let sourceName, targetName;

        if (isINN(d.source.name.trim())) {
            sourceName = `${d.supFullName}(${d.source.name.trim()})`;
            sourceLabel = tooltipConfig.from;
        } else {
            sourceName = findOkpdNameByCode(d.source.name.trim(), okpdData);
            sourceLabel = tooltipConfig.from;
        }

        if (isINN(d.target.name.trim())) {
            targetName = `${d.supFullName}(${d.target.name.trim()})`;
            targetLabel = tooltipConfig.to;
        } else {
            targetName = findOkpdNameByCode(d.target.name.trim(), okpdData);
            targetLabel = tooltipConfig.to;
        }

        const tooltipData = [
            { label: sourceLabel, value: sourceName },
            { label: targetLabel, value: targetName },
            { label: tooltipConfig.summ, value: formatCurrency(d.value) },
        ];

        showTooltip(event, tooltipData);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const createSunChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3
            .select(ref.current)
            .append('svg')
            .attr("viewBox", [0, -10, width, height +10])
            .attr('width', width)
            .attr('height', height);
        const nodes = new Set();
        const links = [];

        if (data.nodes && Array.isArray(data.nodes)) {
            data.nodes.forEach((node) => {
                if (node.extra && node.extra.length >= 2) {
                    nodes.add(node.extra[0].value);
                    nodes.add(node.extra[1].value);
                    const fullName = node.extra.find(e => e.label === 'CustShortName' || e.label === 'SupShortName');

                    links.push({
                        source: node.extra[0].value,
                        target: node.extra[1].value,
                        value: node.value,
                        supFullName: fullName ? fullName.value : 'Неизвестно'
                    });
                }
            });
        } else {
            console.error('data.nodes is null or not an array');
            return;
        }

        const nodesArray = Array.from(nodes);

        const sankeyGenerator = sankey()
            .nodeWidth(10)
            .nodePadding(10)
            .extent([[0, 0], [width, height -10]]);

        const sankeyData = {
            nodes: nodesArray.map(name => ({ name })),
            links: links.map(d => Object.assign({}, d))
        };

        const nodeMap = new Map();
        sankeyData.nodes.forEach((node, index) => {
            nodeMap.set(node.name, index);
        });

        sankeyData.links.forEach(link => {
            link.source = nodeMap.get(link.source);
            link.target = nodeMap.get(link.target);
        });

        sankeyGenerator(sankeyData);

        const color = (name) => {
            const index = nodesArray.indexOf(name) % activeColors.length;
            return activeColors[index];
        };

        const defs = svg.append('defs');

        sankeyData.links.forEach((link, i) => {
            const gradient = defs.append('linearGradient')
                .attr('id', `gradient${i}`)
                .attr('gradientUnits', 'userSpaceOnUse')
                .attr('x1', link.source.x1)
                .attr('x2', link.target.x0);

            gradient.append('stop')
                .attr('offset', '0%')
                .attr('stop-color', color(link.source.name));

            gradient.append('stop')
                .attr('offset', '100%')
                .attr('stop-color', color(link.target.name));
        });

        svg.append("g")
            .attr("stroke", "#000")
            .selectAll("rect")
            .data(sankeyData.nodes)
            .join("rect")
            .attr("x", d => d.x0)
            .attr("y", d => d.y0)
            .attr("height", d => d.y1 - d.y0 + 1)
            .attr("width", d => d.x1 - d.x0)
            .attr("fill", d => color(d.name))
            .on("mouseover", function(event, d) {
                link.transition().duration(200).style("opacity", 0.1);
                link.filter(l => l.source.name === d.name || l.target.name === d.name)
                    .transition().duration(200).style("opacity", 1);
            })
            .on("mousemove", (event, d) => onMouseMove(event, d))
            .on("mouseout", function() {
                link.transition().duration(200).style("opacity", 1);
                onMouseOut();
            });

        const link = svg.append("g")
            .attr("fill", "none")
            .selectAll(".link")
            .data(sankeyData.links)
            .enter().append("path")
            .attr("class", "link")
            .attr("d", sankeyLinkHorizontal())
            .attr("stroke", (d, i) => `url(#gradient${i})`)
            .attr("stroke-width", d => Math.max(d.width, 1))
            .on("mouseover", function(event, d) {
                link.transition().duration(200).style("opacity", 0.1);
                d3.select(this).transition().duration(200).style("opacity", 1);
            })
            .on("mousemove", (event, d) => onMouseMoveLink(event, d))
            .on("mouseout", function() {
                link.transition().duration(200).style("opacity", 1);
                onMouseOut();
            });

        svg.append("g")
            .attr("font-family", "sans-serif")
            .attr("fill", "var(--text-color, #FFF)")
            .attr("font-size", 10)
            .selectAll("text")
            .data(sankeyData.nodes)
            .join("text")
            .attr("x", d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
            .attr("y", d => (d.y1 + d.y0) / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
            .text(d => d.name);

    };

    return (
        <div className={`${style.container} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "100vh"} : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    diagramName={headerWithThreeButtons.title}
                    activeMode={sunKeyMode || 'cust'}
                    hasMoreSan={shouldDisplayButton}
                    loadMoreSan={handleLoadMore}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'customer?') {
                            dispatch(setSunKeyMode('cust'));
                        } else if (mode === 'supplier?') {
                            dispatch(setSunKeyMode('org'));
                        }
                        else {
                            dispatch(setSunKeyMode("cust"))
                        }
                    }}
                    diagramType="sunKey"
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`} />
                </>
            )}
        </div>
    );
};

export default SunDiagramm;
