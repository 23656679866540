import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './SettingsContainer.module.css';
import closeIco from '../../../common/icons/xButtonGrey.svg'
import shevron from '../../../common/icons/testChevron.svg'
import style from "../../../components/Modal/ModalContent/ModalContent.module.css";
import xButtonIco from "../../../common/icons/xButtonBlack.svg";
import xButtonIcoDark from "../../../common/icons/xButtonGrey.svg";
import {useDispatch, useSelector} from "react-redux";
import {DatePickerWrapper} from "../SearchSettingsComponents/DatePickerSettings/DatePickerWrapper";
import {PriceInput} from "../SearchSettingsComponents/PriceInput/PriceInput";
import {CheckboxGroup} from "../SearchSettingsComponents/CheckBoxGroup/CheckBoxGroup";
import InnWizard from "../../../components/Modal/AddInn/InnWizard";
import OkpdTree from "../../../components/OkpdTree/OkpdTree";
import {clearParams, setParam} from "../../../service/reducers/FilterSearchSlice";
import AreaList from "../../../components/AreasTree/Areas";
import LocationsList from "../../../components/LocationsTree/Locations";
import {format} from "date-fns";
import {fetchSearchZData} from "../../../service/reducers/SearchZSlice";
import {
    resetWizardVisibility,
    setShowAreaWizard,
    setShowInnWizard,
    setShowISOWizard,
    setShowOkpdWizard, setShowPlacingWays,
} from "../../../service/reducers/WizardsSearchSlice";
import PlacingWays from "../../../components/PlacingTree/PlacingWays";

export const SettingsContainer = ({onClose, onSearchClick}) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState({});
    const switcher = useSelector(state => state.switcher.activeSwitch);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState('');
    const { showInnWizard, showAreaWizard, showOkpdWizard, showISOWizard, showPlacingWays } = useSelector(state => state.wizardVisibilitySlice);
    const queryParams = useSelector(state => state.searchFiltersSlice.searchParams);
    const { startDate, endDate } = useSelector(state => state.searchFiltersSlice.searchParams);
    useEffect(() => {
        setIsOpen({
            0: showInnWizard,
            1: showAreaWizard,
            2: showOkpdWizard,
            3: showISOWizard,
            7: showPlacingWays,
        });
    }, [showInnWizard, showAreaWizard, showOkpdWizard, showISOWizard,showPlacingWays]);
    const handleSearch = async () => {
        dispatch(fetchSearchZData(queryParams));
        if (onSearchClick) {
            onSearchClick();
        }
        onClose();
    };
    const handleCheckboxChange = (key) => {
        const currentValue = queryParams[key];
        dispatch(setParam({ key, value: !currentValue }));
    };

    const toggleAccordion = (index) => {
        setIsOpen(prevState => ({ ...prevState, [index]: !prevState[index] }));
    }

    const startPickerRef = useRef();
    const endPickerRef = useRef();

    const handlePriceChange = useCallback((e, setter, isMin) => {
        const value = e.target.value.replace(/\s/g, '');
        const number = value === '' ? (isMin ? 0 : 1000000) : Number(value);
        setter(value);
        dispatch(setParam({ key: isMin ? 'minPrice' : 'maxPrice', value: number }));
    }, [dispatch]);


    const openDatePicker = useCallback((pickerRef) => {
        pickerRef.current.setOpen(true);
    }, []);

    const priceInputs = (
        <div className={`${styles.datePickerContainer} ${styles.dataPickerContainerSmG}`}>
            <PriceInput
                label="Минимальная, ₽"
                value={minPrice}
                onChange={(e) => handlePriceChange(e, setMinPrice, true)}
                placeholder="0"
            />
            <PriceInput
                label="Максимальная, ₽"
                value={maxPrice}
                onChange={(e) => handlePriceChange(e, setMaxPrice, false)}
                placeholder="любая"
            />
        </div>
    )
    const handleStartDateChange = (date) => {
        dispatch(setParam({ key: 'startDate', value: format(date, 'yyyy-MM-dd') }));
    };

    const handleEndDateChange = (date) => {
        dispatch(setParam({ key: 'endDate', value: format(date, 'yyyy-MM-dd') }));
    };

    const datePirckeInputs = (
        <div className={styles.datePickerContainer}>
            <DatePickerWrapper
                label="Начиная с"
                date={startDate}
                setDate={handleStartDateChange}
                pickerRef={startPickerRef}
                openDatePicker={openDatePicker}
            />
            <DatePickerWrapper
                label="По"
                date={endDate}
                setDate={handleEndDateChange}
                pickerRef={endPickerRef}
                openDatePicker={openDatePicker}
                minDate={startDate}
            />
        </div>
        )

    const checkboxGroups = {
        6: [
            {
                label: "44-ФЗ",
                key: "is44FZChecked",
                checked: queryParams.is44FZChecked,
                onChange: () => handleCheckboxChange("is44FZChecked"),
            },
            {
                label: "223-ФЗ",
                key: "is223FZChecked",
                checked: queryParams.is223FZChecked,
                onChange: () => handleCheckboxChange("is223FZChecked"),
            },
        ],
        8: [
            {
                label: "С обеспечением",
                key: "isProvisionTrue",
                checked: queryParams.isProvisionTrue,
                onChange: () => handleCheckboxChange("isProvisionTrue"),
            },
            {
                label: "Без обеспечения",
                key: "isProvisionFalse",
                checked: queryParams.isProvisionFalse,
                onChange: () => handleCheckboxChange("isProvisionFalse"),
            },
        ],
        9: [
            {
                label: "Закупка у СМП и СОНО",
                key: "isSmp",
                checked: queryParams.isSmp,
                onChange: () => handleCheckboxChange("isSmp"),
            },
            {
                label: "Закупка является совместной",
                key: "isTogether",
                checked: queryParams.isTogether,
                onChange: () => handleCheckboxChange("isTogether"),
            },
        ],
    };

    const handleAddInnClick = () => dispatch(setShowInnWizard(true))
    const handleAddOkpdClick = () => dispatch(setShowOkpdWizard(true));

    const handleAddAreaClick = () => dispatch(setShowAreaWizard(true));
    const handleAddIsoClick = () => dispatch(setShowISOWizard(true));
    const handleAddPlacingWays = () => dispatch(setShowPlacingWays(true));


    const accordionContent = {
        0: showInnWizard ? (
            <InnWizard defaultCompanyType="customer"/>
        ) : (
            <div className={styles.addButton} onClick={handleAddInnClick}>Выбрать</div>
        ),
        1: showAreaWizard ? (
            <AreaList/>
            ) : (
            <div className={styles.addButton} onClick={handleAddAreaClick}>Выбрать</div>
            ),
        2: showOkpdWizard ? (
            <OkpdTree hideLimit={true} unlimitedSelection={true} />
        ) : (
            <div className={styles.addButton} onClick={handleAddOkpdClick}>Выбрать</div>
        ),
        3: showISOWizard ? (
            <LocationsList/>
            ) : (
            <div className={styles.addButton} onClick={handleAddIsoClick}>Выбрать</div>
            ),
        4: datePirckeInputs,
        5: priceInputs,
        6: (
            <div className={styles.checkBoxContainer}>
                <CheckboxGroup options={checkboxGroups[6]} />
            </div>
        ),
        7: showPlacingWays ? (
            <PlacingWays/>
        ) : (
            <div className={styles.addButton} onClick={handleAddPlacingWays}>Выбрать</div>
        ),
        8: (
            <div className={styles.checkBoxContainer}>
                <CheckboxGroup options={checkboxGroups[8]} />
            </div>
        ),
        9: (
            <div className={styles.checkBoxContainer}>
                <CheckboxGroup options={checkboxGroups[9]} />
            </div>
        ),
    };

    const resetFilters = () => {
        setMinPrice(0);
        setMaxPrice('');
        dispatch(resetWizardVisibility())
        dispatch(clearParams());
    };

    return (
        <div className={styles.container}>
            <div className={`${style.additionalMr} ${style.contentHeader}`}>
                <div>
                    Расширенный поиск
                </div>
                <div className={style.closeXIcon} onClick={onClose}>
                    {switcher === 'sun' ?
                        <img src={xButtonIco} alt="sunIcon" /> :
                        <img src={xButtonIcoDark} alt="moonIcon" />
                    }
                </div>
            </div>
            <div className={styles.filterSection}>
                {['Заказчик', 'Площадка', 'ОКПД', 'Местоположение заказчика', 'Опубликовано', 'Цена', 'Нормативные акты', 'Способ определения поставщика', 'Обеспечение', 'Дополнительная информация'].map((item, index) => (
                    <div key={index}>
                        <div className={styles.accordionHeader} onClick={() => toggleAccordion(index)}>
                            <img src={shevron} alt="toggle" className={`${styles.accordionIcon} ${isOpen[index] ? styles.rotateIcon : ''}`} />
                            <div className={styles.accordionHeaderTitle}>{item}</div>
                        </div>
                        <div className={`${styles.accordionContent} ${isOpen[index] ? styles.accordionOpen : ''}`}>
                            {accordionContent[index]}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.footer}>
                <div className={styles.footerButtonClose} onClick={resetFilters}>
                    <img src={closeIco} alt="close"/>
                    <div className={styles.footerBtnText}>Сбросить фильтры</div>
                </div>
                <button onClick={handleSearch} className={styles.searchButton}>Найти</button>
            </div>
        </div>
    );
}
