import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    regionComboData: [],
};

const regionComboSelect = createSlice({
    name: 'regionComboSelect',
    initialState,
    reducers: {
        setRegionCode: (state, action) => {
            state.regionComboData.push(action.payload);
        },
        resetRegionCode: (state) => {
            state.regionComboData = [];
        },
    }
});

export const { setRegionCode, resetRegionCode } = regionComboSelect.actions;

export default regionComboSelect.reducer;
