import React, { useEffect, useRef, useState } from 'react';
import SearchCard from "../../../components/SearchCards/SearchCard";
import styles from './SearchResult.module.css';
import style from '../LikeResults/LikeResultCard/LikeResultCard.module.css';
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchZData } from "../../../service/reducers/SearchZSlice";
import { CustomLoader } from "../../TestPages/LoaderComponent/CustomLoader";
import SearchLikeIco from "../../../common/icons/SearchIcons/SearchLikeIco.svg";
import axios from 'axios';
import { PURCHASE } from "../../../api/config";
import { setFormValidity } from "../../../service/reducers/ModalCloseStatusSlice";
import CustomModalContent from "../../../components/CustomModalChanger/CustomModal";
import { useModal } from "../../../hook/useModal";
import { fetchLikeResults } from "../../../service/reducers/searchLikesSlice";
import PurchaseSearchWizard from "../../../components/Modal/AddSeaarchPurchase/AddSearchPurchase";
import { fetchPurchaseLikesData } from "../../../service/reducers/purchaseLikesSlice";
import searchIcon from '../../../common/icons/SearchIcon.svg';

const SearchResults = ({ showLikedPurchases, onReturnToSearch, searchText, shouldFetchData, savedSearchName }) => {
    const loading = useSelector(state => state.searchZSlice.searchDataloading);
    const queryParams = useSelector(state => state.searchFiltersSlice.searchParams);
    const dispatch = useDispatch();
    const { setIsModalVisible, setModalContent, setIsCentered } = useModal();
    const [wizardKey, setWizardKey] = useState(Date.now());
    const errorMessageRef = useRef('');
    const likedPurchase = useSelector(state => state.purchaseLikes.likesData);

    useEffect(() => {
        if (shouldFetchData) {
            dispatch(fetchSearchZData(queryParams));
        }
        dispatch(fetchLikeResults());
        dispatch(fetchPurchaseLikesData());
        // eslint-disable-next-line
    }, [dispatch, shouldFetchData]);

    const searchData = useSelector(state => state.searchZSlice.searchData.data);
    const totalStrings = useSelector(state => state.searchZSlice.searchData.total);

    const filteredData = showLikedPurchases
        ? likedPurchase.filter(result => result.title.toLowerCase().includes(searchText.toLowerCase()))
        : searchData;

    const handleIconClick = async (searchName) => {
        const requestData = {
            title: searchName,
            data: queryParams
        };

        try {
            const response = await axios.post(`${PURCHASE}/subscribe`, requestData, {
                withCredentials: true
            });
            if (response.data.success) {
                setIsCentered(false);
                setIsModalVisible(false);
                dispatch(fetchLikeResults());
                errorMessageRef.current = '';
            }
        } catch (error) {
            console.error("Request failed", error);
            if (error.response && error.response.status === 400) {
                errorMessageRef.current = 'Вы исчерпали лимиты поисковых наборов';
            }
        }
    };

    const handleAddGroupClick = () => {
        setWizardKey(Date.now());
        dispatch(setFormValidity(true));
        errorMessageRef.current = '';
        setModalContent(
            <CustomModalContent
                title="Новый поисковой набор"
                formComponent={
                    <PurchaseSearchWizard
                        key={wizardKey}
                        onClose={() => {
                            setIsCentered(false);
                            setIsModalVisible(false);
                            dispatch(fetchLikeResults());
                            errorMessageRef.current = '';
                        }}
                        onSubmit={handleIconClick}
                        errorMessageRef={errorMessageRef}
                    />
                }
                buttons={[]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                    dispatch(fetchLikeResults());
                    errorMessageRef.current = '';
                }}
            />
        );

        setIsCentered(true);
        setIsModalVisible(true);
    };

    return (
        <div className={styles.cardContainer}>
            {loading === 'pending' ? (
                <CustomLoader />
            ) : (
                <>
                    <div className={styles.headerSearch}>
                        <div className={styles.headerTitle}>
                            {savedSearchName
                                ? `Результат поиска по сохраненному набору: ${savedSearchName}`
                                : showLikedPurchases
                                    ? 'Сохраненные закупки'
                                    : 'Результаты поиска'
                            }
                        </div>
                        <div className={styles.subHeader}>
                            {showLikedPurchases ? likedPurchase.length : totalStrings} закупок
                        </div>
                        {showLikedPurchases ? (
                            <div className={style.returnSearchContainer} onClick={onReturnToSearch}>
                                <span className={style.headerReturnText}>Назад к поиску</span>
                                <img src={searchIcon} alt="searchIcon" />
                            </div>
                        ) : (
                            <img
                                src={SearchLikeIco}
                                alt="Likes"
                                className={styles.searchIcon}
                                onClick={handleAddGroupClick}
                            />
                        )}
                    </div>
                    <div>
                        {filteredData?.map((result, index) => (
                            <SearchCard
                                key={index}
                                data={result}
                                likedPurchase={likedPurchase}
                                searchText={showLikedPurchases ? searchText : ''}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default SearchResults;
