import React, { useState, useEffect } from "react";
import styles from "../../components/Layout/Layout.module.css";
import CommonHeader from "../../components/Header/ComonHeader";
import HeadSearchContent from "./HeadSearchContent/HeadSearchContent";
import SearchResults from "./SearchResult/SearchResult";
import { SearchModalProvider } from "../../hook/useSearchModal";
import LikeResultsComponent from "./LikeResults/LikeResultsComponent";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

export const TestSearch = () => {
    const [showLikeResults, setShowLikeResults] = useState(false);
    const [showLikedPurchases, setShowLikedPurchases] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [shouldFetchData, setShouldFetchData] = useState(true);
    const [savedSearchName, setSavedSearchName] = useState('');
    const likeResults = useSelector(state => state.likes.likeResults);
    const [iconCLicked, setIconClicked] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('showLikeResults') === 'true') {
            setShowLikeResults(true);
            setShowLikedPurchases(false);
            setSavedSearchName('');
            setIconClicked(true);
            navigate("/content/search", { replace: true });
        }
    }, [location, navigate]);

    const handleShowLikeResults = () => {
        setShowLikeResults(true);
        setShowLikedPurchases(false);
        setSavedSearchName('');
        setIconClicked(true)
    };

    const handleShowSearchResults = () => {
        setShowLikeResults(false);
        setShowLikedPurchases(false);
        setShouldFetchData(true);
        setSavedSearchName('');
        setIconClicked(false)
    };

    const handleShowLikedPurchases = () => {
        setShowLikeResults(false);
        setShowLikedPurchases(!showLikedPurchases);
        setSavedSearchName('');
        setIconClicked(true)
    };

    const handleInputChange = (value) => {
        setSearchText(value);
    };

    const handleOpenSearchResults = (name) => {
        setShowLikeResults(false);
        setShowLikedPurchases(false);
        setShouldFetchData(false);
        setSavedSearchName(name);
        setIconClicked(false)
    };

    const handleSearchClick = () => {
        setSavedSearchName('');
    };

    return (
        <div className={styles.mainContainer}>
            <SearchModalProvider>
                <CommonHeader title="Поиск закупок" />
                <HeadSearchContent
                    isSavedSearches={showLikeResults}
                    onShowLikeResults={handleShowLikeResults}
                    onShowSearchResults={handleShowSearchResults}
                    onShowLikedPurchases={handleShowLikedPurchases}
                    onInputChange={handleInputChange}
                    inputValue={searchText}
                    showLikedPurchases={showLikedPurchases}
                    onSearchClick={handleSearchClick}
                    iconClickedProp={iconCLicked}
                />
                {showLikeResults ? (
                    <LikeResultsComponent
                        likeResults={likeResults}
                        onReturnToSearch={handleShowSearchResults}
                        searchText={searchText}
                        onOpenSearchResults={handleOpenSearchResults}
                    />
                ) : (
                    <SearchResults
                        showLikedPurchases={showLikedPurchases}
                        onReturnToSearch={handleShowSearchResults}
                        searchText={searchText}
                        shouldFetchData={shouldFetchData}
                        savedSearchName={savedSearchName}
                    />
                )}
            </SearchModalProvider>
        </div>
    );
};

export default TestSearch;
