import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import { useSelector } from "react-redux";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import { fetchIndicatorData } from "../../../service/reducers/IndicatorChartSlice";
import { formatNumberWithDecimal } from "../../../utils/countFunc";
import styles from "../TestMapD/GeoChart.module.css";
import { formatCurrency } from "../../../utils/rubbleFunc";
import { useVirtualTooltipSize } from "../../../hook/useVirtualTooltipSize";
import tooltipNames from "../../../utils/tooltipTitles.json";
import useFetchData from "../../../hook/useFetchData";
import useTooltip from '../../../hook/useTooltip';
import Tooltip from '../../../components/Tooltip/Tooltip';

const IndicatorDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const ref = useRef();
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const { width, height } = useResizeObserver({ ref });
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const { IndicatorData, loading } = useSelector((state) => state.indicator);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);

    const headerWithTwoButtons = {
        title: 'Индикатор исполнения контрактов',
        icons: [
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };

    useFetchData(fetchIndicatorData, [
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.activitySlice),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.searchSwitcher.position)
    ]);

    useEffect(() => {
        if (loading === 'successful' && width && height && IndicatorData && IndicatorData.nodes) {
            createIndicator(IndicatorData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, IndicatorData]);

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d, part) => {
        const currentValue = IndicatorData.nodes.find((node) => node.label === 'current').value;
        const plannedValue = IndicatorData.nodes.find((node) => node.label === 'planned').value;
        let textObject;

        if (part === 'fulfilled') {
            const tooltipConfig = tooltipNames.Indicator.Tabs[activeTab];
            textObject = [
                { label: tooltipConfig.label, value: `${formatCurrency(currentValue)} из ${formatCurrency(plannedValue)}` },
            ];
        } else {
            const unfulfilledValue = plannedValue - currentValue;
            textObject = [
                { label: "Не исполнено", value: formatCurrency(unfulfilledValue) }
            ];
        }

        const tooltipSize = calculateTooltipSize(textObject);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }
        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        showTooltip(event, textObject);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const createIndicator = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3
            .select(ref.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height);

        const planned = data.nodes.find((node) => node.label === 'planned').value;
        const current = data.nodes.find((node) => node.label === 'current').value;
        const scaleFactor = Math.min(width / 2, height / 1.3);
        const effectiveRadius = scaleFactor;
        const initialAngle = -Math.PI / 2;
        const finalAngle = (-Math.PI / 2) + (Math.PI * current / planned);

        const arcGenerator = d3.arc()
            .innerRadius(effectiveRadius * 0.8)
            .outerRadius(effectiveRadius)
            .startAngle(-Math.PI / 2)
            .endAngle(Math.PI / 2);

        const g = svg.append('g')
            .attr('transform', `translate(${width / 2},${height / 2 + scaleFactor * 0.5})`);

        g.append('path')
            .attr('d', arcGenerator())
            .attr('fill', '#EDF1F5')
            .on("mousemove", (event, d) => onMouseMove(event, d, 'unfulfilled'))
            .on("mouseout", onMouseOut);

        const path = g.append('path')
            .attr('d', arcGenerator.endAngle(initialAngle))
            .attr('fill', '#4B72FB')
            .attr('opacity', 1)
            .on("mousemove", (event, d) => onMouseMove(event, d, 'fulfilled'))
            .on("mouseout", onMouseOut);

        path.transition()
            .duration(800)
            .ease(d3.easeLinear)
            .attrTween('d', function () {
                const interpolate = d3.interpolate(initialAngle, finalAngle);
                return function (t) {
                    return arcGenerator.endAngle(interpolate(t))();
                };
            });

        g.append('text')
            .attr('x', 0)
            .attr('y', -scaleFactor * 0.2)
            .attr("fill", "var(--text-color, #FFF)")
            .attr('text-anchor', 'middle')
            .style("font-family", "Golos Regular")
            .attr('dominant-baseline', 'central')
            .attr('font-size', `${Math.min(width, height) * 0.1}px`)
            .text(formatNumberWithDecimal(current));

        g.append('text')
            .attr('x', -effectiveRadius / 1.1)
            .attr('y', scaleFactor * 0.1)
            .attr('text-anchor', 'middle')
            .style("font-family", "Golos Regular")
            .attr('dominant-baseline', 'central')
            .attr('font-size', `${Math.min(width, height) * 0.05}px`)
            .style("fill", "#8D96B2")
            .text('0');

        g.append('text')
            .attr('x', effectiveRadius / 1.2)
            .attr('y', scaleFactor * 0.1)
            .attr('text-anchor', 'middle')
            .style("font-family", "Golos Regular")
            .attr('dominant-baseline', 'central')
            .attr('font-size', `${Math.min(width, height) * 0.05}px`)
            .style("fill", "#8D96B2")
            .text(formatNumberWithDecimal(planned));
    };

    return (
        <div className={`${style.container} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "600px" } : {}}>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <HeaderDiagram
                            {...headerWithTwoButtons}
                            diagramName={headerWithTwoButtons.title}
                            onZoomClick={onZoomClick}
                        />
                    </div>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`} />
                </>
            )}
        </div>
    );
};

export default IndicatorDiagramm;
