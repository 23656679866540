import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styleTarrif from './TarrifsContainer.module.css';
import TarrifCard from './TarrifCard/TarrifCard';
import styles from '../InputForm/DynamicForm.module.css';

const Tarrifs = ({ data, onTariffChange }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [currentPrice, setCurrentPrice] = useState(0);

    useEffect(() => {
        if (!data || !Array.isArray(data) || data.length === 0) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const tariffId = urlParams.get('tariff_id');
        const selectedTariff = data.find(tariff => tariff.id === tariffId) || data[0];
        const period = tariffId && urlParams.get('period') === '12 months' ? '12 months' : '1 month';

        const defaultOption = { value: `${selectedTariff.label}, ${period}`, label: `${selectedTariff.label} тариф на ${period === '12 months' ? '12 месяцев' : '1 месяц'}` };
        setSelectedOption(defaultOption);

        const price = getPrice(selectedTariff, period);
        setCurrentPrice(price);
        onTariffChange(price, selectedTariff.id, selectedTariff.label);
// eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (!data || !Array.isArray(data)) {
            return;
        }

        if (!selectedOption) return;

        const selectedTariff = data.find(tariff => selectedOption.value.includes(tariff.label));
        if (!selectedTariff) {
            return;
        }

        const period = selectedOption.value.includes('12 months') ? '12 months' : '1 month';
        const price = getPrice(selectedTariff, period);
        setCurrentPrice(price);
        onTariffChange(price, selectedTariff.id, selectedTariff.label);
        // eslint-disable-next-line
    }, [selectedOption, data]);

    if (!Array.isArray(data)) {
        return null;
    }

    const prepareItemsForCard = (tariff) => {
        const items = [];
        if (!tariff) return { items: [], subTitle: '' };

        const getCountOrDash = (count) => count === 0 ? '0' : count;

        if (tariff.label === 'Базовый') {
            items.push({ label: 'Базовых пользователей', count: getCountOrDash(tariff.count_base_users) });
        } else if (tariff.label === 'Оптимальный' || tariff.label === 'Продвинутый') {
            items.push({ label: 'Продвинутых пользователей', count: getCountOrDash(tariff.count_improved_users) });
        }
        items.push({ label: 'Доступно ОКПД на выбор', count: getCountOrDash(tariff.count_okp_ds) });
        items.push({ label: 'Доступно групп для объединения', count: getCountOrDash(tariff.count_groups) });
        items.push({ label: 'Количество ОКПД в группе', count: getCountOrDash(tariff.count_okp_ds_in_group) });
        items.push({ label: 'Количество компаний в группе', count: getCountOrDash(tariff.count_companies_in_group) });
        items.push({ label: 'Доступно регионов', count: getCountOrDash(tariff.count_region) });
        items.push({ label: 'Конфигураций для поиска', count: getCountOrDash(tariff.config_search) });

        let subTitle = '';
        if (tariff.label === 'Базовый') {
            subTitle = 'Общий анализ всех участников госзакупок. Основной выбор для начинающих';
        } else if (tariff.label === 'Оптимальный') {
            subTitle = 'Расширенные возможности анализа, включая группировку и выбор регионов';
        } else if (tariff.label === 'Продвинутый') {
            subTitle = 'Максимальные аналитические возможности для глубокого и детального анализа';
        }

        return { items, subTitle };
    };

    const calculateYearlySavings = (priceMonth, priceYear) => {
        return (priceMonth * 12) - priceYear;
    };

    const getPrice = (tariff, period) => {
        return period === '1 month' ? tariff.price_month : tariff.price_year;
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);

        const selectedTariff = data.find(tariff => selectedOption.value.includes(tariff.label));
        if (selectedTariff) {
            const period = selectedOption.value.includes('12 months') ? '12 months' : '1 month';
            const price = getPrice(selectedTariff, period);
            onTariffChange(price, selectedTariff.id, selectedTariff.label);
        }
    };

    const options = data.flatMap(tariff => [
        { value: `${tariff.label}, 1 month`, label: <span><strong>{tariff.label}</strong> тариф на 1 месяц</span> },
        { value: `${tariff.label}, 12 months`, label: <span><strong>{tariff.label}</strong> тариф на 12 месяцев</span> }
    ]);

    const selectedTariff = data.find(tariff => selectedOption?.value.includes(tariff.label));
    const period = selectedOption?.value.includes('12 months') ? '12 months' : '1 month';
    const { items, subTitle } = prepareItemsForCard(selectedTariff);

    return (
        <>
            <div className={`${styles.noSb} ${styles.formContainer}`}>
                <div className={styleTarrif.dropdownContainer}>
                    <Select
                        className={styleTarrif.dropdown}
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={options}
                        isSearchable={false}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                cursor: 'pointer',
                                backgroundColor: '#F7FAFC',
                                borderColor: '#4F19AB',
                                '&:hover': { borderColor: '#4F19AB' },
                                boxShadow: 'none'
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                cursor: 'pointer',
                                fontWeight: state.isSelected ? 'bold' : 'normal'
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                fontWeight: 'bold'
                            }),
                            dropdownIndicator: (provided) => ({
                                ...provided,
                                color: '#4F19AB',
                                '&:hover': { color: '#4F19AB' }
                            }),
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                backgroundColor: '#4F19AB'
                            }),
                        }}
                    />
                </div>
                {selectedTariff && (
                    <TarrifCard
                        title={selectedTariff.label}
                        items={items}
                        price={currentPrice}
                        savings={period === '12 months' ? calculateYearlySavings(selectedTariff.price_month, selectedTariff.price_year) : 0}
                        subTitle={subTitle}
                        isSelectedYear={period === '12 months'}
                    />
                )}
            </div>
        </>
    )
}

export default Tarrifs;
