
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getUserRegion} from "../../api/api";

const regionsDataInitialState = {
    regionsLkData: [],
    isLoadingRegionsLkrData: false,
    regionsLkDataError: null,
};

export const fetchRegionsLkData = createAsyncThunk(
    'regionsLkData/fetchRegionsLkData',
    async () => {
        const response = await getUserRegion();
        return response.data.result;    }
);

const regionsLkData = createSlice({
    name: 'regionsLkData',
    initialState: regionsDataInitialState,
    reducers: {
        clearRegionsLkData: (state) => {
            state.regionsLkData = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRegionsLkData.pending, (state) => {
                state.isLoadingRegionsLkrData = 'pending';
            })
            .addCase(fetchRegionsLkData.fulfilled, (state, action) => {
                state.isLoadingRegionsLkrData = 'successful';
                state.regionsLkData = action.payload;
            })
            .addCase(fetchRegionsLkData.rejected, (state, action) => {
                state.isLoadingRegionsLkrData = 'failed';
                state.regionsLkDataError = action.error.message;
            })
    },
});

export const { clearRegionsLkData } = regionsLkData.actions;

export default regionsLkData.reducer;
