import React, { useEffect, useState } from 'react';
import style from '../InputForm/TextAreaCustom/TextAreaCutom.module.css';
import TextAreaField from '../InputForm/TextAreaCustom/TextAreaCustom';
import axios from 'axios';
import { useSelector } from "react-redux";

const FeedBackContainer = ({ onSuccess }) => {
    const meData = useSelector((state) => state.meData.meData);
    const initialFormData = {
        question: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isModalOpen]);

    const [formTouched, setFormTouched] = useState({
        question: false
    });

    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        const isValid = !!(formData.question);
        setFormValid(isValid);
    }, [formData]);

    const handleBlur = (field) => {
        setFormTouched({ ...formTouched, [field]: true });
    };

    const checkError = (field) => formTouched[field] && !formData[field];

    const sendFeedback = async () => {
        try {
            await axios.post('https://feedback.agatha-tender.ru/v1/task', {
                email: meData.email,
                text: formData.question,
                phone: meData.phone,
                fio: meData.label
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setModalOpen(true);
            setFormData(initialFormData);
            setFormTouched({
                question: false
            });
            onSuccess();
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        sendFeedback();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <>
            <div className={style.contactFormContainer}>
                <form onSubmit={handleSubmit}>
                    <TextAreaField
                        placeholder="Напишите нам свой вопрос"
                        name="question"
                        value={formData.question}
                        onChange={handleInputChange}
                        className={style.textAreaField}
                        maxLength={1000}
                        onBlur={() => handleBlur('question')}
                        isError={checkError('question')}
                    />
                    <span className={style.charLimitText}>Количество знаков не более 1000</span>
                    <div className={style.emailContainer}>
                        <button type="submit" className={style.submitButton} disabled={!formValid}>
                            Отправить
                        </button>
                        <div className={style.disclaimerWrapper}>
                            <span className={style.disclaimerText}>
                                Ввод номера телефона подтверждает ваше согласие с{' '}
                            </span>
                            <a
                                href="https://storage.yandexcloud.net/test-avatar/files/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                className={style.termsLink}
                                style={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                                условиями передачи информации
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default FeedBackContainer;
