import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchScatter} from "../../api/api";

const initialState = {
    BubbleData: [],
    loading: 'idle',
    error: null,
    limPerPage: null,
    currentLimit: 300,
};

export const fetchBubbleData = createAsyncThunk(
    'scatter/fetchBubbleData',
    async (options) => {
        try {
            options.newOKPDCode = options.selectedProduct.map(item => item.productId);
            const { regNumArray, ...modifiedOptions } = options;

            const response = await fetchScatter(modifiedOptions);
            if ((response.status === 204 && response.data === "") || response.data.result === null) {
                return [];
            }
            return response.data.result;
        } catch (error) {
            throw error;
        }
    }
);

const scatterChartSlice = createSlice({
    name: 'scatterChart',
    initialState,
    reducers: {
        increaseLimit: (state) => {
            state.currentLimit += 100;
        },
        resetBubbleData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBubbleData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchBubbleData.fulfilled, (state, action) => {
                if (
                    (Array.isArray(action.payload) && action.payload.length === 0) ||
                    (action.payload.total === 0 && action.payload.nodes === null)
                ) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.BubbleData = action.payload;
                    if (action.payload.nodes && action.payload.nodes[0]?.extra) {
                        const { extra } = action.payload.nodes[0];
                        const maxValue = extra.find(item => item.label === 'max_value')?.value;
                        state.limPerPage = maxValue;
                    }
                }
            })
            .addCase(fetchBubbleData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const selectLimPerPage = (state) => state.scatter.limPerPage;
export const selectCurrentLimitBubbles = (state) => state.scatter.currentLimit;

export const { increaseLimit,resetBubbleData } = scatterChartSlice.actions;
export default scatterChartSlice.reducer;
