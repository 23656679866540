
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getUserCompany} from "../../api/api";

const usersDataInitialState = {
    companyUserData: [],
    isLoadingCompanyUserData: false,
    companyUserDataError: null,
};

export const fetchCompanyUserData = createAsyncThunk(
    'companyUserData/fetchCompanyUserData',
    async () => {
        const response = await getUserCompany();
        return Array.isArray(response) ? response : [];    }
);

const companyUserData = createSlice({
    name: 'companyUserData',
    initialState: usersDataInitialState,
    reducers: {
        clearCompanyUserData: (state) => {
            state.companyUserData = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyUserData.pending, (state) => {
                state.isLoadingCompanyUserData = 'pending';
            })
            .addCase(fetchCompanyUserData.fulfilled, (state, action) => {
                state.isLoadingCompanyUserData = 'successful';
                state.companyUserData = action.payload;
            })
            .addCase(fetchCompanyUserData.rejected, (state, action) => {
                state.isLoadingCompanyUserData = 'failed';
                state.companyUserDataError = action.error.message;
            })
    },
});

export const { clearCompanyUserData } = companyUserData.actions;

export default companyUserData.reducer;
