import React from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as YourFilterIcon } from '../../common/icons/FilterIcon.svg';

const FilterIconWithBadge = ({ activeFilterCount }) => {
    return (
        <IconButton aria-label="filter list">
            <Badge
                badgeContent={activeFilterCount}
                color="secondary"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiBadge-badge': {
                        backgroundColor: 'var(--badge-bg-color, #FFF)',
                        color: 'white',
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        border: '1px solid var(--container-bg-color, #FFF)',
                        top: 0,
                        right: 0,
                        boxShadow: '0 0 0 2px var(--container-bg-color, #FFF)',
                        transition: 'background-color 0.5s ease-in-out, border 0.5s ease-in-out, boxShadow 0.5s ease-in-out',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                }}
            >
                <YourFilterIcon />
            </Badge>
        </IconButton>
    );
};

export default FilterIconWithBadge;
