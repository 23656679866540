import {useDispatch} from "react-redux";
import {resetGeoData} from "../service/reducers/GeoChart/GeoChartSlice";
import {resetLegendClicks} from "../service/reducers/legendItemsClick";
import {resetSummaryData} from "../service/reducers/Summary/SummarySlice";
import {resetPieData} from "../service/reducers/PieChart/PieChartSlice";
import {resetActivityData} from "../service/reducers/ActivityChart/ActivityChartSlice";
import {resetRoleDonutData, setRoleMode} from "../service/reducers/DonutChart/DonutChartRole";
import {resetDonutKbrData} from "../service/reducers/KbrChart/DonutChartKbrSlice";
import {resetLineChartData} from "../service/reducers/LineChart/LineChartSlice";
import {resetManyLinesData} from "../service/reducers/ManyLinesChart/ManyLineChartSlice";
import {resetContractBarWithLineData} from "../service/reducers/PublishChart/Contracts/ContractBarWithLineChartSlice";
import {resetBarWithLineData} from "../service/reducers/PublishChart/BarWithLineChartSlice";
import {resetContractOkpd} from "../service/reducers/StackedBarChart/Contracts/ContractOkpdChartSlice";
import {clearSelectedProduct, resetOkpd} from "../service/reducers/StackedBarChart/ProductCodeChartSlice";
import {resetTreeMapData} from "../service/reducers/TreeMapChart/TreeMapChartSlice";
import {resetBubbleData} from "../service/reducers/BubbleChartSlice";
import {resetIndicatorData} from "../service/reducers/IndicatorChartSlice";
import {resetStackedShort} from "../service/reducers/StackedLineShort";
import {resetSunBurstData} from "../service/reducers/SunBurstChartSlice";
import {resetSanKeyData, setSunKeyMode} from "../service/reducers/SunKeyChartSlice";
import {resetStore as resetPieSliceStore} from "../service/reducers/PieChart/PieChartSegmentSlice";
import {resetStore as resetPieOtpSliceStore} from "../service/reducers/PierChartOtp/PieChartOtpSegmentSlice";
import {
    clearSelectedSegments,
    resetTreeMapStore,
    setTreeMapMode
} from "../service/reducers/TreeMapChart/TreeMapChartSegmentSlice";
import {
    clearSelectedMonth,
    clearSelectedPeriod,
    resetMonthStore
} from "../service/reducers/PublishChart/BarWithLineChartMonthSlice";
import {resetMonthContractStore} from "../service/reducers/PublishChart/Contracts/ContractBarWithLineChartMonthSlice";
import {resetRoleDonutChart} from "../service/reducers/DonutChart/DonutRoleSegmentSlice";
import {resetActivityChart} from "../service/reducers/ActivityChart/ActivityChartSegmentSlice";
import {clearActiveRegions, setMode} from "../service/reducers/GeoChart/GeoChartRegionSlice";
import {cancelAllPendingRequests} from "../api/api";
import {resetCurrentSegmentName, resetSegmentNameStore} from "../service/reducers/ZoomableChartSegmentSlice";
import {resetKbrDonutChart} from "../service/reducers/KbrChart/DonutChartKbrSegmentSlice";
import {resetBubbleChart} from "../service/reducers/BubbleSelectedSegmentSlice";
import {resetPieEtpData} from "../service/reducers/PierChartOtp/PieChartOtpSlice";
import {resetStackedWithContentData} from "../service/reducers/StackedWithContent/StackedWithContentSlice";
import {resetStackedWithContentStore} from "../service/reducers/StackedWithContent/StackedWithContentSegmentSlice";

export const useClearAll = () => {
    const dispatch = useDispatch();

    const clearAll = () => {
        //all data reset here
        dispatch(resetGeoData())
        dispatch(resetLegendClicks())
        dispatch(resetSummaryData())
        dispatch(resetPieData())
        dispatch(resetActivityData())
        dispatch(resetRoleDonutData())
        dispatch(resetDonutKbrData())
        dispatch(resetLineChartData())
        dispatch(resetManyLinesData())
        dispatch(resetContractBarWithLineData())
        dispatch(resetBarWithLineData())
        dispatch(resetContractOkpd())
        dispatch(resetOkpd())
        dispatch(resetTreeMapData())
        dispatch(resetBubbleData())
        dispatch(resetIndicatorData())
        dispatch(resetStackedShort())
        dispatch(resetSunBurstData())
        dispatch(resetSanKeyData())
        dispatch(resetPieEtpData())
        dispatch(resetStackedWithContentData())
        /*  dispatch(setToggleVisibility(false));*/

        //selectors reset
        dispatch(resetPieSliceStore());
        dispatch(resetPieOtpSliceStore());
        dispatch(resetCurrentSegmentName())
        dispatch(resetTreeMapStore());
        dispatch(resetMonthStore());
        dispatch(resetMonthContractStore());
        dispatch(resetStackedWithContentStore());
        dispatch(resetRoleDonutChart());
        dispatch(resetActivityChart())
        dispatch(resetKbrDonutChart())
        dispatch(clearActiveRegions());
        dispatch(clearSelectedProduct())
        dispatch(clearSelectedSegments());
        dispatch(clearSelectedMonth());
        dispatch(clearSelectedPeriod());
        dispatch(setRoleMode("cust"));
        dispatch(setSunKeyMode("cust"))
        dispatch(setTreeMapMode("cust"))
        dispatch(setMode("cust"))
        dispatch(resetBubbleChart())
        dispatch(resetSegmentNameStore())
        cancelAllPendingRequests();
    }

    return clearAll;
};
