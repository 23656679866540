import React from 'react';
import styles from './Tooltip.module.css';

const Tooltip = React.forwardRef(({ x, y, text, showColon = true }, ref) => {
    if (!text || text.length === 0) return null;

    return (
        <div ref={ref} className={styles.tooltip} style={{ top: `${y}px`, left: `${x}px` }}>
            {text.map((item, index) => (
                <div key={index}>
                    <strong>{item.label}</strong>{showColon ? ':' : ''} {item.value}
                </div>
            ))}
        </div>
    );
});

export default Tooltip;
