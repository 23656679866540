import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import localStorageService from '../service/localStorage/localStorageService';

const useAxiosInterceptors = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    localStorageService.removeItem('token');
                    navigate('/');
                }

                else if (error.response && error.response.status === 403) {
                    navigate('/personal')
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, [navigate]);
};

export default useAxiosInterceptors;
