import React, {useEffect} from 'react';
import style from './Tariffs.module.css';
import {fetchOrganizationData} from "../../../service/reducers/organizationInfoSlice";
import {useDispatch, useSelector} from "react-redux";

const Tariffs = () => {
    const dispatch = useDispatch();
    const orgData = useSelector((state) => state.organizationInfoData.organizationData);
    const bColoros = ['#A2C4FF','#DBC3FE', '#FFF0F1', '#F4F6F9'];
    const tariffDetails = [
        { name: 'Учетных записей', value: orgData?.limit_base_users + orgData?.limit_improved_users},
        { name: 'ОКПД', value: orgData?.improved_user_okpd_usage + orgData?.base_user_okpd_usage },
        { name: 'Регионов', value: orgData?.base_user_count_region + orgData?.improved_user_count_region },
        { name: 'Групп', value: orgData?.base_user_group_usage + orgData?.improved_user_group_usage },
    ];
    const tariffName = orgData.tariff_name || 'Нет данных';
    const meData = useSelector((state) => state.meData.meData);
    const expirationDate = meData.expiration ? new Date(meData.expiration).toLocaleDateString() : 'Нет данных';
    useEffect(() => {
        dispatch(fetchOrganizationData());
    }, [dispatch]);

    const currentDate = new Date();
    const expiration = meData.expiration ? new Date(meData.expiration) : null;
    const daysLeft = expiration ? Math.ceil((expiration - currentDate) / (1000 * 60 * 60 * 24)) : null;

    const handleExtendTariff = () => {
        window.location.href = `https://ukassa.agatha-tender.ru/ukassa/form?id_item=${meData.tariff_id}&user_id=${meData.id}&email=${meData.email}`;
    };

    return (
        <div className={style.container}>
            <div className={style.tariffHeader}>
                <span>Мой тариф</span>
                <div className={style.changeTariffButton}>Сменить тариф</div>
            </div>
            <div className={style.tariffName}>{tariffName}</div>
            <div className={style.expirationDate}>Срок действия до {expirationDate}</div>
            {daysLeft !== null && daysLeft <= 3 && (
                <div
                    className={`${style.changeTariffButton} ${style.extendTariffButton}`}
                    onClick={handleExtendTariff}
                >
                    Продлить тариф
                </div>
            )}
            <div className={style.tariffDetails}>
                {tariffDetails.map((detail, index) => (
                    <div
                        key={detail.name}
                        className={style.detailItem}
                        style={{ backgroundColor: bColoros[index % bColoros.length] }}
                    >
                        <span className={style.detailName}>{detail.name}</span>
                        <span className={style.detailValue}>{detail.value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tariffs;
