import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchContractBarLine} from "../../../../api/api";

const initialState = {
    BarLineData: [],
    loading: 'idle',
    error: null,
};

export const fetchContractBarLineData = createAsyncThunk(
    'ContractbarLine/fetchContractBarLineData',
    async (options) => {
        const { selectedContractMonth, ...filteredOptions } = options;
        const response = await fetchContractBarLine(filteredOptions);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);

const contractBarWithLineChartSlice = createSlice({
    name: 'ContractbarLineChart',
    initialState,
    reducers: {
        resetContractBarWithLineData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContractBarLineData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchContractBarLineData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.BarLineData = action.payload;
                }
            })
            .addCase(fetchContractBarLineData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { resetContractBarWithLineData } = contractBarWithLineChartSlice.actions;

export default contractBarWithLineChartSlice.reducer;
