import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress } from '@mui/material';
import styles from '../../EditFormGlobal/EditForm.module.css';
import btnStyle from '../../CustomModalChanger/CustomModal.module.css';

const PurchaseSearchWizard = ({ onSubmit, errorMessageRef }) => {
    const [groupName, setGroupName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [localErrorMessage, setLocalErrorMessage] = useState('');

    useEffect(() => {
        setLocalErrorMessage(errorMessageRef.current);
        // eslint-disable-next-line
    }, [errorMessageRef.current]);

    const handleWizardComplete = async () => {
        setIsLoading(true);
        try {
            await onSubmit(groupName);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
            setLocalErrorMessage(errorMessageRef.current);
        }
    };

    return (
        <div>
            <div className={styles.formControl}>
                <span className={styles.spanText}>Введите название поискового набора</span>
                <TextField
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '6px',
                            backgroundColor: '#F7FAFC',
                            '& fieldset': {
                                borderColor: '#8D96B2',
                            },
                            '&:hover fieldset': {
                                borderColor: '#C8A1FF',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#4A3DFD',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                        },
                        '& .MuiInputBase-input': {
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                        },
                        '& .MuiFormHelperText-root': {
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                        },
                    }}
                    className={styles.formControl}
                    label="Название группы"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                />
            </div>
            <button
                className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton}`}
                onClick={handleWizardComplete}
                disabled={isLoading}
                style={{ opacity: isLoading ? 0.5 : 1 }}
            >
                Отправить
            </button>
            {isLoading && (
                <div className={btnStyle.sendingContainer}>
                    <CircularProgress size={20} />
                    <span>Данные отправляются...</span>
                </div>
            )}
            {localErrorMessage && (
                <div className={styles.errorMessage}>
                    {localErrorMessage}
                </div>
            )}
        </div>
    );
};

export default PurchaseSearchWizard;
