import React, {useEffect, useRef, useState} from 'react';
import searchIcon from '../../../common/icons/SearchIcon.svg';
import styled from '@emotion/styled';
import {useDispatch, useSelector} from "react-redux";
import {setSearchData, setSearchError, setSearchLoading} from "../../../service/reducers/testSearchSlice";
import {cancelAllPendingRequests, searchCompanies} from "../../../api/api";
import {
    resetRelatedINNs,
    resetSearchOrgINNINNs,
    resetSearchSuppINNINNINNs, resetSelectedComboItem,
    resetSelectedOrganization,
    resetSelectedValue,
    setSearchOrgINNINNs,
    setSearchSuppINNINNINNs,
    setSelectedSuggestion
} from "../../../service/reducers/organizationSlice";
import {setToggleLock, setTogglePosition, setToggleVisibility} from "../../../service/reducers/searchSwticherSlice";
import {resetOkpdCode} from "../../../service/reducers/selectedComboOkpd";
import {useClearAll} from "../../../hook/useClearAll";
import {resetRegionCode} from "../../../service/reducers/selectedComboRegionSlice";
import {useLocation, useNavigate} from "react-router-dom";

const SearchBox = styled.div`
  width: 302px;
  height: 40px;
  border: 1px solid ${props => (props.focused ? '#4A3DFD' : 'transparent')};
  border-radius: 4px;
  background: var(--headerCmg-bg-color, #FFF);
  transition: background-color 0.5s ease-in-out;
  margin-right: 24px;
  display: flex;
  align-items: center;
`;

const SuggestionsList = styled.ul`
  width: 302px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 60px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 4px;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: translateY(-20px);
  opacity: 0;
  z-index: 9999;
  visibility: hidden;
  max-height: 80vh; 
  overflow-y: auto; 
  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;

export const SuggestionItem = styled.li`
  padding: 12px 16px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Golos Regular';
  border-bottom: 1px solid #f0f0f0; 
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #f8f8f8; 
  }
`;

const NoResults = styled.div`
  padding: 12px 16px;
  text-align: center;
  color: #999;
  font-size: 14px;
`;

export const SearchIconImg = styled.img`
  margin-left: 8px;
  transition: transform 0.3s, opacity 0.5s;
  transform: ${props => (props.focused ? 'scale(0)' : 'scale(1)')};
  width: ${props => (props.focused ? '0px' : 'auto')};
  opacity: ${props => (props.focused ? 0 : 1)};
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  background: var(--headerCmg-bg-color, #FFF);
  flex-grow: 1;
  padding: 8px;
  font-size: 14px;
  font-family: 'Golos Regular';
  transition: background-color 0.5s ease-in-out;
  color: #000;
  &::placeholder {
    color: ${props => (props.hidePlaceholder ? 'transparent' : '#8D96B2')};
    transition: color 0.4s;
  }
`;
const SearchComponent = () => {
    const dispatch = useDispatch();
    const searchData = useSelector(state => state.search.searchData);
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const searchBoxRef = useRef(null);
    const inputRef = useRef(null);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const navigate = useNavigate();
    const location = useLocation();

    const clearAll = useClearAll();
    useEffect(() => {
        const savedSearches = localStorage.getItem('searchHistory');
        if (savedSearches) {
            setSuggestions(JSON.parse(savedSearches));
        }
    }, []);

    useEffect(() => {
        const handleOutsideAction = (event) => {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
                setShowSuggestions(false);
                if (inputRef.current) {
                    inputRef.current.blur();
                }
            }
        };

        document.addEventListener('mousedown', handleOutsideAction);
        document.addEventListener('scroll', handleOutsideAction, true);
        document.addEventListener('touchstart', handleOutsideAction);
        document.addEventListener('touchmove', handleOutsideAction);

        return () => {
            document.removeEventListener('mousedown', handleOutsideAction);
            document.removeEventListener('scroll', handleOutsideAction, true);
            document.removeEventListener('touchstart', handleOutsideAction);
            document.removeEventListener('touchmove', handleOutsideAction);
        };
    }, []);


    useEffect(() => {
        if (searchOrgINNINNs.length === 0 && searchSuppINNINNINNs.length === 0) {
            setInputValue('');
            dispatch(setToggleVisibility(false));
        }
        // eslint-disable-next-line
    }, [searchOrgINNINNs, searchSuppINNINNINNs]);

    const handleBlur = () => {
        setTimeout(() => setShowSuggestions(false), 100);
        setFocused(false);
    };
    const handleFocus = () => {
        if (inputValue) {
            setInputValue('');
            setShowSuggestions(true);
            dispatch(setToggleVisibility(false));
        }
        setShowSuggestions(true);
        setFocused(true);
    };

    const handleSelectSuggestion = (suggestionObject) => {
        setInputValue('');
        cancelAllPendingRequests();
        updateSearchHistory(suggestionObject);
        setShowSuggestions(false);

        let togglePosition = 'customer';
        let toggleLocked = false;

        if (!suggestionObject.customer && !suggestionObject.suppliers) {
            return;
        }
        navigate(location.pathname, { replace: true });
        dispatch(setToggleVisibility(true));
        dispatch(resetSearchOrgINNINNs());
        dispatch(resetSearchSuppINNINNINNs());
        dispatch(resetSelectedOrganization());
        dispatch(resetRelatedINNs());
        dispatch(resetSelectedValue())
        dispatch(resetOkpdCode());
        dispatch(resetRegionCode());
        if (suggestionObject.customer && !suggestionObject.suppliers) {
            dispatch(setSearchOrgINNINNs([suggestionObject.inn]))
            togglePosition = 'supplier';
            toggleLocked = true;
        } else if (!suggestionObject.customer && suggestionObject.suppliers) {
            dispatch(setSearchSuppINNINNINNs([suggestionObject.inn]))
            togglePosition = 'customer';
            toggleLocked = true;
        } else if (suggestionObject.customer && suggestionObject.suppliers) {
            dispatch(setSearchOrgINNINNs([suggestionObject.inn]))
            dispatch(setSearchSuppINNINNINNs([suggestionObject.inn]))
            togglePosition = 'customer';
            toggleLocked = false;
        }
        dispatch(setSelectedSuggestion(suggestionObject))
        dispatch(setTogglePosition(togglePosition));
        dispatch(setToggleLock(toggleLocked));
        dispatch(resetSelectedComboItem());
        clearAll()
    };

    const updateSearchHistory = (suggestionObject) => {
        const newSuggestion = { full_name: suggestionObject.full_name,
            inn: suggestionObject.inn,
            customer: suggestionObject.customer,
            suppliers: suggestionObject.suppliers};
        if (!suggestions.some(s => s.inn === newSuggestion.inn)) {
            const newHistory = [newSuggestion, ...suggestions].slice(0, 10);
            setSuggestions(newHistory);
            localStorage.setItem('searchHistory', JSON.stringify(newHistory));
        }
    };
    const handleSearchChange = async (e) => {
        cancelAllPendingRequests();
        const searchTerm = e.target.value;
        setInputValue(searchTerm);
        if (searchTerm.length > 0) {
            dispatch(setSearchLoading(true));
            try {
                const data = await searchCompanies(searchTerm);
                dispatch(setSearchData(data.map(item => ({
                    inn: item.inn,
                    full_name: item.full_name || 'Название не указано',
                    customer: item.customer,
                    suppliers: item.suppliers
                }))));
            } catch (error) {
                dispatch(setSearchError(error.toString()));
            } finally {
                dispatch(setSearchLoading(false));
            }
        } else {
            dispatch(setSearchData([]));
        }
    };

    const getSuggestions = () => {
        if (inputValue.length > 0) {
            if (!searchData || searchData.length === 0) {
                return [{ full_name: 'Результатов нет', inn: null }];
            }
            return searchData;
        } else {
            return suggestions;
        }
    };

    return (
        <SearchBox ref={searchBoxRef} focused={focused}>
            <SearchIconImg src={searchIcon} alt="searchIcon" focused={focused} />
            <SearchInput
                ref={inputRef}
                placeholder="Поиск учереждений"
                hidePlaceholder={inputValue.length > 0}
                value={inputValue}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleSearchChange}
            />
            <SuggestionsList className={showSuggestions ? 'show' : ''}>
                {getSuggestions().map((suggestionObject, index) => (
                    suggestionObject.full_name === 'Результатов нет' ? (
                        <NoResults key="no-results">Результатов нет</NoResults>
                    ) : (
                        <SuggestionItem key={index} onClick={() => handleSelectSuggestion(suggestionObject)}>
                            {suggestionObject.full_name}
                        </SuggestionItem>
                    )
                ))}
            </SuggestionsList>
        </SearchBox>
    );
};

export default SearchComponent;
