import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormValidity } from "../../../../../service/reducers/ModalCloseStatusSlice";
import CustomModalContent from "../../../../CustomModalChanger/CustomModal";
import { useModal } from "../../../../../hook/useModal";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { fetchRegionsLkData } from "../../../../../service/reducers/regionsLkSlice";
import { deleteUserRegion } from "../../../../../api/api";
import LocationsList from "../../../../LocationsTree/Locations";
import style from "../Accounts/Accounts.module.css";
import plus from "../../../../../common/icons/HiPlus.svg";
import trash from "../../../../../common/icons/trashIcon.svg";
import {formatDate} from "../../../../../utils/convertDates";

const RegionList = () => {
    const dispatch = useDispatch();
    const { setIsModalVisible, setModalContent, setIsCentered } = useModal();
    const regionsLkData = useSelector((state) => state.regionsLkData.regionsLkData) || [];
    const meData = useSelector((state) => state.meData.meData);
    const limitInn = meData.limit_regions || 0;
    const initialSelectedCount = regionsLkData.length || 0;
    const [innWizardKey, setInnWizardKey] = useState(0);
    // eslint-disable-next-line
    const [selectedCount, setSelectedCount] = useState(initialSelectedCount);

    useEffect(() => {
        dispatch(fetchRegionsLkData());
    }, [dispatch]);

    useEffect(() => {
        setSelectedCount(initialSelectedCount);
        // eslint-disable-next-line
    }, [regionsLkData]);

    const confirmDeleteRegion = async (iso) => {
        try {
            await deleteUserRegion(iso);
            dispatch(fetchRegionsLkData());
            setIsModalVisible(false);
        } catch (error) {
            console.error('Ошибка при удалении региона:', error);
        }
    }

    const handleDeleteRegion = (region, event) => {
        event.stopPropagation();
        dispatch(setFormValidity(true));
        const deleteButtonStyle = {
            backgroundColor: 'red',
        };
        const currentTime = new Date();
        const createdAtDate = new Date(region.created_at);
        const canEditTimeDate = new Date(createdAtDate.setMonth(createdAtDate.getMonth() + 1));

        if (currentTime < canEditTimeDate) {
            setModalContent(
                <CustomModalContent
                    title="Предупреждение"
                    content={`Удалить регион ${region.subjects} вы сможете с ${formatDate(canEditTimeDate)} года`}
                    buttons={[
                        {
                            text: "Ок",
                            onClick: () => {
                                setIsCentered(false);
                                setIsModalVisible(false);
                            }
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );
            setIsCentered(true);
            setIsModalVisible(true);
            return;
        }



        const confirmDeleteAndClose = () => {
            confirmDeleteRegion(region.iso);
            setIsCentered(false);
            setIsModalVisible(false);
        };
        const cancelAndClose = () => {
            setIsCentered(false);
            setIsModalVisible(false);
        };

        setModalContent(
            <CustomModalContent
                title="Подтвердите ваши действия!"
                content={`Вы точно хотите удалить регион: ${region.subjects} ?`}
                buttons={[
                    {
                        text: "Да",
                        onClick: confirmDeleteAndClose,
                        style: deleteButtonStyle
                    },
                    {
                        text: "Отмена",
                        onClick: cancelAndClose,
                    }
                ]}
                onClose={() => {
                    dispatch(fetchRegionsLkData());
                    setIsCentered(false);
                    setIsModalVisible(false);
                }}
            />
        );
        setIsCentered(true);
        setIsModalVisible(true);
    }

    const handleAddInnClick = () => {
        setInnWizardKey(prevKey => prevKey + 1);
        dispatch(setFormValidity(true));
        setModalContent(
            <CustomModalContent
                key={innWizardKey}
                title="Добавление нового региона"
                formComponent={
                    <LocationsList
                        currentCount={0}
                        limit={limitInn - initialSelectedCount}
                        onSelectChange={(region, isSelected) => {
                            setSelectedCount(prevCount => isSelected ? prevCount + 1 : prevCount - 1);
                        }}
                        onAddRegionsComplete={() => {
                            setIsCentered(false);
                            setIsModalVisible(false);
                            dispatch(fetchRegionsLkData());
                        }}
                    />
                }
                buttons={[]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                    dispatch(fetchRegionsLkData());
                }}
            />
        );

        setIsCentered(true);
        setIsModalVisible(true);
    }

    return (
        <>
            <div className={style.availableContainer}>
                <div className={style.plusContainer} onClick={handleAddInnClick}>
                    <img className={style.plusIco} src={plus} alt="Добавить" />
                    <span>
                        Добавить
                    </span>
                </div>
            </div>
            {regionsLkData && regionsLkData.length > 0 ? (
                <div className={style.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={style.tableCellText}>Субъект</TableCell>
                                <TableCell className={style.tableCellText}>Округ</TableCell>
                                <TableCell className={style.tableCellText}>Дата добавления</TableCell>
                                <TableCell className={`${style.tableCellText} ${style.textCentered}`}>Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {regionsLkData.map((region, index) => (
                                <TableRow key={index}>
                                    <TableCell className={style.tableCellText}>{region.subjects}</TableCell>
                                    <TableCell className={style.tableCellText}>{region.district}</TableCell>
                                    <TableCell className={style.tableCellText}>{formatDate(region.created_at)} г.</TableCell>
                                    <TableCell>
                                        <div className={`${style.icoContainer} ${style.soloIco}`}>
                                            <div onClick={(e) => handleDeleteRegion(region, e)}>
                                                <img
                                                    className={style.icoTest}
                                                    src={trash}
                                                    alt="Удалить"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <div className={style.centerContent}>
                    У вас еще нет добавленных регионов
                </div>
            )}
        </>
    );
}

export default RegionList;
