import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    'Извещения': {
        diagrams: [{ type: 'Summary', gridArea: 'col1' },
            { type: 'PieChart', gridArea: 'col2' },
            { type: 'TopDiagramm', gridArea: 'col3' },
            { type: 'StackedBarChart', gridArea: 'col4' },
            { type: 'SunBurst', gridArea: 'col5' },
            { type: 'SoloLineD', gridArea: 'col6' },
            { type: 'GeoChart', gridArea: 'col7' },
            { type: 'BarLineChart', gridArea: 'col8' },
            { type: 'ManyLineD', gridArea: 'col9' },
            { type: 'RoleDiagramm', gridArea: 'col10' },
            { type: 'TreeMapDiagramm', gridArea: 'col11' },
            { type: 'PieChartEtp', gridArea: 'col12' },
            { type: 'StackedWithContent', gridArea: 'col13' },],
    },
    'Контракты': {
        diagrams: [{ type: 'Summary', gridArea: 'col1' },
            { type: 'ContractStackedBarChart', gridArea: 'col2' },
            { type: 'GeoChart', gridArea: 'col3' },
            { type: 'SunBurst', gridArea: 'col4' },
            { type: 'TopDiagramm', gridArea: 'col5' },
            { type: 'BarLineChart', gridArea: 'col6' },
            { type: 'PieChart', gridArea: 'col7' },
            { type: 'TreeMapDiagramm', gridArea: 'col8' },
            { type: 'ContractPayMonth', gridArea: 'col9' },
            { type: 'RoleDiagramm', gridArea: 'col10' }]
    },
    'Исполнение': {
        diagrams: [{ type: 'Summary', gridArea: 'col1' },
            { type: 'BarLineChart', gridArea: 'col2' },
            { type: 'PieChart', gridArea: 'col3' },
            { type: 'RoleDiagramm', gridArea: 'col4' },
            { type: 'Indicator', gridArea: 'col5' },
            { type: 'ispOkpd', gridArea: 'col6' },
            { type: 'GeoChart', gridArea: 'col7' },
            { type: 'SunBurst', gridArea: 'col8' },
            { type: 'SunKey', gridArea: 'col9' },
            { type: 'ContractStackedBarChart', gridArea: 'col10' },
            { type: 'Scatter', gridArea: 'col11' },
        ],
    },
    'Пользовательский': {
        diagrams: [
            { type: 'Summary', gridArea: 'col1' },
            { type: 'PieChart', gridArea: 'col2' }
        ],
    }
};

const diagramConfigSlice = createSlice({
    name: 'diagramConfig',
    initialState,
    reducers: {
        setGridTemplateAreas: (state, action) => {
            const { tabName, gridTemplateAreas } = action.payload;
            state[tabName].gridTemplateAreas = gridTemplateAreas;
        },
        swapDiagrams: (state, action) => {
            const { fromType, toType, tabName } = action.payload;
            const fromDiagram = state[tabName].diagrams.find(d => d.type === fromType);
            const toDiagram = state[tabName].diagrams.find(d => d.type === toType);
            if (!fromDiagram || !toDiagram) return;
            [fromDiagram.gridArea, toDiagram.gridArea] = [toDiagram.gridArea, fromDiagram.gridArea];
        }
    }
});


export const {setGridTemplateAreas, swapDiagrams } = diagramConfigSlice.actions;

export default diagramConfigSlice.reducer;
