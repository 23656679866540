import React, { useEffect, useRef } from 'react';
import { scaleBand, scaleLinear, max, axisLeft, select, axisBottom } from 'd3';
import * as d3 from 'd3';
import styles from '../TestStackedD/StackedBarChart.module.css';
import { useDispatch, useSelector } from "react-redux";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import Legend from "../../../components/DiagrammLegend/Legend";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {
    addContractStackedName,
    clearContractTrimCode,
    clearSelectedContractProduct,
    fetchContractOkpdData, setContractTrimCode, setSelectedProduct,
} from "../../../service/reducers/StackedBarChart/Contracts/ContractOkpdChartSlice";
import { formatNumberWithDecimal } from "../../../utils/countFunc";
import { useVirtualTooltipSize } from "../../../hook/useVirtualTooltipSize";
import { formatCurrency } from "../../../utils/rubbleFunc";
import { clearActiveItemsForDiagram, setActiveLegendItem } from "../../../service/reducers/legendItemsClick";
import { cancelAllPendingRequests } from "../../../api/api";
import tooltipsConfig from "../../../utils/tooltipTitles.json";
import { findOkpdNameByCode } from "../../../utils/findOKPDNameGlobal";
import { truncateText } from "../../../utils/trunctateText";
import localStorageService from "../../../service/localStorage/localStorageService";
import useFetchData from "../../../hook/useFetchData";
import useTooltip from '../../../hook/useTooltip';
import Tooltip from '../../../components/Tooltip/Tooltip';

const ContractStackedBarChart = ({ onZoomClick, zoomedDiagram }) => {
    const svgRef = useRef();
    const dispatch = useDispatch();
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const { ref, width, height } = useResizeObserver();
    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const { odkpData, loading } = useSelector((state) => state.contractOkpd);
    const trimCode = useSelector((state) => state.contractOkpd.trimCode);
    const isInsideActive = useSelector((state) => state.productCode.isInsideActive);
    const insideActiveRef = useRef(isInsideActive);
    insideActiveRef.current = isInsideActive;
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const okpdData = localStorageService.getItem('okpdData') || [];

    useFetchData(fetchContractOkpdData, [
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.activitySlice),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position)
    ]);

    useEffect(() => {
        if (loading === 'successful' && ((width && height))) {
            drawChart(odkpData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, odkpData, selectedOkpd, isLoadingMenu]);

    const title = activeTab === 'Исполнение' ? 'Объемы поставленной продукции по ОКПД2' : 'Объемы продукции по коду ОКПД2';

    const headerWithThreeButtons = {
        title: title,
        icons: [
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, activeIcon: null, width: 20, height: 20, onClick: onZoomClick },
            { name: 'inside', icon: icons.inside, activeIcon: icons.insideActive, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, activeIcon: null, width: 20, height: 20 }
        ]
    };

    const handleContractLineClick = (productId) => {
        cancelAllPendingRequests();
        dispatch(setSelectedProduct(productId));
        dispatch(addContractStackedName(title));
        dispatch(setActiveLegendItem({ diagramId: title, activeItem: productId }));
    };

    const onLegendItemClick = (productId) => {
        cancelAllPendingRequests();
        onMouseOut();
        dispatch(setSelectedProduct(productId));
        dispatch(addContractStackedName(title));
    };

    const handleClickIn = (productId) => {
        cancelAllPendingRequests();

        const parts = productId.split('.').filter(p => p !== "");
        if (parts.length >= 4) {
            return;
        }
        productId += '.';

        dispatch(clearSelectedContractProduct());
        dispatch(clearContractTrimCode());
        dispatch(setContractTrimCode([productId]));
        dispatch(addContractStackedName(title));
        dispatch(clearActiveItemsForDiagram({ diagramId: title }));
    };

    const isActiveBar = (label) => {
        if (selectedOkpd.length === 0) return true;
        return selectedOkpd.includes(label);
    };

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, label, value, okpd) => {
        const { OKPDCode, Value, OKPDName } = tooltipsConfig.StackedBarChartContract.Tabs[activeTab];
        const okpdName = findOkpdNameByCode(okpd, okpdData);
        const textObject = [
            { label: OKPDCode, value: okpd },
            { label: OKPDName, value: okpdName },
            { label: Value, value: formatCurrency(value) }
        ];
        const tooltipSize = calculateTooltipSize([okpd]);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        showTooltip(event, textObject);
    };

    const onMouseMoveLables = (event, label) => {
        const okpdName = findOkpdNameByCode(label, okpdData);
        const tooltipData = { value: `${okpdName}(${label})` };

        const tooltipSize = calculateTooltipSize([label]);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        showTooltip(event, [tooltipData], false);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const drawChart = (data) => {
        const barHeight = 20;
        const margin = { top: 10, right: 30, bottom: 0, left: 55 };
        const container = document.getElementById("container");
        if (!container) return;
        const containerWidth = parseFloat(window.getComputedStyle(container).width);
        const width = containerWidth - margin.left - margin.right;
        const height = barHeight * data.length;
        const minWidthPerTick = 50;
        const numberOfTicks = Math.floor(width / minWidthPerTick / 2);
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();
        svg.attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom);
        const g = svg.append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);
        const x = scaleLinear()
            .domain([0, max(data, (d) => d.value)])
            .range([0, width]);
        const y = scaleBand().domain(data.map((d) => d.label))
            .range([0, height])
            .padding(0.1);
        g.append("g")
            .call(axisLeft(y).tickFormat(d => truncateText(d, 10)))
            .select(".domain").attr("stroke", "#EDF1F5");
        g.selectAll(".tick text")
            .attr("fill", "#8D96B2")
            .attr("font-family", "Golos Regular")
            .attr("cursor", "pointer")

        const selectedProductIds = new Set(selectedOkpd);
        g.selectAll(".tick text")
            .attr("fill", (d) => selectedProductIds.has(d) ? "black" : "#8D96B2")
            .attr("font-family", "Golos Regular")
            .attr("cursor", "pointer")
            .on('mouseover', (event, d) => onMouseMoveLables(event, d))
            .on('mouseout', onMouseOut)
            .on('click', function(event, d) {
                event.stopPropagation();
                if (insideActiveRef.current) {
                    handleClickIn(d);
                    onMouseOut();
                } else {
                    onMouseOut();
                    handleContractLineClick(d);
                }
                select(this).attr("fill", selectedProductIds.has(d) ? "#8D96B2" : "black");
            })

        const xAxisSvg = select("#xAxisSvg");
        xAxisSvg.selectAll("*").remove();
        xAxisSvg.attr("width", width + margin.left + margin.right)
            .attr("height", 30);
        xAxisSvg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(axisBottom(x).ticks(numberOfTicks).tickFormat((d) => formatNumberWithDecimal(d)))
            .selectAll("g.tick")
            .attr("font-family", "Golos Regular")
            .each(function() {
                select(this).select("text")
                    .attr("fill", "#8D96B2")
                    .attr("font-family", "Golos Regular");
            });
        xAxisSvg.select(".domain").attr("stroke", "#EDF1F5");
        xAxisSvg.selectAll(".tick line").remove();

        // Remove all ticks from svg
        g.selectAll(".tick line").remove();
        const MIN_WIDTH = 6;
        data.forEach((barData) => {
            const isActive = isActiveBar(barData.label);
            let barWidth = x(barData.value);
            if (barWidth < MIN_WIDTH) {
                barWidth = MIN_WIDTH;
            }
            const barHeight = y.bandwidth();
            const radius = barHeight / 3.5;
            const barX = 0;
            const barY = y(barData.label);
            const initialPath = `M${barX},${barY} 
h0 
a${radius},${radius} 0 0 1 0,${radius} 
v${barHeight - 2 * radius} 
a${radius},${radius} 0 0 1 0,${radius} 
h0Z`;

            const finalPath = `M${barX},${barY} 
h${barWidth - radius} 
a${radius},${radius} 0 0 1 ${radius},${radius} 
v${barHeight - 2 * radius} 
a${radius},${radius} 0 0 1 ${-radius},${radius} 
h${-(barWidth - radius)}Z`;
            const path = g.append("path")
                .attr("d", initialPath)
                .attr("x", 0)
                .attr("y", y(barData.label))
                .attr("width", 0)
                .attr("height", y.bandwidth())
                .attr("fill", "#4B72FB")
                .attr("opacity", isActive ? 1 : 0.3);

            path.transition()
                .duration(800)
                .attr("d", finalPath)
                .ease(d3.easeCubicInOut)
                .on('end', () => {
                    if (selectedOkpd.length > 0 && selectedOkpd.includes(barData.label)) {
                        const textElement = g.append("text")
                            .attr("x", x(barData.value) + 5)
                            .attr("y", y(barData.label) + y.bandwidth() / 2)
                            .attr("dy", ".35em")
                            .attr("font-family", "Golos Regular")
                            .attr("font-size", "12px")
                            .attr("fill", "var(--text-color, #FFF)")
                            .attr("opacity", 0)
                            .text(formatNumberWithDecimal(barData.value));

                        textElement.on('mousemove', (event) => onMouseMove(event, barData.label, barData.value, barData.label))
                            .on('mouseout', onMouseOut);

                        const textWidth = textElement.node().getComputedTextLength();
                        const remainingSpaceRight = width - (x(barData.value) + 5);

                        if (textWidth > remainingSpaceRight) {
                            textElement.attr("x", x(barData.value) - textWidth - 5)
                                .attr("fill", "#EDF1F5");
                        }

                        textElement.transition()
                            .delay(0.4)
                            .attr("opacity", 1);
                    }

                    path.on('mousemove', (event) => onMouseMove(event, barData.label, barData.value, barData.label))
                        .on('mouseout', onMouseOut)
                        .on('click', (event) => {
                            event.stopPropagation();
                            if (insideActiveRef.current) {
                                onMouseOut();
                                handleClickIn(barData.label);
                            } else {
                                onMouseOut();
                                handleContractLineClick(barData.label);
                            }
                        });
                });
        });

    };

    return (
        <div ref={ref} className={`${style.container} ${selectedOkpd.length > 0 || trimCode?.length > 0 ? style.selected : ''}  ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    diagramName={title}
                    onZoomClick={onZoomClick}
                    diagramId={"Stacked"}
                />
            </div>
            {isLoadingMenu || loading === 'pending' || loading === 'failed' ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={title} data={odkpData} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={['#4B72FB']} onLegendItemClick={onLegendItemClick} selectedSlice={selectedOkpd} />
                    </div>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} showColon={tooltip.showColon} ref={tooltipRef} />
                    <div className={`${styles.containerContent} ${zoomedDiagram ? styles.zoomed : ''}`} id="container">
                        <svg ref={svgRef}></svg>
                    </div>
                    <div id="xAxisContainer">
                        <svg id="xAxisSvg"></svg>
                    </div>
                </>
            )}
        </div>
    );
};

export default ContractStackedBarChart;
