export const diagramDescriptions = {
    "Соотношение способов определения поставщиков": {
        default: {
            description: "Круговая диаграмма показывает долю каждого способа определения поставщика в общем количестве закупок и общей сумме НМЦК. Переключатель параметров оценки позволяет выбрать, какие данные будут отображаться на диаграмме — от суммы или от количества. Это помогает понять, какие способы определения поставщиков наиболее популярны и эффективны",
            videoUrl: "https://storage.yandexcloud.net/test-avatar/files/pieDtest.mp4"
        }
    },
    "Структура закупок по кодам видов расходов": {
        default: {
            description: "Диаграмма показывает долю каждого вида расхода относительно общего числа закупок и суммы НМЦК. Переключатель параметров оценки позволяет выбрать, какие данные будут отображаться на диаграмме — от суммы или от количества. График может быть полезен для анализа структуры расходов и определения наиболее важных видов расходов",
            videoUrl: "https://storage.yandexcloud.net/test-avatar/files/donut1.mp4"
        }
    },
    "Объемы продукции по коду ОКПД2": {
        default: {
            description: "Диаграмма отображает распределение суммы НМЦК и количества закупок по классификатору ОКПД 2, с разделением по способам определения поставщика. Вы можете изучить данные, начиная с общего уровня и углубляться до детализации по 4 уровням ОКПД2. Такая визуализация помогает анализировать структуру и распределение закупок по категориям продукции, выявлять наиболее значимые группы товаров/услуг, а также сопоставлять эти данные для разных способов определения поставщика",
            videoUrl: "https://storage.yandexcloud.net/test-avatar/files/okpd.mp4"
        },
        Контракты: {
            description: "Диаграмма отражает распределение количества и суммы контрактов в разрезе классификатора ОКПД2. Данные сгруппированы по уровням ОКПД2, в коде которых разделены точками на 4 уровня. При базовом запросе передаются данные, сгруппированные по первому уровню с последующей возможностью проваливания до 4 уровня. Это позволяет анализировать структуру рынка государственных закупок по видам продукции и услугам, предоставляя информацию о том, какие категории товаров и услуг наиболее востребованы",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Структура требований к участникам": {
        default: {
            description: "Диаграмма отражает распределение суммы НМЦК закупок по участникам, имеющим преимущественное право и ограничения на закупку товаров, работ и услуг иностранного производства. Диаграмма может быть полезна для наглядного представления информации о требованиях, преимуществах и ограничениях для разных категорий участников. Это позволит лучше понять, какие условия необходимо выполнить для участия в закупках и какие льготы и преференции можно получить",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Динамика размещения закупок по способам определения поставщика": {
        default: {
            description: "Диаграмма отражает распределение суммы НМЦК и количества закупок с разделением по способам определения поставщика, сгруппированных по временным интервалам (месяц, квартал, полгода, год), что позволяет увидеть динамику размещения закупок в определенный период времени. Переключатель параметров оценки позволяет выбрать, какие данные будут отображаться на диаграмме — от суммы или от количества",
            videoUrl: "https://storage.yandexcloud.net/test-avatar/files/manyLineChart1.mp4"
        }
    },
    "Анализ географии заказчиков": {
        default: {
            description: "На карте отображено распределение заказчиков по регионам РФ. Интенсивность цвета региона зависит от суммы НМЦК закупок. Если организатор и заказчик - разные юридические лица, указываются оба.  Карта позволяет определить наиболее активные регионы в сфере госзакупок и оценить их вклад в общий объём рынка",
            videoUrl: "https://storage.yandexcloud.net/test-avatar/files/geoChart.mp4"
        },
        Контракты: {
            description: "На карте отображено распределение заказчиков и исполнителей (в зависимости от выбранной организации) по регионам РФ. Интенсивность цвета региона зависит от суммы контрактов, что позволяет визуально оценить географическое распределение активности на рынке государственных закупок",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        },
        Исполнение: {
            description: "На карте отображено распределение заказчиков и исполнителей (в зависимости от выбранной организации) по регионам РФ. Интенсивность цвета региона зависит от суммы исполненных контрактов и этапов",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "График размещения закупок": {
        default: {
            description: "Данный график отображает распределение суммы НМЦК и количества закупок по дате размещения, сгруппированным по временным интервалам (месяц, квартал, полгода, год). График даёт представление о динамике размещения закупок и позволяет выявить периоды наибольшей активности",
            videoUrl: "https://storage.yandexcloud.net/test-avatar/files/barLineChart1.mp4"
        }
    },
    "Финансовое обеспечение закупок (по годам)": {
        default: {
            description: "Диаграмма показывает распределение объема финансового обеспечения закупок, сгруппированных по временным интервалам (месяц, квартал, полгода, год). Этот график позволяет увидеть динамику финансового обеспечения государственных закупок и оценить тенденции в оплате контрактов",
            videoUrl: "https://storage.yandexcloud.net/test-avatar/files/manyLineChart2.mp4"
        }
    },
    "Структура ролей организации в закупках": {
        default: {
            description: "Круговая диаграмма отражает процентное соотношение ролей участников закупочного процесса к общей сумме закупок. Позволяет наглядно представить структуру ролей в закупках и выделить наиболее значимые. Это может быть полезно для анализа закупочной деятельности организации, выявления слабых мест и разработки стратегии оптимизации закупочного процесса",
            videoUrl: "https://storage.yandexcloud.net/test-avatar/files/donut2.mp4"
        }
    },
    "Сравнение объемов заказчиков": {
        default: {
            description: "Заказчики распределены в соответствии с суммой НМЦК и количеством закупок относительно остальных. Диаграмма может быть полезна для наглядного сравнения объёмов разных заказчиков и выявления наиболее крупных из них",
            videoUrl: "https://storage.yandexcloud.net/test-avatar/files/treeMap.mp4"
        }
    },
    "Анализ географии исполнителей": {
        Контракты: {
            description: "На карте отображено распределение заказчиков и исполнителей (в зависимости от выбранной организации) по регионам РФ. Интенсивность цвета региона зависит от суммы контрактов, что позволяет визуально оценить географическое распределение активности на рынке государственных закупок",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        },
        Исполнение: {
            description: "На карте отображено распределение заказчиков и исполнителей (в зависимости от выбранной организации) по регионам РФ. Интенсивность цвета региона зависит от суммы исполненных контрактов и этапов",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Структура по способам определения поставщиков": {
        Контракты: {
            description: "Диаграмма представляет распределение объёма контрактов в зависимости от способов определения поставщика. Это позволяет оценить, какие методы выбора поставщиков наиболее популярны и эффективны",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Структура контрактов по исполнителям": {
        Контракты: {
            description: "Данная диаграмма позволяет детально изучить распределение заказчиков или исполнителей в зависимости от суммы заключенных ими контрактов. Участники рынка ранжируются относительно друг друга, что дает возможность оценить вклад каждого из них в общий объем контрактов и выявить наиболее активных игроков. Предусмотрены режимы просмотра информации в разрезах заказчика и исполнителя с учетом выбранного региона или ОКПД2",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Структура контрактов по заказчикам": {
        Контракты: {
            description: "Данная диаграмма позволяет детально изучить распределение заказчиков или исполнителей в зависимости от суммы заключенных ими контрактов. Участники рынка ранжируются относительно друг друга, что дает возможность оценить вклад каждого из них в общий объем контрактов и выявить наиболее активных игроков. Предусмотрены режимы просмотра информации в разрезах заказчика и исполнителя с учетом выбранного региона или ОКПД2",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Статусы исполнения контрактов": {
        Контракты: {
            description: "Предоставляет обзор статусов исполнения контрактов –«Исполнение», «Исполнение завершено» и «Исполнение прекращено», разбивая их по сумме и количеству, с возможностью переключения между этими параметрами",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        },
        Исполнение: {
            description: "Круговая диаграмма предоставляет обзор статусов исполнения контрактов и этапов –«Исполнение», «Исполнение завершено» и «Исполнение прекращено», разбивая их по сумме и количеству, с возможностью переключения между этими параметрами",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "График заключения контрактов": {
        Контракты: {
            description: "Данный график отображает распределение суммы и количества заключенных контрактов по дате заключения, сгруппированных по временным интервалам (месяц, квартал, полгода, год). Он показывает завершённые и активные контракты за четыре года, что позволяет оценить динамику заключения контрактов",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Источники финансирования контрактов": {
        Контракты: {
            description: "Круговая диаграмма показывает долю каждого источника финансирования в общем объеме контрактов. Переключатель параметров оценки позволяет выбрать, какие данные будут отображаться на диаграмме — от суммы или от количества, что помогает понять, какая часть контрактов финансируется за счет определенных источников, выявляя тем самым зависимость государственного сектора от различных форм финансирования",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "График платежей": {
        Контракты: {
            description: "Отображает распределение суммы и количества заключенных контрактов по датам оплаты, сгруппированных по временным интервалам (месяц, квартал, полгода, год). Это позволяет оценить динамику оплаты контрактов и выявить возможные задержки или проблемы с платёжной дисциплиной",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Структура поставщиков по ОПФ": {
        Контракты: {
            description: "Диаграмма отображает долю каждого вида ОПФ по отношению к общей сумме всех заключенных контрактов. Это позволяет проанализировать структуру рынка поставщиков по организационно-правовым формам и оценить их вклад в общую сумму контрактов",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "График исполнения этапов и контрактов": {
        Исполнение: {
            description: "Данный график отображает распределение суммы и количества исполненных контрактов и этапов, сгруппированных по временным интервалам (месяц, квартал, полгода, год). Он показывает завершённые и активные контракты за четыре года, что позволяет оценить динамику исполнения контрактов",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Индикатор исполнения контрактов": {
        Исполнение: {
            description: "Визуализирует соотношение между суммой заключенных контрактов и суммой исполненных обязательств (этапов контрактов), показывая степень исполнения обязательств",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Страны происхождения продукции": {
        Исполнение: {
            description: "Анализирует распределение объемов(суммы) поставленной продукции по странам происхождения, давая представление о географии поставок",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Структура неустойки по контрактам": {
        Исполнение: {
            description: "Детализирует информацию о неустойках, разделяя данные на штрафы и пени, с возможностью углубленного анализа",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Структура потоков средств через ОКПД2/НДС": {
        Исполнение: {
            description: "Отображает движение финансов между заказчиками и исполнителями с учетом классификатора ОКПД 2 и НДС, позволяя проследить всю цепочку взаимодействий",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Объемы поставленной продукции по ОКПД2": {
        Исполнение: {
            description: "Диаграмма отражает распределение количества и суммы исполненных контрактов и этапов в разрезе классификатора ОКПД2. Данные сгруппированы по уровням ОКПД2, в коде которых разделены точками на 4 уровня. При базовом запросе передаются данные, сгруппированные по первому уровню с последующей возможностью проваливания до 4 уровня. Это позволяет анализировать структуру рынка государственных закупок по видам продукции и услугам, предоставляя информацию о том, какие категории товаров и услуг наиболее востребованы",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
    "Распределение стоимостей единиц продукции": {
        Исполнение: {
            description: "Точечная диаграмма позволяет анализировать стоимость товарных позиций по ОКПД 2 и наименованиям. Она наглядно отображает разброс цен на каждую товарную позицию, помогая выявить завышенные цены и найти наиболее выгодные предложения. Каждая точка на диаграмме представляет отдельную товарную позицию, а ее положение по вертикальной оси соответствует стоимости единицы продукции. Чем выше расположена точка, тем дороже товар. Анализируя распределение точек, можно определить типичный диапазон цен для каждой категории товаров и идентифицировать выбросы в виде чрезмерно дорогих или подозрительно дешевых предложений. Эта информация полезна для принятия обоснованных решений при закупках и поиске оптимальных поставщиков",
            gifUrl: "https://storage.yandexcloud.net/test-avatar/files/ICU.gif"
        }
    },
};
