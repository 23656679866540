import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useRequestData from './useRequestData';
import { fetchData } from '../utils/fetchDataDiagrams';

const useFetchData = (dispatchFunction, dependencies, diagramType) => {
    const dispatch = useDispatch();
    const requestData = useRequestData();

    useEffect(() => {
        let modifiedRequestData = { ...requestData };
        if (diagramType === 'donutRole') {
            if ((modifiedRequestData.activeTab === 'Исполнение' || 'Контракты') && modifiedRequestData.shouldShowChangeButton) {
                modifiedRequestData.isCustomer = modifiedRequestData.roleMode === 'cust';
            }
        }

        if (diagramType === 'geoChart') {
            if ((modifiedRequestData.activeTab === 'Исполнение' || modifiedRequestData.activeTab === 'Контракты') && modifiedRequestData.shouldShowChangeButton) {
                modifiedRequestData.isCustomer = modifiedRequestData.mode === 'cust';
            }
        }

        if (diagramType === 'treeMapDiagramm') {
            if ((modifiedRequestData.activeTab === 'Исполнение' || modifiedRequestData.activeTab === 'Контракты') && modifiedRequestData.shouldShowChangeButton) {
                modifiedRequestData.isCustomer = modifiedRequestData.treeMapMode === 'cust';
            }
        }

        if (diagramType === 'sunKeyChart') {
            if ((modifiedRequestData.activeTab === 'Исполнение') && modifiedRequestData.shouldShowChangeButton) {
                modifiedRequestData.isCustomer = modifiedRequestData.sunKeyMode === 'cust';
            }
        }
        fetchData(dispatch, modifiedRequestData, dispatchFunction);
        // eslint-disable-next-line
    }, [dispatch, ...dependencies]);
};

export default useFetchData;
