import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedSegments: [],
    roleChartName: [],
};

const donutSegmentSliceV1 = createSlice({
    name: 'donutSegmentSliceV1',
    initialState,
    reducers: {
        addRoleChartName: (state, action) => {
            state.roleChartName = [action.payload];
        },
        toggleSegment: (state, action) => {
            const index = state.selectedSegments.indexOf(action.payload);
            if (index === -1) {
                state.selectedSegments.push(action.payload);
            } else {
                state.selectedSegments.splice(index, 1);
            }
        },
        removeSegment: (state, action) => {
            const index = state.selectedSegments.indexOf(action.payload);
            if (index !== -1) {
                state.selectedSegments.splice(index, 1);
            }
        },
        clearRoleDonutSegments: (state) => {
            state.selectedSegments = [];
        },
        resetRoleDonutChart: (state) => {
            return initialState;
        },
    },
});

export const {clearRoleDonutSegments,addRoleChartName, toggleSegment, removeSegment,resetRoleDonutChart } = donutSegmentSliceV1.actions;
export default donutSegmentSliceV1.reducer;
