import React from 'react';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useSelector} from "react-redux";
import Spinner from "../../TestPages/Spinner";
import {fetchSummaryData} from "../../../service/reducers/Summary/SummarySlice";
import styles from "./Summary.module.css"
import {formatShortCurrency, getCurrencyUnit} from "../../../utils/rubbleFunc";
import useFetchData from "../../../hook/useFetchData";
const Summary = ({ zoomedDiagram }) => {
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const {SummaryData , loading } = useSelector((state) => state.summaryData);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);

    useFetchData(fetchSummaryData, [
        useSelector(state => state.stackedWithContentSegmentSlice.selectedMonth),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.pieChartOtpSegment.selectedSlice),
        useSelector(state => state.activitySlice),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position)
    ]);

    if (loading === 'pending' || loading === 'failed' || !SummaryData || !SummaryData.nodes) {
        return <Spinner />;
    }

    const getActiveTabLabel = (activeTab) => {
        switch (activeTab) {
            case 'Извещения':
                return 'Сумма НМЦК закупок';
            case 'Контракты':
                return 'Сумма контрактов';
            case 'Исполнение':
                return 'Исполнено';
            default:
                return 'Тариф';
        }
    };

    const getExcludedLabels = () => {
        if (activeTab === 'Контракты') {
            if (!selectedOrganization.type && !slidePoz) {
                return ['Кол-во заказчиков'];
            }
            if (((selectedOrganization.type === 'company_customer' || selectedOrganization.type === 'region') && activeTab === 'Контракты') || slidePoz === 'supplier') {
                return ['Кол-во заказчиков'];
            }
            else if ((selectedOrganization.type === 'company_suppliers' && activeTab === 'Контракты') || slidePoz === 'customer') {
                return ['Кол-во исполнителей'];
            }
        }
        if (activeTab === 'Исполнение') {
            if (!selectedOrganization.type && !slidePoz) {
                return ['Штрафов заказчиков', 'Пени заказчиков'];
            }
            if (((selectedOrganization.type === 'company_customer' || selectedOrganization.type === 'region') && activeTab === 'Исполнение') || slidePoz === 'supplier') {
                return ['Штрафов заказчиков', 'Пени заказчиков'];
            }
            else if ((selectedOrganization.type === 'company_suppliers' && activeTab === 'Исполнение') || slidePoz === 'customer') {
                return ['Штрафов исполнителя', 'Пени исполнителя'];
            }
        }

        return [];
    };

    return (
        <div className={`${style.container} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram small-chart`} style={zoomedDiagram ? { height: "600px" } : {}}>
            {(loading === 'pending' || isLoadingMenu) ? (
                <Spinner />
            ) : loading === 'successful' ? (
                <div className={styles.content}>
                    <div className={styles.total}>
                        <div>
                            {getActiveTabLabel(activeTab)}, {getCurrencyUnit(SummaryData.total)}
                        </div>
                        <div className={styles.totalPrice}>
                            {formatShortCurrency(SummaryData.total )}
                        </div>
                    </div>
                    <div className={styles.items}>
                        {SummaryData.nodes
                            .filter(node => !getExcludedLabels().includes(node.label))
                            .map((node, index) => {
                                const isPayment = node.extra && node.extra.some(item => item.label === 'payment' && item.value);
                                const displayValue = isPayment
                                    ? `${formatShortCurrency(node.value)} ${getCurrencyUnit(node.value)}`
                                    : node.value.toLocaleString();
                                //const displayLabel = isPayment ? `${node.label}, ${getCurrencyUnit(SummaryData.total)}` : node.label;
                                return (
                                    <div key={index} className={styles.item}>
                                        <span className={styles.label}>{node.label}</span>
                                        <span className={styles.value}>{displayValue}</span>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Summary;
