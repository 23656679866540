import React, { useState } from 'react';
import style from "../ModalContent/ModalContent.module.css";
import xButtonIco from '../../../common/icons/xButtonBlack.svg';
import xButtonIcoDark from '../../../common/icons/xButtonGrey.svg';
import { useSelector } from "react-redux";

const ModalContent = ({ onClose, companyData }) => {
    const [activeTab, setActiveTab] = useState("Общее");
    const switcher = useSelector(state => state.switcher.activeSwitch);

    const renderGeneralInfo = () => (
        <>
            <div className={style.generalInfoContainer}>
                <div className={style.infoColumn}>
                    <div className={style.infoItem}><strong>ОГРН</strong> <span>{companyData.result.ogrn}</span></div>
                    <div className={style.infoItem}><strong>ОГРН присвоен</strong> <span>{companyData.result.ogrn_date}</span></div>
                    <div className={style.infoItem}><strong>Вид капитала</strong> <span>{companyData.result.capital_type_name}</span></div>
                </div>
                <div className={style.infoColumn}>
                    <div className={style.infoItem}><strong>ИНН</strong> <span>{companyData.result.inn}</span></div>
                    <div className={style.infoItem}><strong>КПП</strong> <span>{companyData.result.kpp}</span></div>
                    <div className={style.infoItem}><strong>Сумма капитала</strong> <span>{companyData.result.capital_amount}</span></div>
                </div>
                <div className={style.infoColumn}>
                    <div className={style.infoItem}><strong>ОКОПФ/ОКПО</strong> <span>{companyData.result.code_opf}</span></div>
                    <div className={style.infoItem}><strong>Дата открытия</strong> <span>{companyData.result.registration_date}</span></div>
                </div>
            </div>
            <div className={style.subInfoContainer}>
                <div className={style.infoRow}><strong>Налоговый орган</strong> <span>{companyData.result.registering_authority_name}</span></div>
                <div className={style.infoRow}><strong>Юридический адрес</strong> <span>{companyData.result.full_address}</span></div>
            </div>
            <strong>Руководитель</strong>
            <div className={style.subInfoContainer}>
                <div className={style.infoRow}><strong>ФИО</strong> <span>{companyData.result.supervisor_fio}</span></div>
                <div className={style.infoRow}><strong>Должность</strong> <span>{companyData.result.supervisor_post}</span></div>
                <div className={style.infoRow}><strong>ИНН</strong> <span>{companyData.result.supervisor_inn}</span></div>
            </div>
        </>
    );

    const renderActivities = () => (
        <div>
            <div className={style.activityItem} key="main">
                <span className={style.activityNumber}>1.</span>
                <div className={style.activityDescription}>
                    <span>
                        <span>{companyData.result.okved}</span> {companyData.result.okved.name}
                    </span>
                    <span className={style.primaryLabel}>Основной</span>
                </div>
            </div>
            {companyData.result.additional_okveds.map((okved, index) => (
                <div className={style.activityItem} key={index + 1}>
                    <span className={style.activityNumber}>{index + 2}.</span>
                    <div className={style.activityDescription}>
                       <span>
                            {okved.name} ({okved.code})
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );

    const renderFounders = () => (
        <div>
            <div><strong>ФИО:</strong> {companyData.result.supervisor_fio}</div>
            <div><strong>Должность:</strong> {companyData.result.supervisor_post}</div>
            <div><strong>ИНН:</strong> {companyData.result.supervisor_inn}</div>
        </div>
    );

    return (
        <div className={style.contentContainer}>
            <div className={style.contentHeader}>
                <div>Информация о компании</div>
                <div className={style.closeXIcon} onClick={onClose}>
                    {switcher === 'sun' ?
                        <img src={xButtonIco} alt="sunIcon" /> :
                        <img src={xButtonIcoDark} alt="moonIcon" />
                    }
                </div>
            </div>
            <div className={style.companyFullName}>{companyData.result.full_name}</div>
            <div className={style.companyShortName}>{companyData.result.short_name}</div>
            <div className={style.tabContainer}>
                <div className={`${style.tab} ${activeTab === "Общее" ? style.activeTab : ''}`} onClick={() => setActiveTab("Общее")}>Общее</div>
                <div className={`${style.tab} ${activeTab === "Виды деятельности" ? style.activeTab : ''}`} onClick={() => setActiveTab("Виды деятельности")}>Виды деятельности</div>
                <div className={`${style.tab} ${activeTab === "Учредители" ? style.activeTab : ''}`} onClick={() => setActiveTab("Учредители")}>Учредители</div>
            </div>
            <div className={style.tabContent}>
                {activeTab === "Общее" && renderGeneralInfo()}
                {activeTab === "Виды деятельности" && renderActivities()}
                {activeTab === "Учредители" && renderFounders()}
            </div>
        </div>
    );
};

export default ModalContent;
