import React from 'react';
import styles from './LikeResultCard/LikeResultCard.module.css';
import LikeResultCard from './LikeResultCard/LikeResultCard';
import searchIcon from '../../../common/icons/SearchIcon.svg';

const LikeResultsComponent = ({ likeResults, onReturnToSearch, searchText, onOpenSearchResults }) => {
    const handleReturnToSearch = () => {
        onReturnToSearch(false);
    };

    const highlightText = (text, highlight) => {
        if (!highlight.trim()) {
            return text;
        }
        const regex = new RegExp(`(${highlight})`, 'gi');
        return text.split(regex).map((part, i) => (
            regex.test(part) ? <span key={i} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
        ));
    };

    const filteredResults = likeResults.filter(result =>
        result.title.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className={styles.likeResultsContainer}>
            <div className={styles.headerContainer}>
                <h2 className={styles.headerTitle}>Сохраненные поисковые наборы</h2>
                <div className={styles.returnSearchContainer} onClick={handleReturnToSearch}>
                    <span className={styles.headerReturnText}>Назад к поиску</span>
                    <img src={searchIcon} alt="searchIcon" />
                </div>
            </div>
            <div className={styles.resultsList}>
                {filteredResults.map(result => (
                    <LikeResultCard
                        key={result.id}
                        data={result}
                        searchText={searchText}
                        highlightText={highlightText}
                        onOpenSearchResults={onOpenSearchResults}
                    />
                ))}
            </div>
        </div>
    );
};

export default LikeResultsComponent;
