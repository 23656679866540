import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { store } from './service/redux/store';
import Layout from './components/Layout/Layout';
import './vendor/fonts/global.css';
import { ModalProvider } from './hook/useModal';
import DContainer from './pages/DashPage/DiagramContainer/DContainer';
import PersonalSettings from './pages/PersonalPage/Administrator/MasterUserPage';
import LoginForm from './components/Auth/AuthComponent';
import AuthCheck from './utils/authCheck';
import { fetchColors, getOkpd2 } from './api/api';
import LoadingScreen from "./pages/TestPages/LoadingPage/LoadingScreen";
import { TestSearch } from "./pages/SearchPage/TestSearch";
import { TestRatings } from "./pages/RatingPage/RatingPage";
import localStorageService from "./service/localStorage/localStorageService";
import SuccessPage from "./pages/TestPages/TestPagePayment/Page200/200";
import WithAxiosInterceptors from './hoc/WithAxiosInterceptors';
import theme from "../src/vendor/theme"
import Payment from "./pages/TestPages/TestPagePayment/TestPage";
import ChangePassword from "./components/ChangePassword/ChangePassword";
function App() {
    const [isAuthChecked, setIsAuthChecked] = useState(false);

    function handleLoginSuccess() {
        console.log('Успешный логин!');
    }

    useEffect(() => {
        const loadColors = async () => {
            try {
                await fetchColors();
            } catch (error) {
                console.error("Не удалось загрузить цвета: ", error);
            }
        };
        loadColors();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiData = await getOkpd2();
                const localStorageData = localStorageService.getItem('okpdData');
                if (!localStorageData || JSON.stringify(apiData) !== JSON.stringify(localStorageData)) {
                    localStorageService.setItem('okpdData', apiData.result);
                }
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Router>
                    <ModalProvider>
                        <WithAxiosInterceptors>
                            <AuthCheck setIsAuthChecked={setIsAuthChecked} />
                            {isAuthChecked && (
                                <Routes>
                                    <Route path="/" element={<LoginForm onLoginSuccess={handleLoginSuccess} />} />
                                    <Route path="/restore-password" element={<ChangePassword/>} />
                                    <Route path="/content" element={<Layout/>}>
                                        <Route index element={<DContainer />} />
                                        <Route path="dashboard" element={<DContainer />} />
                                        <Route path="search" element={<TestSearch />} />
                                        <Route path="ratings" element={<TestRatings />} />
                                    </Route>
                                    <Route path="/personal" element={<Layout/>}>
                                        <Route index element={<PersonalSettings />} />
                                    </Route>
                                    <Route path="/payment" element={<Payment/>}>
                                    </Route>
                                    <Route path="/payment-success" element={<SuccessPage />} />
                                    <Route path="*" element={<div>Not Found</div>} />
                                </Routes>
                            )}
                            {!isAuthChecked && <LoadingScreen/>}
                        </WithAxiosInterceptors>
                    </ModalProvider>
                </Router>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
