import React, { useState } from 'react';
import { Container, Box, Typography, Button, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import style from '../Auth/AuthComponent.module.css';
import inputStyle from "../ImputCustom/CustomInpurField.module.css";
import InputField from "../ImputCustom/CustomInpurField";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import {AUTH} from "../../api/config";

const ChangePassword = () => {
    const [formValues, setFormValues] = useState({ password: '', repeatPassword: '' });
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("key");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formValues.password) {
            formErrors.password = "Пароль не заполнен";
            formErrors.repeatPassword = "Пароль не заполнен";
        }
        if (formValues.password !== formValues.repeatPassword) {
            formErrors.repeatPassword = "Пароли должны совпадать";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true);
            try {
                const response = await axios.post(`${AUTH}/reset_password_email`, {
                    password: formValues.password,
                    invite: paramValue
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.data.success && response.data.result === 'success') {
                    setIsSuccess(true);
                } else {
                    setErrors({ submit: "Что-то пошло не так" });
                }
            } catch (error) {
                setErrors({ submit: "Что-то пошло не так" });
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleNavigate = () => {
        navigate('/');
    };

    return (
        <div>
            <div className={style.background} />
            <Container maxWidth="xs" sx={{
                zIndex: 9999,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        backgroundColor: 'white',
                        padding: '40px',
                        borderRadius: '16px',
                        boxShadow: 10,
                        width: '100%',
                        maxWidth: '400px'
                    }}
                >
                    {isSuccess ? (
                        <>
                            <Typography variant="h5" sx={{ mb: 3 }}>Поздравляем</Typography>
                            <Typography variant="body1" sx={{ mb: 3 }}>Вы успешно обновили свой пароль</Typography>
                            <Button onClick={handleNavigate} fullWidth variant="contained" className={style.authButton} sx={{ mt: 3 }}>
                                Перейти к использованию
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography variant="h5" sx={{ mb: 3 }}>Сброс пароля</Typography>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <InputField
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Введите новый пароль"
                                    name="password"
                                    value={formValues.password}
                                    onChange={handleInputChange}
                                    className={inputStyle.inputField}
                                    isError={errors.password}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                                tabIndex="-1"
                                                style={{ backgroundColor: 'transparent', padding: 0 }}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputProps={{ tabIndex: 1 }}
                                />
                                {errors.password && <Typography color="error" variant="body2">{errors.password}</Typography>}
                                <InputField
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Введите пароль повторно"
                                    name="repeatPassword"
                                    value={formValues.repeatPassword}
                                    onChange={handleInputChange}
                                    className={inputStyle.inputField}
                                    isError={errors.repeatPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                                tabIndex="-1"
                                                style={{ backgroundColor: 'transparent', padding: 0 }}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputProps={{ tabIndex: 2 }}
                                />
                                {errors.repeatPassword && <Typography color="error" variant="body2">{errors.repeatPassword}</Typography>}
                                {errors.submit && <Typography color="error" variant="body2">{errors.submit}</Typography>}
                                <Button type="submit" fullWidth variant="contained" className={style.authButton} sx={{ mt: 3 }}>
                                    Изменить пароль
                                </Button>
                                {isLoading && (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                                        <CircularProgress size={24} />
                                        <Box sx={{ mt: 2 }}>Загрузка...</Box>
                                    </Box>
                                )}
                            </form>
                        </>
                    )}
                </Box>
            </Container>
        </div>
    );
};

export default ChangePassword;
