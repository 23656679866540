import React, { useEffect, useRef, useState } from 'react';
import searchIcon from '../../../common/icons/SearchIcon.svg';
import styled from '@emotion/styled';
import useLocalStorage from "../../../hook/useLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { setParam } from "../../../service/reducers/FilterSearchSlice";
import { fetchSearchZData } from "../../../service/reducers/SearchZSlice";
import xClose from '../../../common/icons/xButtonGrey.svg';
import { SearchIconImg, SearchInput, SuggestionItem } from "../SearchDashBoard/Search";

const SearchBox = styled.div`
  width: 90%;
  height: 40px;
  border: 1px solid ${props => (props.focused ? '#4A3DFD' : 'transparent')};
  border-radius: 4px;
  background: var(--headerCmg-bg-color, #FFF);
  transition: background-color 0.5s ease-in-out;
  margin-left: 2em;
  display: flex;
  align-items: center;
  position: relative;
`;

const SearchButton = styled.button`
  padding: 8px 12px;
  margin-right: 0.2em;
  background-color: #4A3DFD;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #3730A3;
  }
`;

const ClearIcon = styled.img`
  position: absolute;
  right: ${props => (props.iconClicked ? '0.5em' : '4.4em')};
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: right 0.5s ease-in-out;
`;

const SuggestionsList = styled.ul`
  width: 302px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 4px;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: translateY(-20px);
  opacity: 0;
  z-index: 9999;
  visibility: hidden;
  max-height: 80vh;
  overflow-y: auto;
  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;

const SearchZakComponent = ({ isSavedSearches, onInputChange, inputValue, showLikedPurchases, iconClicked, onSearchClick }) => {
    const [focused, setFocused] = useState(false);
    const [localInputValue, setLocalInputValue] = useState(inputValue);
    const [suggestions, setSuggestions] = useLocalStorage('searchSuggestions', []);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const searchBoxRef = useRef(null);
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const queryParams = useSelector(state => state.searchFiltersSlice.searchParams);

    const handleSearch = () => {
        if (onSearchClick) {
            onSearchClick();
        }
        dispatch(setParam({ key: 'searchText', value: localInputValue.trim() }));
        dispatch(fetchSearchZData({ ...queryParams, searchText: localInputValue.trim() }));
        setSuggestions(localInputValue.trim());
        setShowSuggestions(false);
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter' && localInputValue.trim()) {
            if (onSearchClick) {
                onSearchClick();
            }
            handleSearch();
        }
    };

    const handleClearSearch = () => {
        setLocalInputValue('');
        onInputChange('');
        const updatedParams = { ...queryParams, searchText: '' };
        dispatch(setParam({ key: 'searchText', value: '' }));
        dispatch(fetchSearchZData(updatedParams));
    };

    useEffect(() => {
        const handleOutsideAction = (event) => {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
                setShowSuggestions(false);
                if (inputRef.current) {
                    inputRef.current.blur();
                }
            }
        };

        document.addEventListener('mousedown', handleOutsideAction);
        document.addEventListener('scroll', handleOutsideAction, true);
        document.addEventListener('touchstart', handleOutsideAction);
        document.addEventListener('touchmove', handleOutsideAction);

        return () => {
            document.removeEventListener('mousedown', handleOutsideAction);
            document.removeEventListener('scroll', handleOutsideAction, true);
            document.removeEventListener('touchstart', handleOutsideAction);
            document.removeEventListener('touchmove', handleOutsideAction);
        };
    }, []);

    const handleBlur = () => {
        setTimeout(() => setShowSuggestions(false), 100);
        setFocused(false);
    };
    const handleFocus = () => {
        setShowSuggestions(true);
        setFocused(true);
    };

    const handleSelectSuggestion = (suggestion) => {
        setLocalInputValue(suggestion);
        setShowSuggestions(false);
        onInputChange(suggestion);
        dispatch(setParam({ key: 'searchText', value: suggestion }));
        dispatch(fetchSearchZData({ ...queryParams, searchText: suggestion }));
    };

    const handleChange = event => {
        const { value } = event.target;
        setLocalInputValue(value);
        onInputChange(value);
        if (!value.trim()) {
            setShowSuggestions(false);
            dispatch(setParam({ key: 'searchText', value: '' }));
        } else {
            setShowSuggestions(true);
        }
    };

    let placeholderText;
    if (isSavedSearches) {
        placeholderText = "Поиск по сохраненным поисковым наборам";
    } else if (showLikedPurchases) {
        placeholderText = "Поиск по сохраненным закупкам";
    } else {
        placeholderText = "Поиск по предмету закупки, заказчику, товарным позициям, ключевым словам и кодам ОКПД";
    }

    return (
        <SearchBox ref={searchBoxRef} focused={focused}>
            <SearchIconImg src={searchIcon} alt="searchIcon" focused={focused} />
            <SearchInput
                ref={inputRef}
                placeholder={placeholderText}
                hidePlaceholder={localInputValue.length > 0}
                value={localInputValue}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            {localInputValue && (
                <ClearIcon
                    src={xClose}
                    alt="Clear search"
                    onClick={handleClearSearch}
                    iconClicked={iconClicked}
                />
            )}
            {!(isSavedSearches || showLikedPurchases) && <SearchButton onClick={handleSearch}>Найти</SearchButton>}
            <SuggestionsList className={showSuggestions ? 'show' : ''}>
                {suggestions.map((suggestion, index) => (
                    <SuggestionItem key={index} onClick={() => handleSelectSuggestion(suggestion)}>
                        {suggestion}
                    </SuggestionItem>
                ))}
            </SuggestionsList>
        </SearchBox>
    );
};

export default SearchZakComponent;
