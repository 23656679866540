import React, { useState, useEffect, useRef } from 'react';
import AvatarEditor from 'react-avatar-edit';
import styles from './imageCrop.module.css';
import style from "../CustomModalChanger/CustomModal.module.css";
import axios from "axios";
import CustomModalContent from "../CustomModalChanger/CustomModal";
import {useModal} from "../../hook/useModal";
import {setFormValidity} from "../../service/reducers/ModalCloseStatusSlice";
import {useDispatch} from "react-redux";
import {fetchMeData} from "../../service/reducers/MeSlice";
import {PESONALAREA} from "../../api/config";

const ImageCropComponent = () => {
    const [preview, setPreview] = useState(null);
    const [editorSize, setEditorSize] = useState({ width: 200, height: 200 });
    const containerRef = useRef(null);
    const { setIsModalVisible, setModalContent, setIsCentered} = useModal();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const { offsetWidth, offsetHeight } = containerRef.current;
                const size = Math.min(offsetWidth, offsetHeight);
                setEditorSize({ width: size + 50, height: size + 50 });
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
            onClose()
        }
    }, []);

    const onCrop = (previewImage) => {
        setPreview(previewImage);
    };

    const onClose = () => {
        setPreview(null);
    };

    const handleSaveClick = async () => {
        if (!preview) return;

        try {
            const response = await fetch(preview);
            const blob = await response.blob();
            const formData = new FormData();
            formData.append('file', blob, 'file.png');

            const url = `${PESONALAREA}/personal-area/upload_avatar`;
            const config = {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            const result = await axios.post(url, formData, config);
            fetchAndStoreImage(result.data.result.avatar_url);
            console.log('Изображение успешно отправлено', result.data.result.avatar_url);
            openSuccessModal()
        } catch (error) {
            console.error('Ошибка при отправке изображения:', error);
        }
    };

    const fetchAndStoreImage = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`Ошибка загрузки: ${response.statusText}`);
            }
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                localStorage.setItem('avatar', base64data);
                localStorage.setItem('avatarUrl', imageUrl);
            };
            reader.onerror = (error) => {
                console.error('Ошибка при чтении Blob:', error);
            };
        } catch (error) {
            console.error('Ошибка при получении или сохранении изображения:', error);
        }
    };


    const openSuccessModal = () => {
        dispatch(setFormValidity(true));
        setModalContent(
            <CustomModalContent
                title="Внимание!"
                content={`Ваша аватарка успешно загружена`}
                buttons={[
                    {
                        text: "Ок",
                        onClick: () => {
                            dispatch(fetchMeData())
                            setIsModalVisible(false);
                        },
                    }
                ]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                }}
            />
        );
        setIsCentered(true);
        setIsModalVisible(true);

    };


    return (
        <div ref={containerRef} className={styles.cropContainer}>
            <div className={styles.labelContainer}>
                <AvatarEditor
                    width={editorSize.width}
                    height={editorSize.height}
                    imageWidth={editorSize.width}
                    onCrop={onCrop}
                    onClose={onClose}
                    label="Выберите ваше изображение"
                    labelStyle={{
                        paddingLeft: "1.5em",
                        flexWrap: "nowrap",
                        color: 'black',
                        fontSize: '18px',
                        fontFamily: 'Golos Regular',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                    borderStyle={{ border: 'none' }}
                />
            </div>
            <button
                className={`${style.customModalButton} ${style.singleButton}`}
                onClick={handleSaveClick}
            >
                Сохранить
            </button>
        </div>
    );
};

export default ImageCropComponent;
