import React from 'react';
import styles from  './200Page.module.css';
import style from "../../../../components/Auth/AuthComponent.module.css";

const SuccessPage = () => {
    return (
        <div className={styles.successPage}>
            <div className={style.background}/>
            <div className={styles.container}>
                <h1>Поздравляем!</h1>
                <p>Вы успешно оплатили наш сервис!</p>
                <p>На вашу почту была отправлена инструкция для получения доступа к сервису</p>
                <div className={styles.details}>
                    <button className={styles.goHome} onClick={() => window.location.href = '/'}>Начать пользоваться сервисом</button>
                </div>
            </div>
        </div>
    );
};

export default SuccessPage;
