import React from 'react';
import { useSelector } from "react-redux";
import style from '../Tarrifs/Tariffs.module.css';
import PersonalSettingsTab from "./SettingsTab/PersonalSettingsTab";
import Account from "./SettingsTabData/Accounts/Accounts";
import Groups from "./SettingsTabData/Groups/Groups";
import OkpdList from "./SettingsTabData/OkpdList/OkpdList";
import InnList from "./SettingsTabData/InnList/InnList";
import styles from './PersonalSettings.module.css'
import {selectPrivileges} from "../../../service/reducers/PrivelegiesSlice";
import RegionList from "./SettingsTabData/RegionList/RegionList";

const components = {
    'Учетные Записи': Account,
    'Группы': Groups,
    'ОКПД': OkpdList,
    'Компании': InnList,
    'Регионы': RegionList,
    /* 'Уведомления': Notifications*/
};

const tabPrivileges = {
    'Учетные Записи': 'users.view',
    'Группы': 'groups.view',
    'Компании': 'companies.view',
    'ОКПД': 'okpd.view',
    'Регионы': 'regions.view',
    /* 'Уведомления': 'notifications.view'*/
};

const PersonalSettingsContainer = () => {
    const privileges = useSelector(selectPrivileges);
    const activeSettingsTab = useSelector((state) => state.settingsTabs.activeSettingsTab);
    const availableComponents = Object.keys(components).reduce((acc, key) => {
        if (!tabPrivileges[key] || privileges.includes(tabPrivileges[key])) {
            acc[key] = components[key];
        }
        return acc;
    }, {});

    const ActiveComponent = availableComponents[activeSettingsTab] || null;
    const availableTabs = Object.keys(availableComponents);

    return (
        <div className={style.containerList}>
            {availableTabs.length > 0 ? (
                <>
                    <PersonalSettingsTab availableTabs={availableTabs} />
                    <div className={styles.tabDataContainer}>
                        {ActiveComponent && <ActiveComponent />}
                    </div>
                </>
            ) : (
                <div className={styles.subscriptionMessage}>
                    Ваша подписка истекла. Продлите её для возобновления работы.
                </div>
            )}
        </div>
    );
}

export default PersonalSettingsContainer;
