import styles from "../SegmentPart/Circle.module.css";
import React from "react";

export const CircleWithLabel = ({ label, filled, onClick, borderColor = '#000', activeColor = '#4A3DFD', inactiveColor = 'transparent' }) => {
    return (
        <div className={styles.circleLabelContainer} onClick={onClick}>
            <div className={`${styles.circle} ${filled ? styles.filled : ''}`}
                 style={{
                     borderColor: borderColor,
                     backgroundColor: filled ? activeColor : inactiveColor
                 }}></div>
            <span className={styles.label}>{label}</span>
        </div>
    );
};
