import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {PESONALAREA} from "../../api/config";

const initialState = {
    privileges: [],
    loadingPrivileges: 'idle',
    error: null,
};

export const fetchUserPrivileges = createAsyncThunk(
    'privileges/fetchUserPrivileges',
    async (tokenString, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${PESONALAREA}/personal-area/privileges`, {}, {
                headers: { Authorization: `${tokenString}` },
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const privilegesSlice = createSlice({
    name: 'privileges',
    initialState,
    reducers: {
        clearPrivileges: (state) => {
            state.privileges = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserPrivileges.pending, (state) => {
                state.loadingPrivileges = 'pending';
            })
            .addCase(fetchUserPrivileges.fulfilled, (state, action) => {
                state.loadingPrivileges = 'successful';
                state.privileges = action.payload;
            })
            .addCase(fetchUserPrivileges.rejected, (state, action) => {
                state.loadingPrivileges = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { clearPrivileges } = privilegesSlice.actions;
export const selectPrivileges = (state) => state.privileges.privileges.result || [];
export default privilegesSlice.reducer;
