import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedSlice: [],
    selectedSliceSize: {},
    pieName: [],
};

const pieChartOtpSegmentSlice = createSlice({
    name: 'pieOtpSlice',
    initialState,
    reducers: {
        toggleSelectedSlice: (state, action) => {
            const index = state.selectedSlice.indexOf(action.payload.label);
            if (index > -1) {
                state.selectedSlice.splice(index, 1);
                delete state.selectedSliceSize[action.payload.label];
            } else {
                state.selectedSlice.push(action.payload.label);
                state.selectedSliceSize[action.payload.label] = action.payload.size;
            }
        },
        removeSpecificSlice: (state, action) => {
            const index = state.selectedSlice.indexOf(action.payload);
            if (index > -1) {
                state.selectedSlice.splice(index, 1);
                delete state.selectedSliceSize[action.payload];
            }
        },
        updateSelectedSliceSize: (state, action) => {
            const { label, size } = action.payload;
            state.selectedSliceSize[label] = size;
        },
        addPieName: (state, action) => {
            state.pieName = [action.payload];
        },
        resetStore: () => initialState
    },
});

export const {addPieName,removeSpecificSlice, toggleSelectedSlice, updateSelectedSliceSize, resetStore } = pieChartOtpSegmentSlice.actions;
export default pieChartOtpSegmentSlice.reducer;
