import React, { useEffect, useState } from 'react';
import styles from './MainInputsContainer.module.css';
import DynamicForm from "../InputForm/InputForm";
import axios from "axios";
import TarrifContainer from "../Tarrifs/TarrifContainer";
import Circle from "../Circle/Circle";
import f2 from "../../../../common/HeaderPeach.png";
import stylesC from '../SegmentPart/Circle.module.css';
import f1 from "../../../../common/HeaderBlue.webp";

const MainInputsContainer = ({ selectedType }) => {
    const formType = (selectedType === 'phiz') ? 'phiz' : (selectedType === 'individual') ? 'individual' : 'legal';
    const [tariffs, setTariffs] = useState([]);
    const [selectedTariff, setSelectedTariff] = useState({ price: 0, id: null, label: '' });
    useEffect(() => {
        const fetchTariffs = async () => {
            try {
                const response = await axios.get('https://tariff.agatha-tender.ru');
                setTariffs(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchTariffs();
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tariffId = urlParams.get('tariff_id');
        if (tariffId && tariffs.length > 0) {
            const selectedTariff = tariffs.find(tariff => tariff.id === tariffId);
            if (selectedTariff) {
                setSelectedTariff({
                    price: selectedTariff.price_month,
                    id: selectedTariff.id,
                    label: selectedTariff.label
                });
            }
        }
    }, [tariffs]);

    const handleTariffChange = (price, id, label) => {
        setSelectedTariff({ price, id, label });
    };

    return (
        <div className={styles.mainIputsContainer}>
            <div className={styles.dynamicForm}>
                <DynamicForm type={formType} tariffPrice={selectedTariff.price} tariffId={selectedTariff.id} tariffName={selectedTariff.label} />
                <Circle className={`${stylesC.gradientPeach} ${stylesC.rightCircle}`} src={f2} alt='f2' />
            </div>
            <div className={styles.tarrifContainer}>
                <TarrifContainer data={tariffs.result} onTariffChange={handleTariffChange} />
                <Circle className={`${stylesC.gradientCircleBlue} ${stylesC.headerCircle}`} src={f1} alt='f1' />
            </div>
        </div>
    );
};

export default MainInputsContainer;
