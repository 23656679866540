import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedSegments: [],
    treeMapMode: "cust",
};

const treeMapSegmentSlice = createSlice({
    name: 'treeMapSegment',
    initialState,
    reducers: {
        toggleSelectedSegment: (state, action) => {
            const { label, tooltype } = action.payload;
            const index = state.selectedSegments.findIndex(segment => segment.label === label);
            if (index > -1) {
                state.selectedSegments.splice(index, 1);
            } else {
                state.selectedSegments.push({ label, tooltype });
            }
        },
        removeSpecificSegment: (state, action) => {
            const index = state.selectedSegments.findIndex(segment => segment.label === action.payload);
            if (index > -1) {
                state.selectedSegments.splice(index, 1);
            }
        },
        clearSelectedSegments: (state) => {
            state.selectedSegments = [];
            state.selectedCustSegments = [];
        },
        setTreeMapMode: (state, action) => {
            state.treeMapMode = action.payload;
        },
        resetTreeMapStore: () => initialState
    },
});

export const {
    setTreeMapMode,
    removeSpecificSegment,
    clearSelectedSegments,
    toggleSelectedSegment,
    resetTreeMapStore
} = treeMapSegmentSlice.actions;

export default treeMapSegmentSlice.reducer;
