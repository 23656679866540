import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import styles from './VerificationModal.module.css';
import {AUTH} from "../../../../api/config";

const VerificationModal = ({ email, onClose, onSubmit, tariffName, formData }) => {
    const [code, setCode] = useState(Array(6).fill(''));
    const [isSending, setIsSending] = useState(false);
    const [invite, setInvite] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const inputRefs = useRef([]);
    const modalRef = useRef(null);

    const handleCodeChange = (index) => (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const newCode = [...code];

        if (value.length > 1) {
            const values = value.slice(0, 6).split('');
            values.forEach((val, idx) => {
                newCode[idx] = val;
                if (inputRefs.current[idx]) {
                    inputRefs.current[idx].value = val;
                }
            });
            setCode(newCode);
            if (inputRefs.current[5]) {
                inputRefs.current[5].focus();
            }
        } else {
            newCode[index] = value;
            setCode(newCode);
            if (value !== '' && index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 6).split('');
        setCode(paste);
        paste.forEach((val, index) => {
            if (inputRefs.current[index]) {
                inputRefs.current[index].value = val;
            }
        });
        if (inputRefs.current[5]) {
            inputRefs.current[5].focus();
        }
        e.preventDefault();
    };

    const handleKeyDown = (index) => (e) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.body.style.overflow = 'hidden';
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'auto';
        };
        // eslint-disable-next-line
    }, []);

    const handleSendEmail = async () => {
        setIsSending(true);
        try {
            const response = await axios.post(`${AUTH}/registration/request`, {
                label: formData?.fullName,
                inn: formData?.inn,
                phone: formData?.phone,
                email: formData?.email,
                role: tariffName
            });
            setInvite(response.data.result.invite);
            setIsSending(false);
        } catch (error) {
            console.error('Error sending email:', error);
            setIsSending(false);
        }
    };

    const handleSubmit = async () => {
        const codeStr = code.join('');
        try {
            const response = await axios.post(`${AUTH}/registration/verification`, {
                invite: invite,
                key: codeStr,
            });
            if (response.data.success) {
                onSubmit(response.data.result.user_id);
            } else {
                handleError(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                handleError(error.response.data.message);
            } else {
                console.error('Error verifying code:', error);
            }
        }
    };

    const handleError = (message) => {
        if (message.includes('error creating user')) {
            setErrorMessage('У вас уже есть активная подписка');
        } else if (message.includes('invalid key')) {
            setErrorMessage('Вы ввели не верный код подтверждения');
        }
    };

    useEffect(() => {
        handleSendEmail();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent} ref={modalRef}>
                <div className={styles.modalHeading}>Подтверждение адреса</div>
                <div className={styles.modalDesc}>
                    Для получения счета и завершения регистрации необходимо ввести код отправленный на адрес {email}
                </div>
                <div className={styles.codeInputContainer}>
                    {code.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={handleCodeChange(index)}
                            onKeyDown={handleKeyDown(index)}
                            onPaste={index === 0 ? handlePaste : null}
                            className={styles.codeInput}
                            ref={(el) => (inputRefs.current[index] = el)}
                        />
                    ))}
                </div>
                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
                <button className={styles.submitButton} onClick={handleSubmit} disabled={isSending}>
                    Подтвердить
                </button>
            </div>
        </div>
    );
};

export default VerificationModal;
