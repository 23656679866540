import React from 'react'
import style from './TarrifCard.module.css'

const TarrifCard= ({ items, subTitle, price, savings, isSelectedYear }) => {
    const formatPrice = (price) => {
        const numberPrice = Number(price)
        return new Intl.NumberFormat('ru-RU').format(numberPrice)
    }
    return (
        <div>
            <div className={style.cardSubTitle}>{subTitle}</div>
            <div className={`${style.itemPriceContainer} ${!isSelectedYear ? style.itemPriceWithExtraMargin : ''}`}>
                <div className={style.itemPrice}>
                    {formatPrice(price)} ₽
                </div>
                {isSelectedYear && (
                    <div className={style.discountTag}>-20%</div>
                )}
            </div>
            {isSelectedYear && (
                <div className={style.itemPriceLow}>в год (экономия {formatPrice(savings)} ₽)</div>
            )}
            <ul className={style.ulList}>
                {items.map((item, index) => (
                    <div key={index} className={style.cardList}>
                        <li className={style.listItem}>
                            <div>
                                <span className={style.labelText}>{item.label}</span>
                            </div>
                            <span className={style.labelCount}>{item.count}</span>
                        </li>
                    </div>
                ))
                }
            </ul>
        </div>
    )
}

export default TarrifCard
