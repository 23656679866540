import dashIcon from "../../common/icons/DashGray.svg";
import dashIconDark from "../../common/icons/DashDark.svg";
import dashIconActive from "../../common/icons/DashActive.svg";
import searchIcon from "../../common/icons/SearchGray.svg";
import searchIconDark from "../../common/icons/SearchBlue.svg";
import searchIconActive from "../../common/icons/SearchActive.svg";
import messageIcon from "../../common/icons/messages.svg";
import messageIconDark from "../../common/icons/messageIconDark.svg";

export const menuItemsTop = [
    {
        icon: dashIcon,
        darkIcon: dashIconDark,
        activeIcon: dashIconActive,
        darkActiveIcon: dashIconActive,
        text: 'Дашборд',
        path: "/content/dashboard"
    },
    {
        icon: searchIcon,
        darkIcon: searchIconDark,
        activeIcon: searchIconActive,
        darkActiveIcon: searchIconActive,
        text: 'Поиск Закупок',
        path: "/content/search"
    },
    {
        icon: messageIcon,
        darkIcon: messageIconDark,
        activeIcon: messageIconDark,
        darkActiveIcon: messageIconDark,
        text: 'Написать нам'
    },
];
