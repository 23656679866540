import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {getPurchaseLikes} from "../../api/api";

const initialState = {
    likesData: [],
    likesDataloading: 'idle',
    error: null,
};

export const fetchPurchaseLikesData = createAsyncThunk(
    'purchaseLikes/fetchPurchaseLikesData',
    async (queryParams) => {
        const response = await getPurchaseLikes();
        return response;
    }
);

const purchaseLikesSlice = createSlice({
    name: 'purchaseLikes',
    initialState,
    reducers: {
        resetPurchaseLikesData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPurchaseLikesData.pending, (state) => {
                state.likesDataloading = 'pending';
            })
            .addCase(fetchPurchaseLikesData.fulfilled, (state, action) => {
                state.likesDataloading = 'successful';
                state.likesData = action.payload;
            })
            .addCase(fetchPurchaseLikesData.rejected, (state, action) => {
                state.likesDataloading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { resetPurchaseLikesData } = purchaseLikesSlice.actions;

export default purchaseLikesSlice.reducer;
