import React, { useEffect, useRef } from 'react';
import { diagramDescriptions } from "./DiagramDescription";
import styles from './DiagramInfo.module.css';
import { useSelector } from "react-redux";

const DiagramInfo = ({ diagramName }) => {
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const regionComboSelect = useSelector((state) => state.regionComboSelect.regionComboData);

    let diagramInfo = diagramDescriptions[diagramName]?.[activeTab] || diagramDescriptions[diagramName]?.default || { description: "Описание не найдено", videoUrl: null };

    if (diagramName === "Анализ географии исполнителей" && (selectedOrganization.type === 'region' || regionComboSelect.length > 0)) {
        diagramInfo = {
            description: "На карте показано распределение заказчиков и исполнителей по регионам РФ. При создании профиля региона необходимо выбрать соответствующий регион. Дашборд отобразит информацию о заказчиках и исполнителях из выбранного региона, а также других регионах, где находятся заказчики и исполнители компании из выбранного региона. Например, если выбран регион \"Омская область\", на карте будут показаны исполнители, находящиеся в \"Омской области\". Остальные выделенные регионы укажут местонахождение исполнителей для заказчиков из \"Омской области\". Интенсивность цвета региона зависит от суммы контрактов, что позволяет визуально оценить географическое распределение активности на рынке государственных закупок. Предусмотрены режимы просмотра информации в разрезах заказчика и исполнителя с учетом выбранного региона или ОКПД2.",
            videoUrl: null
        };
    }

    else if (diagramName === "Анализ географии заказчиков" && (selectedOrganization.type === 'region' || regionComboSelect.length > 0)) {
        diagramInfo = {
            description: "На карте показано распределение заказчиков и исполнителей по регионам РФ. При создании профиля региона необходимо выбрать соответствующий регион. Дашборд отобразит информацию о заказчиках и исполнителях из выбранного региона, а также других регионах, где находятся заказчики и исполнители компании из выбранного региона. Например, если выбран регион \"Саратовская область\", на карте будут отмечены заказчики, находящиеся в \"Саратовской области\". Остальные выделенные регионы покажут местонахождение заказчиков для исполнителей из \"Саратовской области\". Интенсивность цвета региона зависит от суммы контрактов, что позволяет визуально оценить географическое распределение активности на рынке государственных закупок. Предусмотрены режимы просмотра информации в разрезах заказчика и исполнителя с учетом выбранного региона или ОКПД2.",
            videoUrl: null
        };
    }

    else if ((diagramName === "Анализ географии заказчиков" || diagramName === "Анализ географии исполнителей") && (selectedOrganization.type === 'okpd' || filterOkpd.length > 0)) {
        diagramInfo = {
            description: "На карте отображено распределение заказчиков и исполнителей по регионам РФ. В режиме «по Заказчикам» отображаются регионы заказчиков, осуществляющие закупки по выбранному ОКПД, и наоборот, при переключении на режим по Исполнителям. \n" +
                "\n" +
                "Интенсивность цвета региона зависит от суммы контрактов, что позволяет визуально оценить географическое распределение активности на рынке государственных закупок. Предусмотрены режимы просмотра информации в разрезах заказчика и исполнителя с учетом выбранного региона или ОКПД2",
            videoUrl: 'https://storage.yandexcloud.net/test-avatar/files/geoChart.mp4'
        };
    }

    else if ((diagramName === "Структура контрактов по исполнителям" || diagramName === "Структура контрактов по заказчикам") && (selectedOrganization.type === 'okpd' || filterOkpd.length > 0 || selectedOrganization.type === 'region' || regionComboSelect.length > 0)) {
        diagramInfo = {
            description: "Данная диаграмма позволяет детально изучить распределение заказчиков и исполнителей в зависимости от суммы заключенных ими контрактов.\n" +
                "В режиме «по Заказчикам» отображаются заказчики, осуществляющие закупки по выбранному ОКПД, и наоборот, при переключении на режим по Исполнителям. \n" +
                "\n" +
                " Участники рынка ранжируются относительно друг друга, что дает возможность оценить вклад каждого из них в общий объем контрактов и выявить наиболее активных игроков. Предусмотрены режимы просмотра информации в разрезах заказчика и исполнителя с учетом выбранного региона или ОКПД2.",
            videoUrl: null
        };
    }

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
            videoRef.current.play();
        }
    }, [diagramName, activeTab]);

    const handleVideoEnd = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Название диаграммы: {diagramName}</h2>
            <p className={styles.description}>{diagramInfo.description}</p>
            {diagramInfo.videoUrl && (
                <video
                    className={styles.video}
                    ref={videoRef}
                    src={diagramInfo.videoUrl}
                    poster={`${diagramInfo.videoUrl}#t=0.1`}
                    controls
                    autoPlay
                    muted
                    preload="auto"
                    onEnded={handleVideoEnd}
                >
                    Your browser does not support the video tag.
                </video>
            )}
        </div>
    );
};

export default DiagramInfo;
