import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../../DashPage/HeadContent/HeaderContent.module.css';
import SearchZakComponent from "../../../components/SearchComponent/SearchZak/SearchZak";
import SearchFilterIco from '../../../common/icons/SearchIcons/SearchFilterIco.svg';
import SearchHeartIco from '../../../common/icons/SearchIcons/SearchHeartIco.svg';
import SearchLikeIco from '../../../common/icons/SearchIcons/SearchLikeIco.svg';
import { SettingsContainer } from "../SearchSettingsMain/SettingsContainer";
import { useSearchModal } from "../../../hook/useSearchModal";
import Modal from "../../../components/Modal/ModalComponent/Modal";
import Badge from '@mui/material/Badge';

const HeadSearchContent = ({ onShowLikeResults, onShowLikedPurchases, isSavedSearches, onInputChange, inputValue, showLikedPurchases, onSearchClick, iconClickedProp }) => {
    const likeResults = useSelector(state => state.likes.likeResults);
    const likeStatus = useSelector(state => state.likes.status);
    const { isModalVisible, modalContent, setIsModalVisible, setModalContent } = useSearchModal();
    const likedPurchase = useSelector(state => state.purchaseLikes.likesData);
    const likesDataLoading = useSelector(state => state.purchaseLikes.likesDataloading);
    const handleFilterClick = () => {
        setModalContent(
            <SettingsContainer
                onClose={() => {
                    setIsModalVisible(false);
                }}
                onSearchClick={onSearchClick}
            />
        );
        setIsModalVisible(true);
    };

    const handleLikeIconClick = () => {
        onShowLikeResults();
    };

    const handleHeartIconClick = () => {
        onShowLikedPurchases();
    };

    return (
        <>
            <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
                {modalContent}
            </Modal>
            <div className={styles.content}>
                <SearchZakComponent
                    isSavedSearches={isSavedSearches}
                    onInputChange={onInputChange}
                    inputValue={inputValue}
                    showLikedPurchases={showLikedPurchases}
                    iconClicked={iconClickedProp}
                    onSearchClick={onSearchClick}
                />
                <div className={styles.searchIconsContainer}>
                    <img src={SearchFilterIco} alt="Filter" className={styles.searchIcon} onClick={handleFilterClick} />
                    <Badge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'var(--badge-bg-color, #FFF)',
                                color: 'white',
                                width: 20,
                                height: 20,
                                borderRadius: '50%',
                                border: likeStatus === 'loading' ? '1px solid transparent' : '1px solid var(--container-bg-color, #FFF)',
                                top: 3,
                                right: 3,
                                boxShadow: '0 0 0 2px var(--container-bg-color, #FFF)',
                                transition: 'background-color 0.5s ease-in-out, border 0.5s ease-in-out, boxShadow 0.5s ease-in-out',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                animation: likeStatus === 'loading' ? 'rotate-border 1s linear infinite' : 'none',
                            },
                        }}
                        badgeContent={likeStatus === 'loading' ? '' : likeResults.length}
                        color="secondary">
                        <img
                            src={SearchLikeIco}
                            alt="Likes"
                            className={styles.searchIcon}
                            onClick={handleLikeIconClick}
                        />
                    </Badge>
                    <Badge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'var(--badge-bg-color, #FFF)',
                                color: 'white',
                                width: 20,
                                height: 20,
                                borderRadius: '50%',
                                border: likesDataLoading === 'pending' ? '1px solid transparent' : '1px solid var(--container-bg-color, #FFF)',
                                top: 3,
                                right: 3,
                                boxShadow: '0 0 0 2px var(--container-bg-color, #FFF)',
                                transition: 'background-color 0.5s ease-in-out, border 0.5s ease-in-out, boxShadow 0.5s ease-in-out',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                animation: likesDataLoading === 'pending' ? 'rotate-border 1s linear infinite' : 'none',
                            },
                        }}
                        badgeContent={likesDataLoading === 'pending' ? '' : likedPurchase.length}
                        color="secondary">
                        <img src={SearchHeartIco} alt="Favorites" className={styles.searchIcon} onClick={handleHeartIconClick} />
                    </Badge>
                </div>
            </div>
        </>
    );
};

export default HeadSearchContent;
