export const activeColors = ['#4B72FB', '#AB70FF', '#FEB9BA', '#CAE5CF', '#77A9FF', '#A2C4FF','#C8A1FF','#E94F74','#0F2C93','#1B0ECB','#4437F4','#717FFD','#A39CFF', '#C8A1FF','#AB70FF','#9245FF',
'#6F25D9','#4A3DFD','#4B72FB','#77A9FF','#A2C4FF','#FEB9BA','#EA9FA0','#D18384','#B46869','#7AA181','#91BE99','#B0DBB8',
    '#CAE5CF','#BBC0D2','#8D96B2','#62687F','#494D58'];

export const threeColors = ['#4B72FB', '#AB70FF','#E94F74']
export const treeMapColors = ['#0F2C93','#1B0ECB','#4437F4','#717FFD','#A39CFF', '#C8A1FF','#AB70FF','#9245FF',
'#6F25D9','#4A3DFD','#4B72FB','#77A9FF','#A2C4FF','#FEB9BA','#EA9FA0','#D18384','#B46869','#7AA181','#91BE99','#B0DBB8',
'#CAE5CF','#BBC0D2','#8D96B2','#62687F','#494D58']

export const bubbleColors = ['#4B72FB','#A39CFF', '#C8A1FF','#AB70FF','#9245FF',
    '#6F25D9','#4A3DFD','#4B72FB','#77A9FF','#A2C4FF','#FEB9BA','#EA9FA0','#D18384','#B46869','#7AA181','#91BE99','#B0DBB8',
    '#CAE5CF','#BBC0D2','#8D96B2','#62687F','#494D58']
