import React, { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import style from './Legend.module.css';
import useResizeObserver from 'use-resize-observer';
import { useDispatch, useSelector } from "react-redux";
import { addClickedDiagram, setActiveLegendItem } from "../../service/reducers/legendItemsClick";
import localStorageService from "../../service/localStorage/localStorageService";
import { findOkpdNameByCode } from "../../utils/findOKPDNameGlobal";
import useTooltip from "../../hook/useTooltip";
import Tooltip from "../Tooltip/Tooltip";
import { truncateText } from "../../utils/trunctateText";

const Legend = ({ diagramId, data, dynamicRadius, activeColors, onLegendItemClick, selectedSlice = [] }) => {
    const dispatch = useDispatch();
    const activeItemsFromState = useSelector(state => state.legendItems.activeLegends[diagramId]);
    const activeItems = useMemo(() => activeItemsFromState || [], [activeItemsFromState]);
    const legendRef = useRef();
    const legendItemSize = dynamicRadius * 0.13;
    const fontSize = legendItemSize;
    const colors = localStorageService.getItem('colors');
    const okpdData = localStorageService.getItem('okpdData') || [];

    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();

    const onMouseMove = (event, d) => {
        let tooltipText = { label: '' };

        if (diagramId === 'Объемы поставленной продукции по ОКПД2' || diagramId === 'Объемы продукции по коду ОКПД2') {
            const okpdName = findOkpdNameByCode(d.label, okpdData);
            tooltipText.value = okpdName;
        } else {
            tooltipText.value = d.fullLabel ? d.fullLabel : d.label;
        }

        showTooltip(event, [tooltipText], false);
    };

    const renderLegend = () => {
        if (!Array.isArray(data)) {
            return;
        }
        const legendContainer = d3.select(legendRef.current);
        legendContainer.selectAll('*').remove();
        const sortedData = [...data].sort((a, b) => {
            if (diagramId === 'Распределение стоимостей единиц продукции') {
                return b.count - a.count;
            } else {
                return b.value - a.value;
            }
        });

        const findLabelForShortName = (shortName) => {
            const colorEntry = colors.find(color => color.short_name === shortName);
            return colorEntry ? colorEntry.name : shortName;
        };

        const legendItems = legendContainer
            .selectAll('.legend-item')
            .data(sortedData)
            .enter()
            .append('div')
            .attr('class', style.legendItem)
            .style('display', 'flex')
            .style('align-items', 'center')
            .style('gap', '3px')
            .style('border', d => {
                const fullLabel = diagramId === 'Соотношение способов определения поставщиков (от кол-ва)' || diagramId === 'Соотношение способов определения поставщиков (от суммы)' ? findLabelForShortName(d.label) : d.label;
                return selectedSlice.includes(fullLabel) ? `1px solid var(--tabText-color)` : 'none';
            })
            .style('border-radius', '8px')
            .style('padding-left', '3px')
            .style('padding-right', '3px')
            .style('padding-bottom', '1px')
            .style('padding-top', '1px')
            .style('opacity', d => {
                const fullLabel = diagramId === 'Соотношение способов определения поставщиков (от кол-ва)' || diagramId === 'Соотношение способов определения поставщиков (от суммы)' ? findLabelForShortName(d.label) : d.label;
                return selectedSlice.length === 0 ? '1' : (selectedSlice.includes(fullLabel) ? '1' : '0.5');
            });

        legendItems
            .on('click', function (_, d) {
                let labelToSend = d.label;
                if (diagramId === 'Соотношение способов определения поставщиков (от суммы)' || diagramId === 'Соотношение способов определения поставщиков (от кол-ва)') {
                    labelToSend = findLabelForShortName(d.label);
                }
                dispatch(setActiveLegendItem({ diagramId, activeItem: labelToSend }));

                if (onLegendItemClick) {
                    onLegendItemClick(labelToSend);
                }

                dispatch(addClickedDiagram(diagramId));
                hideTooltip();
            })
            .on('mousemove', (event, d) => onMouseMove(event, d))
            .on('mouseout', hideTooltip);

        legendItems
            .append('svg')
            .attr('width', legendItemSize)
            .attr('height', legendItemSize)
            .append('circle')
            .attr('cx', legendItemSize / 2)
            .attr('cy', legendItemSize / 2)
            .attr('r', legendItemSize / 2)
            .style('fill', (d, i) => {
                if (activeColors.length === 1) {
                    return activeColors[0];
                }
                return activeColors[i % activeColors.length];
            });

        legendItems
            .append('span')
            .text((d) => {
                let displayText = d.label;
                if (diagramId === 'Структура закупок по кодам видов расходов (от суммы)' || diagramId === 'Структура закупок по кодам видов расходов (от кол-ва)') {
                    displayText = d.extraLabel ? d.extraLabel : d.label;
                }
                return truncateText(displayText, 25);
            })
            .style('font-size', `${fontSize}px`)
            .style('font-family', 'Golos regular');
    };

    useResizeObserver({
        ref: legendRef,
        onResize: () => {
            renderLegend();
        },
    });

    useEffect(() => {
        renderLegend();
        // eslint-disable-next-line
    }, [data, dynamicRadius, activeColors, activeItems, selectedSlice]);

    return (
        <div>
            <div ref={legendRef} className={style.legendContainer}></div>
            <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} showColon={tooltip.showColon} ref={tooltipRef} />
        </div>
    );
};

export default Legend;
