import React, { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import { useDispatch, useSelector } from "react-redux";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {
    fetchBubbleData,
    increaseLimit,
    selectCurrentLimitBubbles,
    selectLimPerPage
} from "../../../service/reducers/BubbleChartSlice";
import Legend from "../../../components/DiagrammLegend/Legend";
import { bubbleColors } from "../../../utils/colors";
import { useVirtualTooltipSize } from "../../../hook/useVirtualTooltipSize";
import styles from "../TestMapD/GeoChart.module.css";
import tooltipNames from "../../../utils/tooltipTitles.json";
import { addBubbleChartName, toggleSegment } from "../../../service/reducers/BubbleSelectedSegmentSlice";
import { formatCurrency } from "../../../utils/rubbleFunc";
import useFetchData from "../../../hook/useFetchData";
import useTooltip from '../../../hook/useTooltip';
import Tooltip from '../../../components/Tooltip/Tooltip';

const BubbleDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const { width, height } = useResizeObserver({ ref });
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const { BubbleData, loading } = useSelector((state) => state.scatter);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const clickedNodes = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);

    const headerWithTwoButtons = {
        title: 'Распределение стоимостей единиц продукции',
        icons: [
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);
    const currentLimitBubble = useSelector(selectCurrentLimitBubbles);
    const limPerPage = useSelector(selectLimPerPage);
    const shouldDisplayBubble = currentLimitBubble < limPerPage;

    const handleLoadMoreBubble = () => {
        dispatch(increaseLimit());
    };

    useFetchData(fetchBubbleData, [
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.scatter.currentLimit),
        useSelector(state => state.searchSwitcher.position),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.activitySlice),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.organization.selectedOrganization),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
    ]);

    useEffect(() => {
        if (loading === 'successful' && width && height && BubbleData && BubbleData.nodes) {
            createBubbles(BubbleData);
        }
        // eslint-disable-next-line
    }, [width, height, BubbleData, clickedNodes]);

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const productQuantity = d.extra.find(e => e.label === "ProductQuantity");
        const productName = d.extra.find(e => e.label === "ProductName");
        const tooltipConfig = tooltipNames.Bubble.Tabs[activeTab];

        const tooltipText = [
            { label: tooltipConfig.name, value: productName ? productName.value : 'N/A' },
            { label: tooltipConfig.price, value: formatCurrency(d.value) },
            { label: tooltipConfig.count, value: productQuantity ? productQuantity.value : 'N/A' },
            { label: tooltipConfig.country, value: d.label }
        ];

        const tooltipSize = calculateTooltipSize(tooltipText);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        showTooltip(event, tooltipText);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const createBubbles = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const margin = { top: 30, right: 30, bottom: 50, left: 55 };
        const effectiveWidth = width - margin.left - margin.right;
        const effectiveHeight = height - margin.top - margin.bottom;

        const maxY = d3.max(data.nodes, d => {
            const productQuantity = d.extra.find(e => e.label === "ProductQuantity");
            return productQuantity ? productQuantity.value : 0;
        });

        const maxX = d3.max(data.nodes, d => d.value);

        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const xScale = d3.scaleLinear()
            .domain([0, maxX])
            .range([0, effectiveWidth]);

        const yScale = d3.scaleLinear()
            .domain([0, maxY])
            .range([effectiveHeight, 0]);

        const fixedRadius = Math.min(width, height) * 0.019;

        const midValue1 = maxY / 2;
        const midValue2 = maxY / 1.35;
        const midValue3 = maxY / 4;
        const yTickValues = [midValue1, midValue2, maxY, midValue3];

        const xAxis = d3.axisBottom(xScale).ticks(5).tickSize(0);
        const yAxis = d3.axisLeft(yScale).tickValues(yTickValues).tickSize(0);

        const xAxisGroup = svg.append("g")
            .attr("transform", `translate(0, ${effectiveHeight})`)
            .call(xAxis);
        xAxisGroup.selectAll("text").attr("fill", "#8D96B2").attr("transform", "translate(0, 6)");
        xAxisGroup.selectAll(".domain").attr("stroke", "#EDF1F5");

        const yAxisGroup = svg.append("g").call(yAxis);
        yAxisGroup.selectAll("text").attr("fill", "#8D96B2").attr("transform", "translate(-6, 0)");
        yAxisGroup.selectAll(".domain").attr("stroke", "#EDF1F5");

        const colorMap = uniqueNodes.reduce((acc, node, index) => {
            acc[node.label] = bubbleColors[index % bubbleColors.length];
            return acc;
        }, {});
        // eslint-disable-next-line
        const bubbles = svg.selectAll(".bubble")
            .data(data.nodes)
            .enter().append("circle")
            .attr("class", "bubble")
            .attr("cx", d => xScale(d.value))
            .attr("cy", d => {
                const productQuantity = d.extra.find(e => e.label === "ProductQuantity");
                return yScale(productQuantity ? productQuantity.value : 0);
            })
            .attr("r", 0)
            .style("opacity", d => {
                const regNum = d.extra.find(e => e.label === "ProcedureRegNum")?.value;
                if (regNumArray.length === 0) {
                    return 1;
                } else {
                    return regNumArray.includes(regNum) ? 1 : 0.3;
                }
            })
            .attr("stroke", d => {
                const regNum = d.extra.find(e => e.label === "ProcedureRegNum")?.value;
                if (regNumArray.length === 0) {
                    return "var(--text-color)";
                } else {
                    return regNumArray.includes(regNum) ? "var(--text-color)" : "none";
                }
            })
            .attr("fill", d => colorMap[d.label])
            .on("click", (event, d) => {
                onMouseOut();
                const regNum = d.extra.find(e => e.label === "ProcedureRegNum")?.value;
                const productName = d.extra.find(e => e.label === "ProductName")?.value;
                dispatch(toggleSegment({ regNum, productName }));
                dispatch(addBubbleChartName('Распределение стоимостей единиц продукции'))
                event.stopPropagation();
            })
            .on("mousemove", (event, d) => onMouseMove(event, d))
            .on("mouseout", onMouseOut)
            .transition()
            .duration(800)
            .attr("r", fixedRadius);
    };

    const uniqueNodes = BubbleData && BubbleData.nodes ? BubbleData.nodes.reduce((acc, node) => {
        const existingNode = acc.find(existingNode => existingNode.label === node.label);
        if (existingNode) {
            existingNode.count += 1;
        } else {
            acc.push({ ...node, count: 1 });
        }
        return acc;
    }, []).sort((a, b) => b.count - a.count) : [];

    return (
        <div className={`${style.container} ${clickedNodes.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "600px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithTwoButtons}
                    onZoomClick={onZoomClick}
                    diagramName={headerWithTwoButtons.title}
                    hasMoreBubble={shouldDisplayBubble}
                    loadMoreBubble={handleLoadMoreBubble}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={"Распределение стоимостей единиц продукции"} data={uniqueNodes} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={bubbleColors} />
                    </div>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`} />
                </>
            )}
        </div>
    );
};

export default BubbleDiagramm;
