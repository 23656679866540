import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import styles from "../OkpdTree/RecurseTree/Tree.module.css";
import { useDispatch } from "react-redux";
import { removeParam, setParam } from "../../service/reducers/FilterSearchSlice";

export const CustomCheckbox = styled(Checkbox)(() => ({
    color: '#D0D4DA',
    '&.Mui-checked': {
        color: '#4A3DFD',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
    },
}));

const LocationItem = ({ data, isLimitReached, style: customStyle, onSelectChange }) => {
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLimitReached && !isChecked) {
            dispatch(removeParam({ key: 'locations', value: data.ISO }));
        }
        // eslint-disable-next-line
    }, [isLimitReached, data.ISO, dispatch]);

    const handleCheckboxChange = () => {
        const newIsChecked = !isChecked;
        setIsChecked(newIsChecked);
        if (onSelectChange) {
            onSelectChange(data, newIsChecked);
        }
        if (newIsChecked) {
            dispatch(setParam({ key: 'locations', value: data.ISO }));
        } else {
            dispatch(removeParam({ key: 'locations', value: data.ISO }));
        }
    };

    return (
        <li className={styles.treeNode} style={customStyle}>
            <div className={styles.treeNodeHeader}>
                <CustomCheckbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                {data.region}
            </div>
        </li>
    );
};

export default LocationItem;
