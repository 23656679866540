import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchSunKey} from "../../api/api";

const initialState = {
    SunKeyData: [],
    loading: 'idle',
    error: null,
    limPerPage: null,
    currentLimit: 15,
    sunKeyMode: "cust",
};

export const fetchSunData = createAsyncThunk(
    'sun/fetchSunData',
    async (options) => {
        try {
            options.newOKPDCode = options.selectedProduct.map(item => item.productId);
            const response = await fetchSunKey(options);
            if ((response.status === 204 && response.data === "") || response.data.result === null) {
                return [];
            }
            return response.data.result;
        } catch (error) {
            throw error;
        }
    }
);


const sunKeyChartSlice = createSlice({
    name: 'sunKeyChart',
    initialState,
    reducers: {
        increaseLimit: (state) => {
            state.currentLimit += 15;
        },
        resetSanKeyData: (state) => {
            return initialState;
        },
        setSunKeyMode: (state, action) => {
            state.sunKeyMode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSunData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchSunData.fulfilled, (state, action) => {
                if ((Array.isArray(action.payload) && action.payload.length === 0) ||
                    (action.payload.nodes === null || action.payload.nodes?.length === 0)) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.SunKeyData = action.payload;
                    if (action.payload.nodes && action.payload.nodes.length > 0) {
                        const { extra } = action.payload.nodes[0];
                        if (extra) {
                            const maxValueItem = extra.find(item => item.label === 'max_value');
                            if (maxValueItem) {
                                const maxValue = maxValueItem.value;
                                state.limPerPage = maxValue;
                            }
                        }
                    }
                }
            })
            .addCase(fetchSunData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const selectLimPerPage = (state) => state.sunKey.limPerPage;
export const selectCurrentLimit = (state) => state.sunKey.currentLimit;

export const {setSunKeyMode, increaseLimit,resetSanKeyData } = sunKeyChartSlice.actions;

export default sunKeyChartSlice.reducer;
