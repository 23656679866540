import React, { useEffect, useState } from 'react';
import styles from './SearchCard.module.css';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useModal } from "../../hook/useModal";
import { PurchaseAllInfo } from "../../pages/SearchPage/AllPurchaseInfo/PurchaseInfoCard";
import { formatDate } from "../../utils/convertDates";
import { PURCHASE } from "../../api/config";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import CustomToast from '../CustomToast/CustomToast';
import {fetchPurchaseLikesData} from "../../service/reducers/purchaseLikesSlice";

const highlightText = (text, highlight) => {
    if (!highlight) return text;
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
        <span>
            {parts.map((part, i) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <span key={i} style={{ backgroundColor: 'yellow' }}>{part}</span>
                ) : (
                    part
                )
            )}
        </span>
    );
};

const SearchCard = ({ data, likedPurchase, searchText }) => {
    const dispatch = useDispatch();
    const isLiked = likedPurchase.some(purchase => purchase.id === data.id);
    const [liked, setLiked] = useState(isLiked);
    const [toasts, setToasts] = useState([]);
    const { setIsModalVisible, setModalContent, setIsFullScreen, setIsFullScreenAll } = useModal();

    useEffect(() => {
        setLiked(isLiked);
    }, [isLiked, likedPurchase]);

    const toggleLike = async () => {
        const newLikedStatus = !liked;
        setLiked(newLikedStatus);

        const url = `${PURCHASE}/like?id=${data.id}`;
        try {
            const response = newLikedStatus
                ? await axios.post(url, { liked: newLikedStatus }, { withCredentials: true })
                : await axios.delete(url, { withCredentials: true });

            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }

            const newToast = {
                id: Date.now(),
                message: `Закупка №${data.id} ${newLikedStatus ? 'добавлена в избранное!' : 'удалена из избранного'}`,
                type: newLikedStatus ? 'add' : 'remove'
            };

            setToasts((prevToasts) => {
                const updatedToasts = [...prevToasts, newToast].slice(-5);
                return updatedToasts;
            });

            dispatch(fetchPurchaseLikesData());
        } catch (error) {
            console.error('There was a problem with the axios operation:', error);
        }
    };

    const handleSearchItemClick = (id) => {
        setModalContent(
            <PurchaseAllInfo
                key={Date.now()}
                cardId={id}
                data={data}
                onClose={() => {
                    setIsModalVisible(false);
                    setIsFullScreen(false);
                    setIsFullScreenAll(false);
                }}
            />
        );
        setIsFullScreenAll(true);
        setIsFullScreen(true);
        setIsModalVisible(true);
    }

    return (
        <div className={styles.card} key={data.id}>
            <div className={styles.header}>
                <div>
                    <span className={styles.titleOrder}>Подача заявок до </span>
                    <span className={styles.date}>{formatDate(data.date)}</span>
                </div>
                <span>Начальная цена</span>
            </div>
            <div className={styles.titleString}>
                <h3 className={styles.title} onClick={() => handleSearchItemClick(data.id)}>
                    {highlightText(data.title, searchText)}
                </h3>
                <span className={styles.price}>
                    {new Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 2 }).format(data.price)} ₽
                </span>
            </div>
            <span className={styles.description}>{data.customer}</span>
            <div className={styles.numberSt}>
                <span className={styles.date}>{data.id}</span>
                <span className={styles.date}>{data.type}</span>
                <span className={styles.date}>{data.gov}</span>
            </div>
            <div className={styles.footer}>
                <div className={styles.buttonContFooter}>
                    <button className={styles.likeButton} onClick={toggleLike}>
                        {liked ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
                    </button>
                    <button className={styles.detailsButton} onClick={() => handleSearchItemClick(data.id)}>Подробнее</button>
                </div>
            </div>
            <div className={styles.toastContainer}>
                {toasts.map((toast) => (
                    <CustomToast
                        key={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={() => setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id))}
                    />
                ))}
            </div>
        </div>
    );
};

export default SearchCard;
